import React, { useEffect, useContext } from "react";
import i18n from "../plugins/i18n";
import { useHistory } from "react-router-dom";
import {
  fetchGlobalSettings,
  getCinemas,
  chooseCinema,
} from "../store/global/globalActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { globalConfigContext } from "@context/GlobalConfigContext";

const SelectCinema = ({ t, history }) => {
  const dispatch = useDispatch();

  const global = useSelector((state) => state.global);
  const { loading, error, globalSettings, cinemas } = global;

  useEffect(() => {
    dispatch(getCinemas());
  }, [dispatch]);
  const onSelectCinema = (cinema) => {
    dispatch(chooseCinema(cinema, history));
  };
  return (
    <div className="parent">
      <div id="printableArea"></div>
      <div className="page2_container">
        {/* <Link to="/" className="page2_movie_header">
          <h2 className="small_text">{t("common.WELCOME TO")}</h2>
          <div className="logo2"></div>
        </Link> */}
        <div className="page2_body">
          <div className="choose_action">{t("common.CHOOSE CINEMA")}</div>
          <div className="huge_buttons">
            {cinemas.map((cinema) => (
              <button
                className="left_button hb"
                key={cinema.cinema_id}
                onClick={() => {
                  onSelectCinema(cinema);
                }}
              >
                {cinema.cinema_name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(SelectCinema);
