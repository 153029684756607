import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  fetchSeatLayoutData,
  fetchTicketPrice,
  selectSeats,
  reserveSeatsOnDemand,
  setSeatsString,
  fetchSeatTypes,
  onSetCompleteRateCard,
  onSetCompleteNewTotalAmount,
  clearStore,
  fetchVoucherList,
  updateSeatLayout,
  setReservationID,
  releaseRealtimeReservedSeats,
  //   validateVoucher,
} from "@store/booking/bookingActions";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import MovieHeader from "@components/partials/MovieHeader";
import CSpinner from "@components/partials/Spinner";
import screen from "../../assets/imgs/svgs/screen.svg";
import ip1 from "../../assets/imgs/ip1.jpg";
import moment from "moment";
import {withNamespaces} from "react-i18next";
import i18n from "../../plugins/i18n";
import movieDef from "@assets/imgs/movie-default-portrait.jpg";
import whiteSofa from "../../assets/imgs/svgs/sofa.svg";
import wheelChair from "../../assets/imgs/svgs/wheel-chair.svg";
import selectedSeat from "../../assets/imgs/svgs/seat-sl-selected.svg";
import occupiedSeat from "../../assets/imgs/svgs/seat-sl-occupied.svg";
import whiteSeat from "../../assets/imgs/svgs/seat-sl.svg";
import {getLangSpecificAttribute} from "../../helper/Languages";
import {
  timeConvert,
  isImageURLNull,
  translateSessionAttribute,
} from "@helper/formatting.js";
import {globalConfigContext} from "@context/GlobalConfigContext";
import {getValueFromGlobalOptions} from "@helper/utils";
import {backendSocket} from "@src/index";
import {toast} from "react-toastify";
import {fetchSeatLayout, releaseSeat} from "../../store/seatLayout/seatLayoutActions";
import {clearFnbState} from "../../store/booking/bookingActions";
import {useParams} from "react-router";
import {fetchScheduleDetails, fetchScreeningTypes} from "../../store/movies/moviesActions";
import {BASEURL} from "../../config";

const checkSeatReserved = (seat, srtid) => {
  srtid = parseInt(srtid);
  if (
    [8, 9, 12, 13].includes(srtid) ||
    seat.covidBlocked ||
    seat.covidBlockedTemp
  ) {
    return true;
  }
  return false;
};

let showPno = (e) => {
  const pno = document.querySelector("#pno");
  const bankOffers = document.querySelector("#bankOffers");
  const pnoBtn = document.querySelector("#pnoBtn");
  const bankOffersBtn = document.querySelector("#bankOffersBtn");

  if (pno.classList.contains("d-none")) {
    pno.classList.add("d-flex");
    bankOffers.classList.remove("d-none");
    bankOffers.classList.add("d-none");
    bankOffers.classList.remove("d-flex");
  }
  if (!e.target.classList.contains("active")) {
    e.target.classList.add("active");
    bankOffersBtn.classList.remove("active");
  }
};

let showBankOffers = (e) => {
  const pno = document.querySelector("#pno");
  const bankOffers = document.querySelector("#bankOffers");
  const pnoBtn = document.querySelector("#pnoBtn");
  const bankOffersBtn = document.querySelector("#bankOffersBtn");

  if (bankOffers.classList.contains("d-none")) {
    pno.classList.add("d-none");
    bankOffers.classList.add("d-flex");
    pno.classList.remove("d-flex");
    bankOffers.classList.remove("d-none");
  }
  if (!e.target.classList.contains("active")) {
    e.target.classList.add("active");
    pnoBtn.classList.remove("active");
  }
};

const getSeatClass = (status, singleSeat) => {
  if (singleSeat.sst_seat_type === "Wheelchair") {
    return "wheel-chair";
  } else if (status === 3) {
    return "wheel-chair";
  } else return "cell";
};

const CreateShowSeatLayout = (props) => {
  //
  const [back, setBack] = useState(false);
  const isFromMoviePage = useSelector((state) => state.movies.fromMoviePage);
  const {
    screen_id,
    ss_id,
    md_id,
    // category_id,
    gogo_ss_id,
    booking_type_id,
    genre_id,
    movie_id,
    content_lang_id,
    isPrivate,
    mycinema_ss_d_id,
  } = useParams();
  const booking_type = useSelector((state) => state.movies.booking_type);
  const reservation_id = useSelector((state) => state.booking.reservation_id);

  const category_id = 1;
  useEffect(() => {
    dispatch(clearFnbState());
    if (reservation_id) {
      dispatch(releaseSeat({reservation_id}));
    }
  }, []);
  useEffect(() => {
    // dispatch(clearSelectedVoucher());
    dispatch(fetchSeatLayout({mycinema_ss_d_id}));
    dispatch(
      fetchScheduleDetails({
        mycinema_ss_d_id,
        booking_type_id,
      })
    );
  }, [booking_type_id, mycinema_ss_d_id]);

  const [specialRequest, setSpecialRequest] = useState("");
  const seatLayoutLoading = useSelector(
    (state) => state.seatLayout.seatLayoutLoading
  );
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  useEffect(() => {
    if (category_id == 1) {
      dispatch(fetchScreeningTypes({ mycinema_ss_d_id, payload: 3 }));
    }
  }, []);

  const [minSeatsCompulsory, setMinSeatsCompulsory] = useState(1);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const [modalShow, setModalShow] = useState(false);

  const selected_seats = useSelector((state) => state.booking.seats_selected);
  const [numberOfSeats, setNumberOfSeats] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalBtnMessage, setShowModalBtnMsg] = useState("");

  const screeningTypes = useSelector((state) => state.movies.screeningTypes);

  useEffect(() => {
    if (booking_type_id) {
      if (screeningTypes && category_id == 1 && booking_type_id == 1) {
        const x = screeningTypes.find((type) => type.booking_type_id === 1);
        if (x) {
          setNumberOfSeats(x.min_seats);
          setMinSeatsCompulsory(x.min_seats);
        }
      }
    }
  }, [screeningTypes]);
  //
  // const { arabic, currency } = useContext(globalConfigContext);
  const arabic = false;
  // const currency = {};

  const {session_id /*, movie_id, screen_id*/} = props.match.params;

  const global = useSelector((state) => state.global);
  const {
    globalSettings: {org_kiosk_screen_image_url},
    cinema: {cinema_id},
    currency: {curr_code},
    selected_language: {lang_id},
    dummy_image,
    mode,
  } = global;

  const t = props.t;

  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);

  let currentSetShow =  useSelector(s => s.movies.show)

  const globalOptions = useSelector((state) => state.global.globalOptions);
  const realTimeSeatLayout = getValueFromGlobalOptions(
    globalOptions,
    "ENABLE_SEAT_LAYOUT_REAL_TIME"
  );
  const guest_id = window.localStorage.getItem("guest_user_id");

  useEffect(() => {
    dispatch(clearStore());
    // dispatch(
    //   fetchSeatLayoutData({
    //     ss_id: session_id,
    //     md_id: movie_id,
    //     screen_id,
    //   })
    // );
    // dispatch(fetchSeatTypes({ screen_id }));
    // dispatch(fetchTicketPrice({ session_id, movie_id }));
    // const reservation_id = Math.floor(100000000 + Math.random() * 900000000);
    // dispatch(setReservationID(reservation_id));

    console.log("seatLayoutSocket in seatlayout page", backendSocket);
  }, []);

  const [totalSeatBooked, settotalSeatBooked] = useState(0);
  // const [totalAmountPrice, settotalAmountPrice] = useState(0);
  let totalAmountPrice = useRef(0);
  let totalNetAmout = useRef(0);
  // const [totalNetAmout, settotalNetAmout] = useState(0);
  let seatsSelectedRateGroupNew = useRef([]);
  const [rateCardGroups, setrateCardGroups] = useState([]);
  // const [loader, setloader] = useState(false);

  //
  let rowIndex,
    seatIndex,
    singleSeat = {};
  //
  let singleRow = Object;
  singleRow.PhysicalName = "";
  const [seats, setSeats] = useState([]);
  const seatLayout = useSelector((state) => state.booking.seat_layout);
  const seat_Types = useSelector((state) => state.booking.seat_types);
  const [userSelectedSeatsNew, setUserSelectedSeatsNew] = useState([]);
  const selectedTicketPrice = useSelector(
    (state) => state.booking.selectedTicketPrice
  );
  const movies = useSelector((state) => state.movies.now_playing_movies);
  const cinema = useSelector((state) => state.global.cinema);
  const movieDetails = movies.find((movie) => movie.md_id == movie_id);
  //   const movieDetails = useSelector((state) => state.movies.movie_details);
  // const showDetails = useSelector((state) =>
  //   state.booking.show_details && state.booking.show_details.length > 0
  //     ? state.booking.show_details[0]
  //     : {}
  // );
  //const allshowDetails = useSelector((state) => state.movies.show_times);
  // const showDetails = allshowDetails.find((show) => show.ss_id == session_id);

  const showDetails = useSelector((state) => state.movies.selected_session);

  //console.log({showDetails: state.movies.selected_session})
  const seatSelectError = useSelector(
    (state) => state.booking.seat_select_error
  );
  const onSelectSeats = (seatTypeIndex, singleSeat) => {
    const {sl_seat_name} = singleSeat;
    dispatch(selectSeats({seatTypeIndex, SeatTypes: singleSeat}));
    dispatch(
      fetchVoucherList({
        seat: singleSeat,
        cinema_id,
        movie_details_id: movie_id,
        screen_id,
        show_date_time: currentSetShow.ss_actual_start_date_time,
        aggregator_cinema_id: cinema_id,
        selectSeats: selected_seats,
      })
    );
    let doesSeatExist = false;
    const newSeats = [...seats];
    if (newSeats.indexOf(sl_seat_name) > -1) {
      newSeats.splice(newSeats.indexOf(sl_seat_name), 1);
      doesSeatExist = true;
    } else {
      newSeats.push(sl_seat_name);
    }
    setSeats(newSeats);
    setUserSelectedSeatsNew(singleSeat);
    console.log("selected_seats :>> ", selected_seats);
  };

  const voucherCodeData = useSelector((state) => state.booking.voucherCodeData);
  const voucherCodeError = useSelector(
    (state) => state.booking.voucherCodeError
  );
  const [showSeatLimitError, setShowSeatLimitError] = useState(false);
  const [showProceedSpinner, setShowProceedSpinner] = useState(false);
  const [showVoucherPopup, setShowVoucherPopup] = useState(false);
  const [voucherCode, setVoucherCode] = useState(null);
  const [voucherCodeLoading, setVoucherCodeLoading] = useState(false);
  const [voucherSeatsExceededError, setVoucherSeatsExceededError] =
    useState(false);
  const [directProceed, setdirectProceed] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(true);
  // Seat Types
  const rateCardGroupsFunction = useCallback(() => {
    console.log("rateCardGroupsFunction fired");
    console.log("selected_seats", selected_seats);
    if (selected_seats.length > 0) {
      let array = [];
      let checkCheckoutRateCardNeeded = 0;
      settotalSeatBooked(0);
      totalAmountPrice.current = 0;
      totalNetAmout.current = 0;
      selected_seats.map((x) => {
        let find_In = array.findIndex((new1) => {
          return new1.sst_id == x.sst_id;
        });
        if (find_In >= 0) {
          array[find_In]["AllSeats"].push(x);
          if (array[find_In]["rate_card_group"].length == 1) {
            array[find_In]["totalSelected"] =
              array[find_In]["totalSelected"] + 1;
            array[find_In]["rate_card_group"][0].totalSeatSelected =
              array[find_In]["rate_card_group"][0].totalSeatSelected + 1;
            totalAmountPrice.current =
              parseFloat(totalAmountPrice.current) +
              parseFloat(array[find_In]["rate_card_group"][0].rc_total_amount);
            totalNetAmout.current =
              parseFloat(totalNetAmout.current) +
              parseFloat(array[find_In]["rate_card_group"][0].rc_net_amount);
            settotalSeatBooked((prev) => prev + 1);
            setdirectProceed(true);
          } else if (array[find_In]["rate_card_group"].length > 1) {
            checkCheckoutRateCardNeeded = checkCheckoutRateCardNeeded + 1;
          }
        } else {
          let arrayCardGroup = [];
          x.rate_card_group.map((rg) => {
            rg["totalSeatSelected"] = 0;
            arrayCardGroup.push(rg);
          });
          let obj = {
            srt_name: x.srt_name,
            sst_id: x.sst_id,
            AllSeats: [x],
            totalSelected: 0,
            rate_card_group: arrayCardGroup,
          };
          if (arrayCardGroup.length == 1) {
            obj["totalSelected"] = 1;
            obj["rate_card_group"][0].totalSeatSelected = 1;
            totalAmountPrice.current =
              parseFloat(totalAmountPrice.current) +
              parseFloat(obj["rate_card_group"][0].rc_total_amount);
            totalNetAmout.current =
              parseFloat(totalNetAmout.current) +
              parseFloat(obj["rate_card_group"][0].rc_net_amount);
            settotalSeatBooked((prev) => prev + 1);
          } else if (arrayCardGroup.length > 1) {
            checkCheckoutRateCardNeeded = checkCheckoutRateCardNeeded + 1;
          }
          array.push(obj);
        }
      });
      seatsSelectedRateGroupNew.current = array;
      console.log(
        "🚀 ~ file: SeatLayout.js ~ line 259 ~ rateCardGroupsFunction ~ seatsSelectedRateGroupNew",
        seatsSelectedRateGroupNew
      );

      if (
        checkCheckoutRateCardNeeded == 0 &&
        array[0]["rate_card_group"].length > 0
      ) {
        dispatch(
          onSetCompleteRateCard({
            status: true,
            seatGroup: seatsSelectedRateGroupNew.current,
          })
        );
        dispatch(
          onSetCompleteNewTotalAmount({
            totalAmount: totalAmountPrice.current,
            netAmount: totalNetAmout.current,
          })
        );
      } else if (checkCheckoutRateCardNeeded > 0) {
        dispatch(
          onSetCompleteRateCard({
            status: false,
            seatGroup: seatsSelectedRateGroupNew.current,
          })
        );
      }
      setrateCardGroups(array);
    } else {
      seatsSelectedRateGroupNew.current = [];
    }
  }, [selected_seats]);
  const totalCount = (rg, index, rg_index, type) => {
    console.log("seatsSelectedRateGroupNew", seatsSelectedRateGroupNew.current);
    let x =
      seatsSelectedRateGroupNew.current[index]["rate_card_group"][rg_index];
    if (
      type == "increment" &&
      seatsSelectedRateGroupNew.current[index].totalSelected ==
      seatsSelectedRateGroupNew.current[index].AllSeats.length
    ) {
      return false;
    }

    if (
      type == "increment" &&
      totalSeatBooked + 1 <= selected_seats.length &&
      x["totalSeatSelected"] <= selected_seats.length
    ) {
      x["totalSeatSelected"] = x["totalSeatSelected"] + 1;
      seatsSelectedRateGroupNew.current[index].totalSelected =
        seatsSelectedRateGroupNew.current[index].totalSelected + 1;
    } else if (
      seatsSelectedRateGroupNew.current[index].totalSelected >= 1 &&
      type == "decrement" &&
      x["totalSeatSelected"] >= 1
    ) {
      x["totalSeatSelected"] = x["totalSeatSelected"] - 1;
      seatsSelectedRateGroupNew.current[index].totalSelected =
        seatsSelectedRateGroupNew.current[index].totalSelected - 1;
    }
    let totalAmount = 0;
    let totalSeatBooked2 = 0;
    let totalNetAmout2 = 0;
    seatsSelectedRateGroupNew.current.map((z) => {
      z.rate_card_group.map((y) => {
        totalAmount =
          parseFloat(totalAmount) +
          parseFloat(y.totalSeatSelected) * parseFloat(y.rc_total_amount);

        totalNetAmout2 =
          parseFloat(totalNetAmout2) +
          parseFloat(y.totalSeatSelected) * parseFloat(y.rc_net_amount);
      });

      totalSeatBooked2 = parseInt(totalSeatBooked2) + parseInt(z.totalSelected);
    });
    totalAmountPrice.current = totalAmount;

    settotalSeatBooked(totalSeatBooked2);
    totalNetAmout.current = totalNetAmout2;
    x["tt_name"] = x["tt_name"] + " ";
    if (totalSeatBooked2 == selected_seats.length) {
      dispatch(
        onSetCompleteRateCard({
          status: true,
          seatGroup: seatsSelectedRateGroupNew.current,
        })
      );
      dispatch(
        onSetCompleteNewTotalAmount({
          totalAmount: totalAmountPrice.current,
          netAmount: totalNetAmout.current,
        })
      );
    } else {
      dispatch(
        onSetCompleteRateCard({
          status: false,
          seatGroup: seatsSelectedRateGroupNew.current,
        })
      );
    }
  };
  useEffect(() => {
    console.log("showDetails", showDetails);
    console.log("showDetails", currentSetShow);
  }, [showDetails, currentSetShow]);
  useEffect(() => {
    console.log("use effect firing");
    rateCardGroupsFunction();
  }, [selected_seats]);
  const handleClose = () => {
  };
  const redirectToFnb = () => {
    props.history.push(`/fnb/${session_id}/${cinema_id}`);
  };
  const voucherHandler = async () => {
    // if (voucherCodeLoading) {
    //   return;
    // }
    // if (voucherCode) {
    //   setVoucherCodeLoading(true);
    //   dispatch(
    //     validateVoucher({
    //       voucher_code: voucherCode,
    //       cinema_id,
    //       session_id,
    //     })
    //   )
    //     .then((data) =>
    //       data !== "INVALID" ? setShowVoucherPopup(false) : null
    //     )
    //     .finally(() => setVoucherCodeLoading(false));
    // }
  };
  const onProceed = async () => {
    if (selected_seats.length < minSeatsCompulsory) {
      return;
    }

    if (directProceed) {
      onProceedRateCardGroup();
    } else {
      setShow(true);
    }
    await dispatch(setSeatsString({seats: seats.toString(), session_id}));
  };
  const onProceedRateCardGroup = async () => {
    setLoader(true);
    await dispatch(
      reserveSeatsOnDemand({
        mycinema_ss_d_id,
        booking_type_id,
        selected_seats,
        schedule_show_id: session_id,
        history: props.history,
        cinema_id,
        seatGroup: seatsSelectedRateGroupNew.current,
        cinema,
        callback: setLoader,
        session_id,
        movie_id,
        screen_id,
        callback2: setShow,
        callback3: setSeats,
        mode,
      })
    );
  };
  const history = useHistory();
  const onClickBack = () => {
    if (selected_seats && selected_seats.length > 0) {
      dispatch(releaseRealtimeReservedSeats());
    } else {
      dispatch(setReservationID(null));
    }
    history.go(-1);
  };

  let getArtwork = (artwork = '') => {
    let startWithUpload = artwork && (artwork.startsWith('/uploads') || artwork.startsWith('uploads'))

    if (startWithUpload) return `${BASEURL || ''}/${artwork}`
    return artwork
  }
  return (
    <>
      <div className="">
        <CSpinner active={showSpinner}/>
        <MovieHeader/>
        <div className="bg-light-black">
          <Container fluid>
            <div>
              <Row className="align-items-center py-3 mb-40">
                <Col>
                  <div className="go_back">
                    <div className="button_wrap" onClick={onClickBack}>
                      <div className="go_back_button"></div>
                    </div>
                    <h2 className="big_text">{t("Go Back")}</h2>
                  </div>
                </Col>
              </Row>

              <Col>
                <div className="ticket-header bb-1 pb-3">
                  <Row className="">
                    <Col xs={12}>
                      <div className="d-flex">
                        <img
                          src={
                            getArtwork(getLangSpecificAttribute(
                              currentSetShow.altMovieContent,
                             lang_id,
                             "artwork"
                            )) ||
                            dummy_image
                          }
                          className=""
                        />
                        <div className="ml-1 d-flex flex-column align-items-center w-100">
                          <Row className="mx-0 w-100 pb-2 pt-1">
                            <Col md={5}>
                              <span className="fw-bold frs-28 text-limit v3 lh-1">
                                {
                                  getLangSpecificAttribute(
                                  currentSetShow.altMovieContent,
                                  lang_id,
                                  "mc_title"
                                  )
                                }
                              </span>
                            </Col>
                            <Col md={4}>
                              <div className="group">
                                <span
                                  className={`${
                                    arabic
                                      ? "border-left pl-2"
                                      : "border-right pr-2"
                                  }`}
                                >
                                  {currentSetShow?.rating}
                                </span>
                                <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                                  {timeConvert(currentSetShow?.mrrdr_runtime)}
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mx-0 w-100">
                            <Col xs={5}>
                              <span>
                                <p>{t("seatlayout_page.Date")}</p>
                                <h4>
                                  {moment(currentSetShow?.ss_start_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </h4>
                              </span>
                            </Col>
                            <Col xs={4}>
                              <span>
                                <p>{t("seatlayout_page.Seats")}</p>
                                <h4>
                                  {seats.length > 0
                                    ? seats.toString()
                                    : t("seatlayout_page.seats-not-selected")}
                                </h4>
                              </span>
                            </Col>
                          </Row>
                          <Row className="mx-0 w-100">
                            <Col xs={5}>
                              <span>
                                <p>{t("seatlayout_page.Show Details")}</p>
                                <h4>
                                  {/* {showDetails?.SessionAttribute
                                    ? showDetails?.SessionAttribute
                                    : "PREMIUM"}
                                  , */}
                                  {moment(
                                    currentSetShow?.ss_start_show_time,
                                    "HH:mm"
                                  ).format("HH:mm")}
                                </h4>
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </div>

            <Row className="pt-5">
              <Col>
                <div className="position-relative">
                  <div className="stw">
                    <img
                      className="img-fluid"
                      src={
                        org_kiosk_screen_image_url
                          ? org_kiosk_screen_image_url
                          : screen
                      }
                      alt="Screen"
                    />
                  </div>
                  <div className="stw-text">{currentSetShow?.screen_name}</div>
                </div>
              </Col>
            </Row>

            {seatLayout && seatLayout.length > 0 ? (
              <Row className="mb-4">
                <Col className="table-responsive" id="seat-layout-table">
                  <Table borderless responsive className="first-table">
                    {/* Section loop, premium, macro xe etc. */}
                    {seatLayout.map((seatTypes, seatTypeIndex) => (
                      <tr>
                        <td>
                          <tr>
                            <td className="second-table">
                              <Table borderless>
                              </Table>
                            </td>
                            <td className="second-table">
                              <Table borderless>
                                {seatTypes.seatsFinal.map(
                                  (singleRow, rowIndex) => (
                                    <tr key={rowIndex} className="seats">
                                      {singleRow.map((singleSeat, seatIndex) =>
                                        singleSeat.srt_id == 2 ? (
                                          <td key={seatIndex}>
                                            <div className="cell empty"></div>
                                          </td>
                                        ) : (
                                          <td>
                                            <div
                                              className={`${getSeatClass(
                                                singleSeat.sl_seat_status,
                                                singleSeat
                                              )} ${
                                                singleSeat.SeatSelected
                                                  ? "active"
                                                  : checkSeatReserved(
                                                  singleSeat,
                                                  singleSeat.seat_reserve_type_id
                                                  )
                                                  ? "reserved"
                                                  : ""
                                              } ${
                                                singleSeat.Status === 4
                                                  ? "empty"
                                                  : ""
                                              } ${
                                                singleSeat.covidBlocked ||
                                                singleSeat.covidBlockedTemp
                                                  ? "covid-blocked"
                                                  : ""
                                              }
                                      `}
                                              onClick={() => {
                                                !checkSeatReserved(
                                                  singleSeat,
                                                  singleSeat.seat_reserve_type_id
                                                ) &&
                                                onSelectSeats(
                                                  seatIndex,
                                                  singleSeat
                                                );
                                              }}
                                            >
                                              {/* checkSeatValid(
                                                    singleSeat.Status,
                                                    seatIndex,
                                                    rowIndex,
                                                    seatTypes,
                                                    singleSeat
                                                  ) */}
                                              {singleSeat.SeatSelected
                                                ? singleSeat.sl_seat_name
                                                : ""}
                                            </div>
                                          </td>
                                        )
                                      )}
                                    </tr>
                                  )
                                )}
                              </Table>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    ))}
                  </Table>
                </Col>
              </Row>
            ) : (
              <Row className="mb-4">
                <Col className="table-responsive" id="seat-layout-table">
                  <div className="text-center">
                    <div>
                      <Spinner
                        animation="border"
                        role="status"
                        className="spinner"
                      >
                        <span className="sr-only">{t("Loading")}</span>
                      </Spinner>
                    </div>
                    <br/>
                    <h6>{t("seatlayout_page.loading-seatlayout")}</h6>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              <Col className="seats-desc py-5">
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                  {/* {seat_Types.map(seat=>(
                    <span>
                      <span>
                        {seat}
                      </span>
                    </span>
                  ))} */}
                  <span>
                    <img src={occupiedSeat} alt="img"/>
                    <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                      {t("seatlayout_page.chair-types.occupied")}
                    </span>
                  </span>
                  <span>
                    <img src={whiteSeat} alt="img"/>
                    <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                      {t("seatlayout_page.chair-types.open")}
                    </span>
                  </span>
                  <span class="seat-selected-span">
                    {/* <img src={selectedSeat} alt="img" /> */}
                    <svg
                      width="60px"
                      height="48px"
                      viewBox="0 0 60 48"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="5.2-Seats-and-voucher-selected-"
                          transform="translate(-401.000000, -1303.000000)"
                          fill="#6236FF"
                          fill-rule="nonzero"
                        >
                          <g
                            id="Group-10"
                            transform="translate(0.000000, 161.000000)"
                          >
                            <g
                              id="np_cinema-seat_2049821_000000-copy-35"
                              transform="translate(401.000000, 1142.000000)"
                            >
                              <path
                                d="M53.293391,21.2843444 L50.3802798,21.2843444 L50.3802798,7.09478148 C50.3802798,3.17715666 46.4642542,0 41.6355597,0 L18.3144403,0 C13.4857458,0 9.56972017,3.17715666 9.56972017,7.09478148 L9.56972017,21.2843444 L6.65660903,21.2843444 C3.43470433,21.2843444 0.825,23.401711 0.825,26.0156556 L0.825,44.9343161 C0.825,45.5617785 1.13380055,46.1626214 1.68037355,46.6060169 C2.22694654,47.0494123 2.96745644,47.3 3.74083949,47.3 L12.4855597,47.3 C13.2589427,47.3 13.9995156,47.0494634 14.5460256,46.6060169 C15.0925356,46.1625703 15.4013992,45.5617785 15.4013992,44.9343161 L15.4013992,42.5686321 L44.5486008,42.5686321 L44.5486008,44.9343161 C44.5486008,45.5617785 44.8574014,46.1626214 45.4039744,46.6060169 C45.9505474,47.0494123 46.6910573,47.3 47.4644403,47.3 L56.2091605,47.3 C56.9825436,47.3 57.7231164,47.0494634 58.2696265,46.6060169 C58.8161365,46.1625703 59.125,45.5617785 59.125,44.9343161 L59.125,26.0156556 C59.125,24.7607874 58.5101342,23.5568881 57.4170512,22.6700404 C56.3239682,21.7831927 54.8400871,21.2843444 53.293391,21.2843444 Z"
                                id="Shape"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                      {t("seatlayout_page.chair-types.selected")}
                    </span>
                  </span>
                  <span>
                    <img src={whiteSofa} alt="img"/>
                    <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                      {t("seatlayout_page.chair-types.lounger")}
                    </span>
                  </span>
                  <span>
                    <img src={wheelChair} alt="img"/>
                    {t("seatlayout_page.chair-types.wheelchair")}
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="sticky-bar-margin">
              <Col sm={12}>
                {seatSelectError && (
                  <Alert variant="dark" className="text-center">
                    {seatSelectError.message}
                    {t("seatlayout_page.errors.try-later")}
                  </Alert>
                )}
              </Col>
            </Row>

            <Row className="align-items-center bgb mt-5 sticky-movie-bar">
              <Col xs={12} className="seat-footer">
                <Row className="py-4">
                  {/* <Col md={8}>
                    <div className={`d-flex px-3 ${arabic ? "bl-1" : "br-1"}`}>
                      <div>
                        <h3 className="f-grey">
                          {t("seatlayout_page.Total Payable")}
                        </h3>
                        <h1 className="fw-bold">
                          {curr_code}{" "}
                          {selectedTicketPrice.priceInCents.toFixed(2)}
                        </h1>
                        <h5>
                          {t("fnb_page.taxes")}: {curr_code}{" "}
                          {selectedTicketPrice.taxInCents.toFixed(2)}{" "}
                        </h5>
                      </div>
                      <div
                        className={`flex-center ${arabic ? "pr-4" : "pl-4"}`}
                      >
                        <h6
                          className="custom-label"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          {t("seatlayout_page.Total Bill")}
                        </h6>
                      </div>
                    </div>
                  </Col> */}
                  <Col
                    md={12}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <span
                      className={`blue-btn btn-lg px-5 ${
                        selected_seats.length >= minSeatsCompulsory ? "" : "inactive-btn"
                      }`}
                      onClick={onProceed}
                    >
                      {showProceedSpinner ? (
                        <Spinner
                          animation="border"
                          role="status"
                          className="spinner"
                        >
                          <span className="sr-only">{t("Loading")}</span>
                        </Spinner>
                      ) : (
                        t("common.PROCEED")
                      )}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-4"
          dialogClassName="modal-95w"
        >
          <div className="d-flex flex-column">
            <div className="d-flex border-bottom border-dark">
              <div className="col-6">
                <h5 className="font-weight-bold mt-2">
                  {t("seatlayout_page.Show Rate Card")}
                </h5>
              </div>
              <div className="col-6 text-right">
                <span
                  className="fw-bold h1 ml-auto cursor-pointer rate-card-close"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  &times;
                </span>
              </div>
            </div>

            <div className="d-flex flex-column">
              {rateCardGroups.length > 0 &&
              rateCardGroups.map((item, index) => (
                <>
                  <div className="d-flex align-items-center border-bottom flex-wrap">
                    <div className="seat-type gold">
                      <div className="st-name">{item.srt_name}</div>
                      <div>
                        {item.totalSelected}/{item.AllSeats.length}
                      </div>
                    </div>
                    {item.rate_card_group.map((rg, rg_index) => (
                      <>
                        <div className="d-flex align-items-center flex-wrap">
                          <div className={`category`}>
                            <div className="category-name">{rg.tt_name}</div>
                            <div className="category-price">
                              {curr_code} {rg.rc_total_amount}
                            </div>
                            <div className="category-seats">
                              <div
                                onClick={() =>
                                  totalCount(rg, index, rg_index, "decrement")
                                }
                                className={
                                  rg.totalSeatSelected == 0
                                    ? "mod-disabled"
                                    : "mod-btn"
                                }
                              >
                                -
                              </div>
                              <div>{rg.totalSeatSelected}</div>
                              <div
                                onClick={() =>
                                  totalCount(rg, index, rg_index, "increment")
                                }
                                className={
                                  item.totalSelected == item.AllSeats.length
                                    ? "mod-disabled"
                                    : "mod-btn"
                                }
                              >
                                +
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </>
              ))}
              {/* End Here */}
              <div className="d-flex flex-column w-100 px-4 border-bottom py-2">
                <div className="d-flex w-100 justify-content-between align-items-center border-bottom">
                  <h5>{t("seatlayout_page.Total Seats")}</h5>
                  <h4 className="font-weight-bold">
                    {totalSeatBooked}/{selected_seats.length}
                  </h4>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center py-2">
                  <h5>{t("seatlayout_page.Total Price")}</h5>
                  <h3 className="font-weight-bold">
                    {curr_code} {totalAmountPrice.current}
                  </h3>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center">
                <span
                  className={`blue-btn mt-3 px-4 ${
                    totalSeatBooked == selected_seats.length
                      ? ""
                      : "inactive-btn"
                  }`}
                  onClick={() =>
                    totalSeatBooked == selected_seats.length &&
                    onProceedRateCardGroup()
                  }
                >
                  {t("common.PROCEED")}
                  {loader && (
                    <span className="pl-2">
                      <Spinner
                        animation="border"
                        role="status"
                        className="spinner"
                      >
                        <span className="sr-only">{t("Loading")}.</span>
                      </Spinner>
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default withNamespaces()(CreateShowSeatLayout);
