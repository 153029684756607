import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
//imports multiple reducer here
import userReducer from "./users/userReducer";
import moviesReducer from "./movies/moviesReducer";
import bookingReducer from "./booking/bookingReducer";
import foodAndBeveragesReducer from "./foodAndBeverages/foodAndBeveragesReducer";
import globalReducer from "./global/globalReducer";
import homepageReducer from "./homepage/homepageReducer";
import seatLayoutReducer from "./seatLayout/seatLayoutReducer";

const moviesPersistConfig = {
  key: 'movies',
  storage: storage,
  // sub-states/keys inside of `state.movies`
  whitelist: [
    'movies_loader',
    'now_playing_movies'
  ]
}

const rootReducer = combineReducers({
  //and use it here
  homepage: homepageReducer,
  seatLayout: seatLayoutReducer,
  user: userReducer,
  movies: persistReducer(moviesPersistConfig, moviesReducer), //moviesReducer,
  booking: bookingReducer,
  foodAndBeverages: foodAndBeveragesReducer,
  global: globalReducer,
});

export default rootReducer;
