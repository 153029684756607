import React, { useEffect, useContext } from "react";
import i18n from "../plugins/i18n";
import { useHistory } from "react-router-dom";
import {
  fetchGlobalSettings,
  getCinemas,
  chooseCinema,
  setBookingType,
  logout,
  setOnDemand,
} from "../store/global/globalActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { globalConfigContext } from "@context/GlobalConfigContext";

const Settings = ({ t, history }) => {
  const dispatch = useDispatch();
  const global = useSelector((state) => state.global);
  const {
    loading,
    error,
    globalSettings,
    cinemas,
    mode,
    cinema: { cinema_name },
    isAuthenticated,
  } = global;
  useEffect(() => {
    dispatch(getCinemas());
  }, [dispatch]);
  const onSelectCinema = (cinema) => {
    dispatch(chooseCinema(cinema, history));
  };
  const uniqueDeviceCode = useSelector(
    (state) => state.global.uniqueDeviceCode
  );
  const onChangeRadioButton = (e) => {
    e.preventDefault();
    // console.log(`value`, value);
    // dispatch(setBookingType());
  };
  const onClickConfirmSettings = () => {
    dispatch(logout());
    history.push(`/`);
  };
  useEffect(() => {
    if (!isAuthenticated) {
      history.push(`/`);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    localStorage.setItem(`is_on_demand_active`, 'false')
  }, []);

  return (
    <div className="parent">
      {uniqueDeviceCode && (
        <div className="d-flex justify-content-end align-items-center mr-3">
          <h4>DEVICE CODE: {uniqueDeviceCode}</h4>
        </div>
      )}
      <div id="printableArea"></div>
      <div className="choose_action text-center mx-auto">
        {t("CHOOSE MODE")}
      </div>
      <>
        <form onSubmit={onChangeRadioButton} className="radio-group">
          <div className="radio">
            <label htmlFor="">
              <input
                type="radio"
                name=""
                id="mode"
                checked={mode === 1}
                onChange={() => dispatch(setBookingType(1))}
              />
              {t("collect_or_book.TICKET")}
            </label>
          </div>
          <div className="radio">
            <label htmlFor="">
              <input
                type="radio"
                name=""
                id="mode"
                checked={mode === 2}
                onChange={() => dispatch(setBookingType(2))}
              />
              {t("collect_or_book.CONCESSION")}
            </label>
          </div>
          <div className="radio">
            <label htmlFor="">
              <input
                type="radio"
                name=""
                id="mode"
                checked={mode === 3}
                onChange={() => dispatch(setBookingType(3))}
              />
              {t("collect_or_book.TICKET&CONCESSION")}
            </label>
          </div>
          <div className="radio">
            <label htmlFor="">
              <input
                type="checkbox"
                name="isOnDemand"
                id="isOnDemand"
                onChange={(e) => dispatch(setOnDemand(e))}
              />
              {t("On-demand-kiosk").toUpperCase()}
            </label>
          </div>
        </form>
      </>
      <div className="page2_container">
        <div className="page2_body">
          <div className="choose_action">
            {t("SELECTED CINEMA")} - {cinema_name}{" "}
          </div>
          <div></div>
          <div className="huge_buttons">
            {cinemas.map((cinema) => (
              <button
                className="left_button hb"
                key={cinema.cinema_id}
                onClick={() => {
                  onSelectCinema(cinema);
                }}
              >
                {cinema.cinema_name.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
        <button
          onClick={onClickConfirmSettings}
          className="reverse blue-btn mb-5 mxh-47 px-5"
        >
          {t(`common.CONFIRM`)}
        </button>
      </div>
    </div>
  );
};
export default withNamespaces()(Settings);
