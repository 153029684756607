import Api from "./Api";

export default {
  GetAllFnbCategories(payload) {
    return Api().get(`/api/external/fnb-categories`);
  },
  GetAllFnbItems(payload) {
    return Api().post(`/api/external/fnb-items`, payload);
  },
  GetModifierGroup(payload) {
    return Api().get(`fnb/modifier-group/${payload.item_id}`);
  },
  GetAllModifiers(payload) {
    return Api().get(`fnb/modifiers/${payload.item_id}`);
  },
  AddFnb(payload) {
    return Api().get(`fnb/add-fnb`, payload);
  },
  BookFnb(payload) {
    return Api().post(`/api/external/book-fnb`, payload);
  },
};
