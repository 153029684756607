import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";

const MovieFooter = ({
  movie_title,
  image,
  lang,
  format,
  rating,
  genre,
  cinemaName,
  cine_address,
  cine_location,
  date,
  time,
  showType,
  screeningType,
  totalAmount,
  curr_code,
  booking_type,
  category_id,
  onClickMethod,
  loader,
  enabled,
  showConfirmButton,
  t,
}) => {
  const { pathname } = useLocation();

  return (
    <div className="movie-footer">
      <div className="container-fluid">
        <section className="row">
          <div className="sect">
            {
              <div className="col-md-4 mb-2 mb-md-0">
                <div className="movie-img-n-details">
                  <div className="img-wrapper">
                    <img
                      src={image}
                      alt=""
                      // style={{ width: "60%" }}
                    />
                  </div>
                  <div>
                    <p className="movie-title">{movie_title}</p>
                    <div className={""}>
                      <p className={`movie-details `}>
                        {lang} {format}
                      </p>
                      <p className={`movie-details`}>
                        <span>{genre} | </span>
                        <span>{rating}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              <div className={`col-md-3 mb-2 mb-md-0 `}>
                {(!pathname.startsWith("/create-show-seat-layout") ||
                  !pathname.startsWith("/seat-layout")) && (
                  <div className="">
                    <p className="movie-field-title">
                      {date && `${t("Date")}`} {time && `& ${t("Time")}:`}
                    </p>
                    <p className="movie-field">
                      {moment(date).format("ll")}
                      {time && `,`}
                      &nbsp;{time && moment(time, "HH:mm:ss").format("HH:mm")}
                    </p>
                  </div>
                )}
                <div className="">
                  {cinemaName && cinemaName !== "All" && (
                    <>
                      <p className="movie-field-title">{t("Cinema")}:</p>
                      <p className="movie-field">
                        {cinemaName}, {cine_address}
                      </p>
                    </>
                  )}
                </div>
              </div>
            }
            <div className="col-6 col-md-2">
              {false && (
                <>
                  <p className="movie-field-title d-none d-md-block">
                    {t("Screening Type")}:
                  </p>
                  {false && (
                    <p className="movie-field d-none d-md-block">
                      Screening type here
                    </p>
                  )}
                </>
              )}

              {/* {showType && (
                <>
                  <p className="movie-field-title">Show:</p>
                  <p className="movie-field">{showType}</p>
                </>
              )} */}
              <>
                {/* {booking_type != 3 && seats && seats.length > 0 && ( */}

                {/*<p className="movie-field-title">
                  {false ? t("Picked Seats") : t("Pick Seats")}{" "}
                  {false && `(${5})`}:
                </p>*/}
                {/* )} */}
                {false && (
                  <p className="movie-field">
                    {["F1", "F2", "F3", "F4"].map((seat, index) => (
                      <span className="coma" key={index}>
                        {seat}
                      </span>
                    ))}
                  </p>
                )}
                {false && (
                  <p>
                    <p className="movie-field">{t("common.All")}</p>
                  </p>
                )}
              </>
            </div>
            {false && (
              <div className="col-6 col-md-3 for-ft">
                {(pathname.startsWith("/seat-layout") ||
                  pathname.startsWith("/create-show-seat-layout")) && (
                  <button
                    onClick={() => onClickMethod()}
                    className={`blue-btn footer ${true && "inactive"}`}
                  >
                    {t("Confirm")}
                  </button>
                )}
              </div>
            )}
            {/* )} */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default withNamespaces()(MovieFooter);
