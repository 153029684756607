import React, { useEffect, useRef, useState } from "react";
import {useLocation} from "react-router-dom"
import { withNamespaces } from "react-i18next";
import { motion } from "framer-motion";

// Images
import ArrowUp from "../../assets/imgs/svgs/arrow-up.svg";
import ArrowDown from "../../assets/imgs/svgs/arrow-down.svg";

const ScrollArrows = (props) => {
    const { pathname } = useLocation();
    const { paginationStartIndex, setPaginationStartIndex, paginationInterval, arrayLength } = props;

    // const scrollPixels = 100;
    // const scrollInterval = 200;
    // let scrollUpIntervalId, scrollDownIntervalId;

    // const [scrollable, setScrollable] = useState(false);
    // const [scrollIsOnTop, setScrollIsOnTop] = useState(false);
    // const [scrollIsOnBottom, setScrollIsOnBottom] = useState(false);

    // const checkScrollLocation = () => {
    //     if (window.scrollY === 0) {
    //         setScrollIsOnTop(true)
    //     } else {
    //         setScrollIsOnTop(false)
    //     }
    //     if (window.scrollY + window.innerHeight === document.documentElement.scrollHeight) {
    //         setScrollIsOnBottom(true);
    //     } else {
    //         setScrollIsOnBottom(false)
    //     }
    // }

    // const checkIfScrollable = () => {
    //      if (document.documentElement.scrollHeight > window.innerHeight) {
    //         setScrollable(true);
    //     } else {
    //         setScrollable(false)
    //     }
    //     checkScrollLocation();
    // }

    // const scrollUp = ()=>{
    //     window.scrollBy({ top: -scrollPixels, behavior: "smooth" })
    // }

    // const scrollDown = () => {
    //         window.scrollBy({ top: scrollPixels, behavior: "smooth" })
    //     }

    // const handleScrollUp = (e) => {
    //         if (!scrollIsOnTop) {
    //     scrollUpIntervalId = window.setInterval(scrollUp, scrollInterval);
    //     const removeScrollUp = () => {
    //             window.clearInterval(scrollUpIntervalId);
    //             window.removeEventListener("touchend",removeScrollUp)
    //     }
    //         if (scrollUpIntervalId) {
    //             window.addEventListener("touchend", removeScrollUp);
    //         }
    //     }    

    // };
   

    // const handleScrollDown = (e) => {
    //     if (!scrollIsOnBottom) {
    //     scrollDownIntervalId = window.setInterval(scrollDown, scrollInterval);
    //     const removeScrollDown = () => {
    //             window.clearInterval(scrollDownIntervalId);
    //             window.removeEventListener("touchend",removeScrollDown)
    //     }
    //         if (scrollDownIntervalId) {
    //             window.addEventListener("touchend", removeScrollDown);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     checkIfScrollable();
    //     window.addEventListener("scroll", checkScrollLocation);

    //     return () => {
    //         window.removeEventListener("scroll", checkScrollLocation)
    //     }
    // }, [])

        // create an Observer instance
    // const resizeObserver = new ResizeObserver(entries => {
        // checkIfScrollable();
        // console.log('Document height changed:', entries[0].target.scrollHeight, { entries })
// })

// start observing a DOM node
// resizeObserver.observe(document.documentElement)
    
    const handleUpClick = () => {
        setPaginationStartIndex(paginationStartIndex - paginationInterval)
    }

    const handleDownClick = () => {
        setPaginationStartIndex(paginationStartIndex + paginationInterval)
        
    }


    const ScrollArrowsJsx = () => (
        <motion.article className="scroll-arrows" initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
                transition={{ duration: 0.25 }}>
            {/* {!scrollIsOnTop && */}
                {paginationStartIndex > 0 && <motion.figure
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.25 }}
                onClick={handleUpClick}
                // onTouchStart={handleScrollUp}
                className="scroll-arrows__up"
            >
                <img src={ArrowUp} alt="" />
            </motion.figure>}
            {/* } */}
            {/* {!scrollIsOnBottom && */}
                {arrayLength > paginationStartIndex + paginationInterval && <motion.figure
            initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
                transition={{ duration: 0.25 }}
                onClick={handleDownClick}
                // onTouchStart={handleScrollDown}
                className="scroll-arrows__down"
            >
                <img src={ArrowDown} alt="" />
            </motion.figure>}
            {/* } */}
    </motion.article>
    )

    // if (scrollable) {
    if (true) {
        return <ScrollArrowsJsx/>;
    } else {
        return null
    }
}
 
export default withNamespaces()(ScrollArrows);