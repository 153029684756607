import React, { useEffect, useContext, useState, useRef } from "react";
// import "./page2.css";
import i18n from "../plugins/i18n";
import { useHistory } from "react-router-dom";
import {
  fetchGlobalSettings,
  setKioskSelectedLanguage,
  login,
} from "../store/global/globalActions";
import { useDispatch, useSelector } from "react-redux";
import {
  startTimer2,
  clearTimer,
  clearFnbState,
  clearSelectedFnbItems,
  resetTimer,
} from "../store/booking/bookingActions";
import { Link } from "react-router-dom";
import { getLangISOCode } from "@helper/formatting";
import { useSimpleAndDoubleClick } from "../customHooks/useSimpleAndDoubleClick";
import { withNamespaces } from "react-i18next";
import { globalConfigContext } from "@context/GlobalConfigContext";
import KeyboardWrapper from "./KeyboardWrapper";
import { Row, Container, Col, Dropdown, Modal, Spinner } from "react-bootstrap";
import {clearMovieBookingType} from "../store/movies/moviesActions";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";

const lang = window.localStorage.getItem("lang");

if (lang && lang === "ar") {
  import("@assets/css/arabic.css").then((condition) => {});
  // moment.locale('ar');
} else {
  import("@assets/css/App.css").then((condition) => {});
  // moment.locale('en');
}

const ChooseLanguage = ({ t }) => {
  // const { arabic, setArabic } = useContext(globalConfigContext);
  const callbackClick = () => {};
  const callbackDoubleClick = () => {
    setShowSettingsPopup(true);
  };
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [username, setUsername] = useState(``);
  const [password, setPassword] = useState(``);
  const [authError, setAuthError] = useState(false);
  const [showUsernameKeyboard, setShowUsernameKeyboard] = useState(false);
  const [showPasswordKeyboard, setShowPasswordKeyboard] = useState(false);
  const usernameKeyboard = useRef(null);
  const passwordKeyboard = useRef(null);
  const onChangeUsernameInput = (event) => {
    const input = event.target.value;
    setUsername(input);
    usernameKeyboard.current.setInput(input);
  };
  const onChangePasswordInput = (event) => {
    const input = event.target.value;
    setPassword(input);
    passwordKeyboard.current.setInput(input);
  };
  const onSubmitLoginForm = () => {
    if (!username || !password) {
      setAuthError(true);
      return;
    }
    setShowProceedSpinner(true);
    dispatch(
      login({
        username,
        password,
        callback: setShowProceedSpinner,
        setAuthError,
        history,
      })
    );
  };
  const [showProceedSpinner, setShowProceedSpinner] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [language_change, setLanguageChange] = useState(null);
  const click = useSimpleAndDoubleClick(callbackClick, callbackDoubleClick);
  const global = useSelector((state) => state.global);
  const {
    loading,
    error,
    globalSettings,
    globalOptions,
    selected_language,
    mode,
    cinema: { cinema_id },
  } = global;
  // useEffect(() => {
  //   if (mode === 2) {
  //     dispatch(resetTimer());
  //     clearTimer();
  //     dispatch(startTimer2());
  //     dispatch(clearSelectedFnbItems());
  //     history.push(`/fnb-checkout/${cinema_id}`);
  //   }
  // }, [mode]);
  // useEffect(() => {
  //   if (lang && lang === "ar") {
  //     document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  //     // setArabic(true);
  //   } else {
  //     document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
  //     // setArabic(false);
  //   }
  //   i18n.changeLanguage(lang ? lang : "en");
  // }, []);

  const globalOptionsLanguages = globalOptions.find((go) => go.go_id === 30);
  let languages = [];
  const [count, setCount] = useState(0);

  if (globalOptionsLanguages && globalOptionsLanguages !== null) {
    const globalLanguages = JSON.parse(globalOptionsLanguages.go_value);
    languages = globalLanguages;
    console.log("fsdfds", languages);
  }

  // useEffect(() => {
  //   const localStorageLanguage = JSON.parse(
  //     localStorage.getItem(`kiosk_selected_language`)
  //   );
  //   if (localStorageLanguage) {
  //     dispatch(setKioskSelectedLanguage(localStorageLanguage));
  //   }
  //   // if (selected_language !== null && languages.length === 1) {
  //   //   history.push("/book-show");
  //   // }
  // }, [selected_language, history, dispatch, languages]);

  // const onSelectLanguage = (lng) => {
  //   let stylesSheets = document.styleSheets;
  //   let lastStyleSheet = document.styleSheets[stylesSheets.length - 1];
  //   if (lng === "ar") {
  //     require("../assets/css/arabic.css");
  //     lastStyleSheet.disabled = false;
  //     document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  //   } else {
  //     lastStyleSheet.disabled = true;
  //     document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
  //   }
  //   history.push("/book-show");
  //   i18n.changeLanguage(lng);
  // };

  const onSelectLanguage = (language) => {
    return new Promise((resolve) => {
      let lang = language.iso_2
        ? language.iso_2.toLowerCase()
        : getLangISOCode(language.lang_name);
      i18n.changeLanguage(lang);
      moment.locale(lang.toLowerCase());
      // if (lang && lang != lng) {
      //   window.location.reload();
      // } else {
      //   history.push("/book-show");
      // }
      console.log("language :>> ", language);
      // if (language.lang_id === 1) {
      dispatch(setKioskSelectedLanguage(language));
      console.log("languages :>> ", languages);
      return resolve(true);
    });

    // if (lng === "ar") {
    //   window.localStorage.setItem("lang", "ar");
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    // } else {
    //   // lastStyleSheet.disabled = true;
    //   window.localStorage.setItem("lang", "en");
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    // }

    // history.push("/book-show");

    // }
  };
  useEffect(() => {
    let timeInterval = "";
    if (localStorage.getItem("kiosk_selected_language")) {
      let objNew = JSON.parse(localStorage.getItem("kiosk_selected_language"));
      setLanguageChange(objNew.lang_name);
      changeLanguage(objNew);
    } else {
      timeInterval = setTimeout(() => {
        changeLanguage(languages[0]);
      }, 1500);
    }
    return () => {
      console.log("unmount");
      clearInterval(timeInterval);
    };
  }, []);
  const onCollectTicket = () => {
    if (language_change) {
      history.push(`/kiosk-collect-login`);
    } else {
      onSelectLanguage(languages[0]).then((res) => {
        history.push(`/kiosk-collect-login`);
      });
    }
  };
  const onBookNewTicket = () => {
    if (language_change) {
      push();
    } else {
      onSelectLanguage(languages[0]).then((res) => {
        push();
      });
    }
  };
  const push = () => {
    dispatch(clearMovieBookingType())
    switch (mode) {
      case 3:
      case 1:
        history.push(`/movies`);
        break;
      case 2:
        dispatch(resetTimer());
        clearTimer();
        dispatch(startTimer2());
        dispatch(clearSelectedFnbItems())
          .then(_ => {
            history.push(`/fnb-checkout/${cinema_id}`);
          });
        break;
      default:
        history.push(`/movies`);
    }
  };
  const changeLanguage = (lng) => {
    console.log("inside choose language. lng = ", lng);
    console.log("lang change");
    setLanguageChange(lng.lang_name);

    // if (lng === "ar") {
    //   window.localStorage.setItem("lang", "ar");
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    // } else {
    //   window.localStorage.setItem("lang", "en");
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    // }
    dispatch(setKioskSelectedLanguage(lng));
    let lang = lng.iso_2
      ? lng.iso_2.toLowerCase()
      : getLangISOCode(lng.lang_name);
    console.log("found lang = ", lang);
    i18n.changeLanguage(lang);
    moment.locale(lang.toLowerCase());
    // changeLanguageFunction();
    // window.location.reload();
  };

  const is_on_demand_active = window.localStorage.getItem("is_on_demand_active");
  useEffect(() => {}, [is_on_demand_active])

  return (
    <>
      <Container fluid>
        {/* {globalLanguages?.length > 1 && (
        <div className="gb">
          <GoBack className="" />
        </div>
      )} */}

        <div className="parent">
          <div className="movie_header" style={{ borderBottom: "none" }}>
            <Link to="/" className="logo">
              <img src={globalSettings.org_logo_url} />
            </Link>
            <span className="">
              {/* {cinema_name && cinema_name !== "" && cinema_name} */}
            </span>
            {/* <div className="dropdown_div">
						<select
							onChange={(e) => changeLanguage(e.target.value)}
							className="dropdown"
							name="language"
							id="language"
						>
							<option value="en" selected={!lang || lang === 'en'}>
								{t('common.ENGLISH')}
							</option>
							<option value="ar" selected={lang && lang === 'ar'}>
								{t('common.ARABIC')}
							</option>
						</select>
					</div> */}
            {languages?.length > 1 ? (
              <Dropdown>
                <Dropdown.Toggle
                  className="dropdownClassic"
                  // onChange={(e) => changeLanguage(e.target.value)}
                  name="language"
                  id="language"
                >
                  {language_change
                    ? language_change.toUpperCase()
                    : languages[0]?.lang_name.toUpperCase()}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdownItems">
                  {languages.map((lang) => (
                    <Dropdown.Item
                      value={lang.lang_id}
                      onSelect={() => changeLanguage(lang)}
                    >
                      {lang.lang_name.toUpperCase()}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div className="single-lang">
                {language_change
                  ? language_change.toUpperCase()
                  : languages[0]?.lang_name.toUpperCase()}
              </div>
            )}
          </div>
          <div className="page2_container">
            {/* <Link to="/" className="page2_movie_header">
            <h2 className="small_text">{t("common.WELCOME TO")}</h2>
            <div
              className="logo2"
            // style={{ backgroundImage: `url(${globalSettings.org_logo_url})` }}
            ></div>
            <h2 className="bigg_text">{t("common.OASIS MALL")}</h2>
          </Link> */}
            <div className="page2_body">
              <div className="choose_action text-uppercase">
                {t("Choose your Action")}
              </div>
              <div className="huge_buttons position-relative">
                {mode !== 2 && (
                  <button
                    onClick={onCollectTicket}
                    className="left_button hb text-uppercase"
                  >
                    {t("Collect your Ticket")}
                  </button>
                )}
                <button onClick={onBookNewTicket} className="right_button hb">
                  {mode === 2
                    ? t("collect_or_book.BOOK CONCESSION")
                    : t("collect_or_book.BOOK A NEW SHOW")}
                </button>

                {
                  is_on_demand_active === 'true' &&
                  (<button onClick={() => history.push("/on-demand")} className="right_button hb">
                    {t('On-demand-kiosk').toUpperCase()}
                  </button>)
                }
                {/* <aside onClick={click} className="hidden-block" /> */}
              </div>
            </div>
          </div>
        </div>
        <aside onClick={click} className="hidden-block" />
      </Container>
      <Modal
        show={showSettingsPopup}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-time-up"
        backdrop="static"
      >
        <Modal.Header closeButton={false}>
          <p className="kumbh fw-bold frxl-36 frl-32 frm-28 frs-24 ls-4">
            {t("Login to Proceed")}
          </p>
        </Modal.Header>
        <Modal.Body>
          <Row className="input-wrapper">
            <Col className="d-flex flex-column align-items-center">
              <input
                className={`kiosk-input ${authError ? "error" : ""}`}
                type="text"
                value={username}
                placeholder={t("Enter Username")}
                onChange={(e) => onChangeUsernameInput(e)}
                onClick={(e) => onChangeUsernameInput(e)}
                onFocus={() => {
                  setShowUsernameKeyboard(true);
                  setShowPasswordKeyboard(false);
                }}
                ref={usernameKeyboard}
              />

              {showUsernameKeyboard && (
                <KeyboardWrapper
                  keyboardRef={usernameKeyboard}
                  onChange={setUsername}
                  setShowKeyboard={setShowUsernameKeyboard}
                />
              )}

              <input
                className={`kiosk-input ${authError ? "error" : ""}`}
                type="password"
                value={password}
                placeholder={t("Enter Password")}
                onChange={(e) => onChangePasswordInput(e)}
                onClick={(e) => onChangePasswordInput(e)}
                onFocus={() => {
                  setShowPasswordKeyboard(true);
                  setShowUsernameKeyboard(false);
                }}
                ref={passwordKeyboard}
              />

              {showPasswordKeyboard && (
                <KeyboardWrapper
                  keyboardRef={passwordKeyboard}
                  onChange={setPassword}
                  setShowKeyboard={setShowPasswordKeyboard}
                />
              )}

              <p
                className="error-p"
                onClick={() => {
                  setShowUsernameKeyboard(false);
                  setShowPasswordKeyboard(false);
                }}
              >
                {authError && (
                  <span className="form-error-text d-block">
                    {t("Please enter valid credentials")}
                  </span>
                )}
              </p>
            </Col>
          </Row>
          <Row
            className="input-wrapper"
            onClick={() => {
              setShowUsernameKeyboard(false);
              setShowPasswordKeyboard(false);
            }}
          ></Row>
          <Row
            onClick={() => {
              setShowUsernameKeyboard(false);
              setShowPasswordKeyboard(false);
            }}
          >
            <Col className="">
              <button
                className="blue-btn mx-auto mt-5 px-4 mxh-47"
                onClick={onSubmitLoginForm}
              >
                {showProceedSpinner ? (
                  <Spinner animation="border" role="status" className="spinner">
                    <span className="sr-only">{t("common.Loading...")}</span>
                  </Spinner>
                ) : (
                  t("common.PROCEED")
                )}
              </button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <span
            onClick={() => setShowSettingsPopup(false)}
            className="blue-btn mxh-47 px-4 frxl-18 frl-16 frs-14"
          >
            {t("Go Back").toUpperCase()}
          </span>
        </Modal.Footer>
      </Modal>
    </>
    // <div className="parent">
    //   <div id="printableArea"></div>
    //   {/* <div className="go_back">
    // 		<div className="button_wrap">
    // 			<div className="go_back_button"></div>
    // 		</div>
    // 		<h2 className="big_text">GO BACK</h2>
    // 	</div> */}
    //   <div className="page2_container">
    //     <Link to="/" className="page2_movie_header">
    //       <h2 className="small_text">{t("common.WELCOME TO")}</h2>
    //       <div
    //         className="logo2"
    //         // style={{ backgroundImage: `url(${globalSettings.org_logo_url})` }}
    //       ></div>

    //       {/* <h2 className="bigg_text">{t('common.OASIS MALL')}</h2> */}
    //     </Link>
    //     <div className="page2_body">
    //       <div className="choose_action">
    //         {t("choose_lang.CHOOSE YOUR LANGUAGE")}
    //       </div>
    //       <div className="huge_buttons">
    //         {languages.map((language) => (
    //           <button
    //             key={language.lang_id}
    //             onClick={() => onSelectLanguage(language)}
    //             className="left_button hb"
    //           >
    //             {language.lang_name}
    //           </button>
    //         ))}
    //         {/* <button
    //           onClick={() => onSelectLanguage("en")}
    //           className="left_button hb"
    //         >
    //           {t("choose_lang.ENGLISH")}
    //         </button> */}
    //         {/* <button
    //           onClick={() => onSelectLanguage("ar")}
    //           className="right_button hb"
    //         >
    //           {t("choose_lang.ARABIC")}
    //         </button> */}
    //         {/* <button onClick={() => history.push('/process-payment')} className="right_button hb">
    // 					payment page
    // 				</button> */}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
export default withNamespaces()(ChooseLanguage);
