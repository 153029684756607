import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchSeatLayoutData,
  fetchTicketPrice,
  selectSeats,
  reserveSeats,
  setSeatsString,
  fetchSeatTypes,
  onSetCompleteRateCard,
  onSetCompleteNewTotalAmount,
  clearStore,
  fetchVoucherList,
  updateSeatLayout,
  setReservationID,
  releaseRealtimeReservedSeats,
  //   validateVoucher,
} from "@store/booking/bookingActions";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import MovieHeader from "@components/partials/MovieHeader";
import CSpinner from "@components/partials/Spinner";
import screen from "../assets/imgs/svgs/screen.svg";
import ip1 from "../assets/imgs/ip1.jpg";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import i18n from "../plugins/i18n";
import movieDef from "@assets/imgs/movie-default-portrait.jpg";
import whiteSofa from "../assets/imgs/svgs/sofa.svg";
import wheelChair from "../assets/imgs/svgs/wheel-chair.svg";
import selectedSeat from "../assets/imgs/svgs/seat-sl-selected.svg";
import occupiedSeat from "../assets/imgs/svgs/seat-sl-occupied.svg";
import whiteSeat from "../assets/imgs/svgs/seat-sl.svg";
import { getLangSpecificAttribute } from "../helper/Languages";
import {
  timeConvert,
  isImageURLNull,
  translateSessionAttribute,
} from "@helper/formatting.js";
import { globalConfigContext } from "@context/GlobalConfigContext";
import { getValueFromGlobalOptions } from "@helper/utils";
import { backendSocket } from "@src/index";
import { toast } from "react-toastify";

const els3 = [1, 2, 3];
const els10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const el16 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
const checkSeatReserved = (seat, srtid) => {
  srtid = parseInt(srtid);
  if (
    [8, 9, 12, 13].includes(srtid) ||
    seat.covidBlocked ||
    seat.covidBlockedTemp
  ) {
    return true;
  }
  return false;
};

let showPno = (e) => {
  const pno = document.querySelector("#pno");
  const bankOffers = document.querySelector("#bankOffers");
  const pnoBtn = document.querySelector("#pnoBtn");
  const bankOffersBtn = document.querySelector("#bankOffersBtn");

  if (pno.classList.contains("d-none")) {
    pno.classList.add("d-flex");
    bankOffers.classList.remove("d-none");
    bankOffers.classList.add("d-none");
    bankOffers.classList.remove("d-flex");
  }
  if (!e.target.classList.contains("active")) {
    e.target.classList.add("active");
    bankOffersBtn.classList.remove("active");
  }
};

let showBankOffers = (e) => {
  const pno = document.querySelector("#pno");
  const bankOffers = document.querySelector("#bankOffers");
  const pnoBtn = document.querySelector("#pnoBtn");
  const bankOffersBtn = document.querySelector("#bankOffersBtn");

  if (bankOffers.classList.contains("d-none")) {
    pno.classList.add("d-none");
    bankOffers.classList.add("d-flex");
    pno.classList.remove("d-flex");
    bankOffers.classList.remove("d-none");
  }
  if (!e.target.classList.contains("active")) {
    e.target.classList.add("active");
    pnoBtn.classList.remove("active");
  }
};

const getSeatClass = (status, singleSeat) => {
  if (singleSeat.sst_seat_type === "Wheelchair") {
    return "wheel-chair";
  } else if (status === 3) {
    return "wheel-chair";
  } else return "cell";
};

const SeatLayout = (props) => {
  // const { arabic, currency } = useContext(globalConfigContext);
  const arabic = false;
  const currency = {};

  const { session_id, movie_id, screen_id } = props.match.params;

  const global = useSelector((state) => state.global);
  const {
    globalSettings: { org_kiosk_screen_image_url },
    cinema: { cinema_id },
    currency: { curr_code },
    selected_language: { lang_id },
    dummy_image,
    mode,
  } = global;

  const t = props.t;

  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const reservation_id = useSelector((state) => state.booking.reservation_id);
  const globalOptions = useSelector((state) => state.global.globalOptions);
  const realTimeSeatLayout = getValueFromGlobalOptions(
    globalOptions,
    "ENABLE_SEAT_LAYOUT_REAL_TIME"
  );
  const guest_id = window.localStorage.getItem("guest_user_id");

  useEffect(() => {
    dispatch(clearStore());
    dispatch(
      fetchSeatLayoutData({
        ss_id: session_id,
        md_id: movie_id,
        screen_id,
      })
    );
    // dispatch(fetchSeatTypes({ screen_id }));
    // dispatch(fetchTicketPrice({ session_id, movie_id }));
    const reservation_id = Math.floor(100000000 + Math.random() * 900000000);
    dispatch(setReservationID(reservation_id));

    console.log("seatLayoutSocket in seatlayout page", backendSocket);
    if (
      backendSocket &&
      backendSocket.connected &&
      realTimeSeatLayout === "Y"
    ) {
      backendSocket.emit("joined-seat-layout", {
        ss_id: session_id,
      });

      backendSocket.on("seat-layout-changes", function (payload) {
        let { sl_id, status } = payload;
        console.log("getting response for seat layout changes ===> ", payload);
        dispatch(
          updateSeatLayout({
            sl_id,
            status,
          })
        );
      });

      backendSocket.on("select-seat-response", function (res) {
        if (!res.status) {
          console.log("select-seat-response status false: ", res);
          toast.error(res.message);
          dispatch(clearStore());
          dispatch(
            fetchSeatLayoutData({
              ss_id: session_id,
              md_id: movie_id,
              screen_id,
            })
          );
          const reservation_id = Math.floor(
            100000000 + Math.random() * 900000000
          );
          dispatch(setReservationID(reservation_id));
          setSeats([]);
        }
      });
    }

    return () => {
      if (backendSocket) {
        backendSocket.off("seat-layout-changes");
        backendSocket.off("select-seat-response");
      }
    };
  }, []);
  const [totalSeatBooked, settotalSeatBooked] = useState(0);
  // const [totalAmountPrice, settotalAmountPrice] = useState(0);
  let totalAmountPrice = useRef(0);
  let totalNetAmout = useRef(0);
  // const [totalNetAmout, settotalNetAmout] = useState(0);
  let seatsSelectedRateGroupNew = useRef([]);
  const [rateCardGroups, setrateCardGroups] = useState([]);
  const [loader, setloader] = useState(false);

  //
  let rowIndex,
    seatIndex,
    singleSeat = {};
  //
  let singleRow = Object;
  singleRow.PhysicalName = "";
  const [seats, setSeats] = useState([]);
  const seatLayout = useSelector((state) => state.booking.seat_layout);
  const seat_Types = useSelector((state) => state.booking.seat_types);
  const [userSelectedSeatsNew, setUserSelectedSeatsNew] = useState([]);
  const selectedTicketPrice = useSelector(
    (state) => state.booking.selectedTicketPrice
  );
  const movies = useSelector((state) => state.movies.now_playing_movies);
  const cinema = useSelector((state) => state.global.cinema);
  const movieDetails = movies.find((movie) => movie.md_id == movie_id);
  //   const movieDetails = useSelector((state) => state.movies.movie_details);
  // const showDetails = useSelector((state) =>
  //   state.booking.show_details && state.booking.show_details.length > 0
  //     ? state.booking.show_details[0]
  //     : {}
  // );
  //const allshowDetails = useSelector((state) => state.movies.show_times);
  // const showDetails = allshowDetails.find((show) => show.ss_id == session_id);

  const showDetails = useSelector((state) => state.movies.selected_session);

  const selected_seats = useSelector((state) => state.booking.seats_selected);
  const seatSelectError = useSelector(
    (state) => state.booking.seat_select_error
  );
  const onSelectSeats = (seatTypeIndex, singleSeat) => {
    const { sl_seat_name } = singleSeat;
    dispatch(selectSeats({ seatTypeIndex, SeatTypes: singleSeat }));
    dispatch(
      fetchVoucherList({
        seat: singleSeat,
        cinema_id,
        movie_details_id: movie_id,
        screen_id,
        show_date_time: showDetails.ss_actual_start_date_time,
        aggregator_cinema_id: cinema_id,
        selectSeats: selected_seats,
      })
    );
    let doesSeatExist = false;
    const newSeats = [...seats];
    if (newSeats.indexOf(sl_seat_name) > -1) {
      newSeats.splice(newSeats.indexOf(sl_seat_name), 1);
      doesSeatExist = true;
    } else {
      newSeats.push(sl_seat_name);
    }
    setSeats(newSeats);
    setUserSelectedSeatsNew(singleSeat);
    console.log("selected_seats :>> ", selected_seats);

    if (
      backendSocket &&
      backendSocket.connected &&
      realTimeSeatLayout === "Y"
    ) {
      let payload = {
        seat: singleSeat,
        reservation_id,
        ss_id: session_id,
        is_delete: false,
        guest_user_id: guest_id,
      };

      if (doesSeatExist) {
        payload.is_delete = true;
        backendSocket.emit("deselect-seat", payload);
      } else {
        backendSocket.emit("select-seat", payload);
      }
    }
  };

  const voucherCodeData = useSelector((state) => state.booking.voucherCodeData);
  const voucherCodeError = useSelector(
    (state) => state.booking.voucherCodeError
  );
  const [showSeatLimitError, setShowSeatLimitError] = useState(false);
  const [showProceedSpinner, setShowProceedSpinner] = useState(false);
  const [showVoucherPopup, setShowVoucherPopup] = useState(false);
  const [voucherCode, setVoucherCode] = useState(null);
  const [voucherCodeLoading, setVoucherCodeLoading] = useState(false);
  const [voucherSeatsExceededError, setVoucherSeatsExceededError] =
    useState(false);
  const [directProceed, setdirectProceed] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(true);
  // Seat Types
  const rateCardGroupsFunction = useCallback(() => {
    console.log("rateCardGroupsFunction fired");
    console.log("selected_seats", selected_seats);
    if (selected_seats.length > 0) {
      let array = [];
      let checkCheckoutRateCardNeeded = 0;
      settotalSeatBooked(0);
      totalAmountPrice.current = 0;
      totalNetAmout.current = 0;
      selected_seats.map((x) => {
        let find_In = array.findIndex((new1) => {
          return new1.sst_id == x.sst_id;
        });
        if (find_In >= 0) {
          array[find_In]["AllSeats"].push(x);
          if (array[find_In]["rate_card_group"].length == 1) {
            array[find_In]["totalSelected"] =
              array[find_In]["totalSelected"] + 1;
            array[find_In]["rate_card_group"][0].totalSeatSelected =
              array[find_In]["rate_card_group"][0].totalSeatSelected + 1;
            totalAmountPrice.current =
              parseFloat(totalAmountPrice.current) +
              parseFloat(array[find_In]["rate_card_group"][0].rc_total_amount);
            totalNetAmout.current =
              parseFloat(totalNetAmout.current) +
              parseFloat(array[find_In]["rate_card_group"][0].rc_net_amount);
            settotalSeatBooked((prev) => prev + 1);
            setdirectProceed(true);
          } else if (array[find_In]["rate_card_group"].length > 1) {
            checkCheckoutRateCardNeeded = checkCheckoutRateCardNeeded + 1;
          }
        } else {
          let arrayCardGroup = [];
          x.rate_card_group.map((rg) => {
            rg["totalSeatSelected"] = 0;
            arrayCardGroup.push(rg);
          });
          let obj = {
            srt_name: x.srt_name,
            sst_id: x.sst_id,
            AllSeats: [x],
            totalSelected: 0,
            rate_card_group: arrayCardGroup,
          };
          if (arrayCardGroup.length == 1) {
            obj["totalSelected"] = 1;
            obj["rate_card_group"][0].totalSeatSelected = 1;
            totalAmountPrice.current =
              parseFloat(totalAmountPrice.current) +
              parseFloat(obj["rate_card_group"][0].rc_total_amount);
            totalNetAmout.current =
              parseFloat(totalNetAmout.current) +
              parseFloat(obj["rate_card_group"][0].rc_net_amount);
            settotalSeatBooked((prev) => prev + 1);
          } else if (arrayCardGroup.length > 1) {
            checkCheckoutRateCardNeeded = checkCheckoutRateCardNeeded + 1;
          }
          array.push(obj);
        }
      });
      seatsSelectedRateGroupNew.current = array;
      console.log(
        "🚀 ~ file: SeatLayout.js ~ line 259 ~ rateCardGroupsFunction ~ seatsSelectedRateGroupNew",
        seatsSelectedRateGroupNew
      );

      if (
        checkCheckoutRateCardNeeded == 0 &&
        array[0]["rate_card_group"].length > 0
      ) {
        dispatch(
          onSetCompleteRateCard({
            status: true,
            seatGroup: seatsSelectedRateGroupNew.current,
          })
        );
        dispatch(
          onSetCompleteNewTotalAmount({
            totalAmount: totalAmountPrice.current,
            netAmount: totalNetAmout.current,
          })
        );
      } else if (checkCheckoutRateCardNeeded > 0) {
        dispatch(
          onSetCompleteRateCard({
            status: false,
            seatGroup: seatsSelectedRateGroupNew.current,
          })
        );
      }
      setrateCardGroups(array);
    } else {
      seatsSelectedRateGroupNew.current = [];
    }
  }, [selected_seats]);
  const totalCount = (rg, index, rg_index, type) => {
    console.log("seatsSelectedRateGroupNew", seatsSelectedRateGroupNew.current);
    let x =
      seatsSelectedRateGroupNew.current[index]["rate_card_group"][rg_index];
    if (
      type == "increment" &&
      seatsSelectedRateGroupNew.current[index].totalSelected ==
        seatsSelectedRateGroupNew.current[index].AllSeats.length
    ) {
      return false;
    }

    if (
      type == "increment" &&
      totalSeatBooked + 1 <= selected_seats.length &&
      x["totalSeatSelected"] <= selected_seats.length
    ) {
      x["totalSeatSelected"] = x["totalSeatSelected"] + 1;
      seatsSelectedRateGroupNew.current[index].totalSelected =
        seatsSelectedRateGroupNew.current[index].totalSelected + 1;
    } else if (
      seatsSelectedRateGroupNew.current[index].totalSelected >= 1 &&
      type == "decrement" &&
      x["totalSeatSelected"] >= 1
    ) {
      x["totalSeatSelected"] = x["totalSeatSelected"] - 1;
      seatsSelectedRateGroupNew.current[index].totalSelected =
        seatsSelectedRateGroupNew.current[index].totalSelected - 1;
    }
    let totalAmount = 0;
    let totalSeatBooked2 = 0;
    let totalNetAmout2 = 0;
    seatsSelectedRateGroupNew.current.map((z) => {
      z.rate_card_group.map((y) => {
        totalAmount =
          parseFloat(totalAmount) +
          parseFloat(y.totalSeatSelected) * parseFloat(y.rc_total_amount);

        totalNetAmout2 =
          parseFloat(totalNetAmout2) +
          parseFloat(y.totalSeatSelected) * parseFloat(y.rc_net_amount);
      });

      totalSeatBooked2 = parseInt(totalSeatBooked2) + parseInt(z.totalSelected);
    });
    totalAmountPrice.current = totalAmount;

    settotalSeatBooked(totalSeatBooked2);
    totalNetAmout.current = totalNetAmout2;
    x["tt_name"] = x["tt_name"] + " ";
    if (totalSeatBooked2 == selected_seats.length) {
      dispatch(
        onSetCompleteRateCard({
          status: true,
          seatGroup: seatsSelectedRateGroupNew.current,
        })
      );
      dispatch(
        onSetCompleteNewTotalAmount({
          totalAmount: totalAmountPrice.current,
          netAmount: totalNetAmout.current,
        })
      );
    } else {
      dispatch(
        onSetCompleteRateCard({
          status: false,
          seatGroup: seatsSelectedRateGroupNew.current,
        })
      );
    }
  };
  useEffect(() => {
    console.log("showDetails", showDetails);
  }, [showDetails]);
  useEffect(() => {
    console.log("use effect firing");
    rateCardGroupsFunction();
  }, [selected_seats]);
  const handleClose = () => {};
  const redirectToFnb = () => {
    props.history.push(`/fnb/${session_id}/${cinema_id}`);
  };
  const voucherHandler = async () => {
    // if (voucherCodeLoading) {
    //   return;
    // }
    // if (voucherCode) {
    //   setVoucherCodeLoading(true);
    //   dispatch(
    //     validateVoucher({
    //       voucher_code: voucherCode,
    //       cinema_id,
    //       session_id,
    //     })
    //   )
    //     .then((data) =>
    //       data !== "INVALID" ? setShowVoucherPopup(false) : null
    //     )
    //     .finally(() => setVoucherCodeLoading(false));
    // }
  };
  const onProceed = async () => {
    if (selected_seats.length === 0) {
      return;
    }
    if (directProceed) {
      onProceedRateCardGroup();
    } else {
      setShow(true);
    }
    await dispatch(setSeatsString({ seats: seats.toString(), session_id }));
  };
  const onProceedRateCardGroup = async () => {
    setloader(true);
    await dispatch(
      reserveSeats({
        selected_seats,
        schedule_show_id: session_id,
        history: props.history,
        cinema_id,
        seatGroup: seatsSelectedRateGroupNew.current,
        cinema,
        callback: setloader,
        session_id,
        movie_id,
        screen_id,
        callback2: setShow,
        callback3: setSeats,
        mode,
      })
    );
  };
  const history = useHistory();
  const onClickBack = () => {
    if (selected_seats && selected_seats.length > 0) {
      dispatch(releaseRealtimeReservedSeats());
    } else {
      dispatch(setReservationID(null));
    }
    history.go(-1);
  };
  return (
    // manav
    <>
      <div className="">
        <CSpinner active={showSpinner} />
        <MovieHeader />
        <div className="bg-light-black">
          <Container fluid>
            <div>
              <Row className="align-items-center py-3 mb-40">
                <Col>
                  <div className="go_back">
                    <div className="button_wrap" onClick={onClickBack}>
                      <div className="go_back_button"></div>
                    </div>
                    <h2 className="big_text">{t("Go Back")}</h2>
                  </div>
                </Col>
              </Row>

              {/* <Col>
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src={movieDetails.md_thumbnail_url}
                      alt="poster"
                      height="300"
                      className=""
                    />
                  </div>
                  <div className="d-flex flex-column kumbh px-4">
                    <p className="fw-bold mb-2">
                      {showDetails.movie_title} {showDetails.lang_name}
                    </p>
                    <p className="fw-reg mb-2">{t("Date")}</p>
                    <p className="fw-bold mb-2">
                      {moment(showDetails.ss_start_date).format("DD MMM YYYY")}
                    </p>
                    <p className="fw-reg mb-2">{t("Show Details")}</p>
                    <p className="fw-bold">
                      {moment(showDetails.ss_start_show_time).format("HH:mm")}
                    </p>
                  </div>
                  <div className="d-flex flex-column kumbh px-4">
                    <div className="group frl-12 frm-10 frs-8 fw-reg mb-2">
                      <span className="border-right pr-2">
                        {showDetails.rating}
                      </span>
                      <span className="pl-2">
                        {timeConvert(showDetails.mrrdr_runtime)}
                      </span>
                    </div>
                    <p className="fw-reg mb-2">{t("Seats")}</p>
                    <p className="fw-bold mb-2">
                      {seats.length > 0
                        ? seats.toString()
                        : t("seatlayout_page.seats-not-selected")}
                    </p>
                  </div>
                </div>
              </Col> */}
              <Row>
                    <Col xs={12} className="ticket-header bb-1 pb-3">
                      <div className="d-flex">
                        <img
                          src={
                            getLangSpecificAttribute(
                              showDetails.MovieContent,
                              lang_id,
                              "artwork"
                            ) || dummy_image
                          }
                          className=""
                        />
                        <div className="ml-1 d-flex flex-column align-items-center w-100">
                          <Row className="mx-0 w-100 pb-2 pt-1">
                            <Col md={5}>
                              <span className="fw-bold frs-28  lh-1">
                                {getLangSpecificAttribute(
                                  showDetails.MovieContent,
                                  lang_id,
                                  "mc_title",
                                  true,
                                ) || showDetails?.original_title_of_movie || showDetails?.movie_title}
                              </span>
                            </Col>
                            <Col md={4}>
                              <div className="group">
                                <span
                                  className={`${
                                    arabic
                                      ? "border-left pl-2"
                                      : "border-right pr-2"
                                  }`}
                                >
                                  {showDetails?.rating}
                                </span>
                                <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                                  {timeConvert(showDetails?.mrrdr_runtime)}
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mx-0 w-100">
                            <Col xs={5}>
                              <span>
                                <p>{t("seatlayout_page.Date")}</p>
                                <h4>
                                  {moment(showDetails?.ss_start_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </h4>
                              </span>
                            </Col>
                            <Col xs={4}>
                              <span>
                                <p>{t("seatlayout_page.Seats")}</p>
                                <h4>
                                  {seats.length > 0
                                    ? seats.toString()
                                    : t("seatlayout_page.seats-not-selected")}
                                </h4>
                              </span>
                            </Col>
                          </Row>
                          <Row className="mx-0 w-100">
                            <Col xs={5}>
                              <span>
                                <p>{t("seatlayout_page.Show Details")}</p>
                                <h4>
                                  {/* {showDetails?.SessionAttribute
                                    ? showDetails?.SessionAttribute
                                    : "PREMIUM"}
                                  , */}
                                  {moment(
                                    showDetails?.ss_start_show_time,
                                    "HH:mm"
                                  ).format("HH:mm")}
                                </h4>
                              </span>
                            </Col>
                            {/* <Col xs={4}>
                              <span>
                                <p>{t("seatlayout_page.Seats")}Voucher Code</p>
                                <h4>
                                  {false
                                    ? "PD 10"
                                    : t("seatlayout_page.No Voucher Added")}
                                </h4>
                              </span>
                            </Col> */}
                            {/* <Col xs={3} className="d-flex align-items-center">
                              <span className="blue-btn px-4">
                                {false
                                  ? t("seatlayout_page.CHANGE")
                                  : t("seatlayout_page.ADD")}{" "}
                                {t("seatlayout_page.VOUCHER")}
                              </span>
                            </Col> */}
                          </Row>
                          {/* <div className="d-flex flex-column justify-content-between h-100 w-50">
                            <div className="label-ticket">
                              <h6 className="right">{showDetails?.rating}</h6>
                              <h6 className="left">
                                {timeConvert(showDetails?.mrrdr_runtime)}
                              </h6>
                            </div>
                          </div>
                          <div className="pl-4 d-flex flex-column align-items-start justify-content-between h-100 w-50">
                          </div> */}
                        </div>
                      </div>
                    </Col>
              </Row>
            </div>

            {false && (
              <Row className="mb-3 d-none d-md-flex sticky-alert-top">
                <Col>
                  <Alert variant="success">
                    <Alert.Heading>
                      {t("seatlayout_page.errors.imp-msg")}
                    </Alert.Heading>
                    <p>{t("seatlayout_page.bogo-message")}</p>
                  </Alert>
                </Col>
              </Row>
            )}
            {false && (
              <Row className="mb-3 d-none d-md-flex sticky-alert-top">
                <Col>
                  <Alert variant="danger" dismissible>
                    <Alert.Heading>
                      {t("seatlayout_page.errors.imp-msg")}
                    </Alert.Heading>
                    <p>
                      {t("seatlayout_page.You can select only")}{" "}
                      {t("seatlayout_page.Seats")}
                    </p>
                  </Alert>
                </Col>
              </Row>
            )}
            {false && (
              <Row className="my-3 d-none d-md-flex sticky-alert-top">
                <Col>
                  <Alert variant="danger" dismissible>
                    <Alert.Heading>
                      {t("seatlayout_page.errors.imp-msg")}
                    </Alert.Heading>
                    <p>{t("seatlayout_page.errors.seat-limit-err")}</p>
                  </Alert>
                </Col>
              </Row>
            )}
            {false && (
              <Row className="my-3 d-none d-md-flex sticky-alert-top">
                <Col>
                  <Alert variant="danger" dismissible>
                    <Alert.Heading>
                      {t("seatlayout_page.errors.imp-msg")}
                    </Alert.Heading>
                    <p>{t("seatlayout_page.errors.seat-limit-err")}</p>
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="pt-5">
              <Col>
                <div className="position-relative">
                  <div className="stw">
                    <img
                      className="img-fluid"
                      src={
                        org_kiosk_screen_image_url
                          ? org_kiosk_screen_image_url
                          : screen
                      }
                      alt="Screen"
                    />
                  </div>
                  <div className="stw-text">{showDetails?.screen_name}</div>
                </div>
              </Col>
            </Row>

            {seatLayout && seatLayout.length > 0 ? (
              <Row className="mb-4">
                <Col className="table-responsive" id="seat-layout-table">
                  <Table borderless responsive className="first-table">
                    {/* Section loop, premium, macro xe etc. */}
                    {seatLayout.map((seatTypes, seatTypeIndex) => (
                      <tr>
                        <td>
                          {/* <div className="category-title">
                            <span>{seatTypes.Description}</span>
                          </div> */}
                          <tr>
                            <td className="second-table">
                              <Table borderless>
                                {/* {seatTypes.Rows.map((singleRow, rowIndex) => (
                                  <tr key="index" className="">
                                    <td className="blurred-white ls-2 fw-reg frxl-20 frl-18 frm-16 frs-14  text-center cell">
                                      {singleRow.PhysicalName}
                                    </td>
                                  </tr>
                                ))} */}
                              </Table>
                            </td>
                            <td className="second-table">
                              <Table borderless>
                                {seatTypes.seatsFinal.map(
                                  (singleRow, rowIndex) => (
                                    <tr key={rowIndex} className="seats">
                                      {singleRow.map((singleSeat, seatIndex) =>
                                        singleSeat.srt_id == 2 ? (
                                          <td key={seatIndex}>
                                            <div className="cell empty"></div>
                                          </td>
                                        ) : (
                                          <td>
                                            <div
                                              className={`${getSeatClass(
                                                singleSeat.sl_seat_status,
                                                singleSeat
                                              )} ${
                                                singleSeat.SeatSelected
                                                  ? "active"
                                                  : checkSeatReserved(
                                                      singleSeat,
                                                      singleSeat.seat_reserve_type_id
                                                    )
                                                  ? "reserved"
                                                  : ""
                                              } ${
                                                singleSeat.Status === 4
                                                  ? "empty"
                                                  : ""
                                              } ${
                                                singleSeat.covidBlocked ||
                                                singleSeat.covidBlockedTemp
                                                  ? "covid-blocked"
                                                  : ""
                                              }
                                      `}
                                              onClick={() => {
                                                !checkSeatReserved(
                                                  singleSeat,
                                                  singleSeat.seat_reserve_type_id
                                                ) &&
                                                  onSelectSeats(
                                                    seatIndex,
                                                    singleSeat
                                                  );
                                              }}
                                            >
                                              {/* checkSeatValid(
                                                    singleSeat.Status,
                                                    seatIndex,
                                                    rowIndex,
                                                    seatTypes,
                                                    singleSeat
                                                  ) */}
                                              {singleSeat.SeatSelected
                                                ? singleSeat.sl_seat_name
                                                : ""}
                                            </div>
                                          </td>
                                        )
                                      )}
                                    </tr>
                                  )
                                )}
                              </Table>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    ))}
                  </Table>
                </Col>
              </Row>
            ) : (
              <Row className="mb-4">
                <Col className="table-responsive" id="seat-layout-table">
                  <div className="text-center">
                    <div>
                      <Spinner
                        animation="border"
                        role="status"
                        className="spinner"
                      >
                        <span className="sr-only">{t("Loading")}</span>
                      </Spinner>
                    </div>
                    <br />
                    <h6>{t("seatlayout_page.loading-seatlayout")}</h6>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              <Col className="seats-desc py-5">
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                  {/* {seat_Types.map(seat=>(
                    <span>
                      <span>
                        {seat}
                      </span>
                    </span>
                  ))} */}
                  <span>
                    <img src={occupiedSeat} alt="img" />
                    <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                      {t("seatlayout_page.chair-types.occupied")}
                    </span>
                  </span>
                  <span>
                    <img src={whiteSeat} alt="img" />
                    <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                      {t("seatlayout_page.chair-types.open")}
                    </span>
                  </span>
                  <span class="seat-selected-span">
                    {/* <img src={selectedSeat} alt="img" /> */}
                    <svg
                      width="60px"
                      height="48px"
                      viewBox="0 0 60 48"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="5.2-Seats-and-voucher-selected-"
                          transform="translate(-401.000000, -1303.000000)"
                          fill="#6236FF"
                          fill-rule="nonzero"
                        >
                          <g
                            id="Group-10"
                            transform="translate(0.000000, 161.000000)"
                          >
                            <g
                              id="np_cinema-seat_2049821_000000-copy-35"
                              transform="translate(401.000000, 1142.000000)"
                            >
                              <path
                                d="M53.293391,21.2843444 L50.3802798,21.2843444 L50.3802798,7.09478148 C50.3802798,3.17715666 46.4642542,0 41.6355597,0 L18.3144403,0 C13.4857458,0 9.56972017,3.17715666 9.56972017,7.09478148 L9.56972017,21.2843444 L6.65660903,21.2843444 C3.43470433,21.2843444 0.825,23.401711 0.825,26.0156556 L0.825,44.9343161 C0.825,45.5617785 1.13380055,46.1626214 1.68037355,46.6060169 C2.22694654,47.0494123 2.96745644,47.3 3.74083949,47.3 L12.4855597,47.3 C13.2589427,47.3 13.9995156,47.0494634 14.5460256,46.6060169 C15.0925356,46.1625703 15.4013992,45.5617785 15.4013992,44.9343161 L15.4013992,42.5686321 L44.5486008,42.5686321 L44.5486008,44.9343161 C44.5486008,45.5617785 44.8574014,46.1626214 45.4039744,46.6060169 C45.9505474,47.0494123 46.6910573,47.3 47.4644403,47.3 L56.2091605,47.3 C56.9825436,47.3 57.7231164,47.0494634 58.2696265,46.6060169 C58.8161365,46.1625703 59.125,45.5617785 59.125,44.9343161 L59.125,26.0156556 C59.125,24.7607874 58.5101342,23.5568881 57.4170512,22.6700404 C56.3239682,21.7831927 54.8400871,21.2843444 53.293391,21.2843444 Z"
                                id="Shape"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                      {t("seatlayout_page.chair-types.selected")}
                    </span>
                  </span>
                  <span>
                    <img src={whiteSofa} alt="img" />
                    <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                      {t("seatlayout_page.chair-types.lounger")}
                    </span>
                  </span>
                  <span>
                    <img src={wheelChair} alt="img" />
                    {t("seatlayout_page.chair-types.wheelchair")}
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="sticky-bar-margin">
              <Col sm={12}>
                {seatSelectError && (
                  <Alert variant="dark" className="text-center">
                    {seatSelectError.message}
                    {t("seatlayout_page.errors.try-later")}
                  </Alert>
                )}
              </Col>
            </Row>

            <Row className="align-items-center bgb mt-5 sticky-movie-bar">
              <Col xs={12} className="seat-footer">
                <Row className="py-4">
                  {/* <Col md={8}>
                    <div className={`d-flex px-3 ${arabic ? "bl-1" : "br-1"}`}>
                      <div>
                        <h3 className="f-grey">
                          {t("seatlayout_page.Total Payable")}
                        </h3>
                        <h1 className="fw-bold">
                          {curr_code}{" "}
                          {selectedTicketPrice.priceInCents.toFixed(2)}
                        </h1>
                        <h5>
                          {t("fnb_page.taxes")}: {curr_code}{" "}
                          {selectedTicketPrice.taxInCents.toFixed(2)}{" "}
                        </h5>
                      </div>
                      <div
                        className={`flex-center ${arabic ? "pr-4" : "pl-4"}`}
                      >
                        <h6
                          className="custom-label"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          {t("seatlayout_page.Total Bill")}
                        </h6>
                      </div>
                    </div>
                  </Col> */}
                  <Col
                    md={12}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <span
                      className={`blue-btn btn-lg px-5 ${
                        selected_seats.length === 0 ? "inactive-btn" : ""
                      }`}
                      onClick={onProceed}
                    >
                      {showProceedSpinner ? (
                        <Spinner
                          animation="border"
                          role="status"
                          className="spinner"
                        >
                          <span className="sr-only">{t("Loading")}</span>
                        </Spinner>
                      ) : (
                        t("common.PROCEED")
                      )}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-4"
          dialogClassName="modal-95w"
        >
          <div className="d-flex flex-column">
            <div className="d-flex border-bottom border-dark">
              <div className="col-6">
                <h5 className="font-weight-bold mt-2">
                  {t("seatlayout_page.Show Rate Card")}
                </h5>
              </div>
              <div className="col-6 text-right">
                <span
                  className="fw-bold h1 ml-auto cursor-pointer rate-card-close"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  &times;
                </span>
              </div>
            </div>

            <div className="d-flex flex-column">
              {rateCardGroups.length > 0 &&
                rateCardGroups.map((item, index) => (
                  <>
                    <div className="d-flex align-items-center border-bottom flex-wrap">
                      <div className="seat-type gold">
                        <div className="st-name">{item.srt_name}</div>
                        <div>
                          {item.totalSelected}/{item.AllSeats.length}
                        </div>
                      </div>
                      {item.rate_card_group.map((rg, rg_index) => (
                        <>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className={`category`}>
                              <div className="category-name">{rg.tt_name}</div>
                              <div className="category-price">
                                {curr_code} {rg.rc_total_amount}
                              </div>
                              <div className="category-seats">
                                <div
                                  onClick={() =>
                                    totalCount(rg, index, rg_index, "decrement")
                                  }
                                  className={
                                    rg.totalSeatSelected == 0
                                      ? "mod-disabled"
                                      : "mod-btn"
                                  }
                                >
                                  -
                                </div>
                                <div>{rg.totalSeatSelected}</div>
                                <div
                                  onClick={() =>
                                    totalCount(rg, index, rg_index, "increment")
                                  }
                                  className={
                                    item.totalSelected == item.AllSeats.length
                                      ? "mod-disabled"
                                      : "mod-btn"
                                  }
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ))}
              {/* End Here */}
              <div className="d-flex flex-column w-100 border-bottom">
                <div className="__rate_card_total">
                  <h5>{t("seatlayout_page.Total Seats")}</h5>
                  <h4 className="font-weight-bold">
                    {totalSeatBooked}/{selected_seats.length}
                  </h4>
                </div>
                <div className="__rate_card_total __rate_card_total__price">
                  <h5>{t("seatlayout_page.Total Price")}</h5>
                  <h4 className="font-weight-bold">
                    {curr_code} {totalAmountPrice.current}
                  </h4>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center">
                <span
                  className={`blue-btn mt-3 px-4 ${
                    totalSeatBooked == selected_seats.length
                      ? ""
                      : "inactive-btn"
                  }`}
                  onClick={() =>
                    totalSeatBooked == selected_seats.length &&
                    onProceedRateCardGroup()
                  }
                >
                  {t("common.PROCEED")}
                  {loader && (
                    <span className="pl-2">
                      <Spinner
                        animation="border"
                        role="status"
                        className="spinner"
                      >
                        <span className="sr-only">{t("Loading")}.</span>
                      </Spinner>
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      {/*<Modal
        show={showVoucherPopup}
        onHide={() => setShowVoucherPopup(false)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-4 for-btn"
      >
        <div className="wrapper">
          <div className="bg-light-black">
            <Modal.Header
              closeButton
              className={`p-0 border-0 pt-2 pr-2`}
            ></Modal.Header>
            <div className="text-center py-md-5 px-md-5 px-sm-1 my-5 px-3">
              <h4 className="font-weight-bold">
                {t("seatlayout_page.voucher-popup.title")}
              </h4>
              <p className="px-md-5 pt-3">
                {t("seatlayout_page.voucher-popup.subtitle")}
              </p>
            </div>
            <div className="kumbh py-4 bg-greyish px-3">
              <h6 className="text-center">
                {t("seatlayout_page.voucher-popup.enter-code")}
              </h6>
              {voucherCodeError && voucherCodeError === "error" && (
                <span className="form-error-text d-block text-align-center text-center">
                  {t("seatlayout_page.errors.invalid-voucher")}
                </span>
              )}
              <div className="d-flex flex-column flex-sm-row justify-content-sm-center">
                <input
                  type="text"
                  onChange={(e) => setVoucherCode(e.target.value)}
                  className="mx-auto mx-sm-4 code-input voucher-input px-3 py-2 mr-4 mb-3 mb-sm-0"
                />
                <a
                  href="#"
                  className="mx-auto mx-sm-0 blue-btn px-4 frs-16"
                  onClick={voucherHandler}
                >
                  {voucherCodeLoading ? (
                    <Spinner animation="border" variant="light" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    t("common.APPLY")
                  )}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
};
export default withNamespaces()(SeatLayout);
