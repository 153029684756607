import React, { Component } from "react";
// import "./page2.css";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedFnbItems,
  startTimer2,
  resetTimer,
  clearTimer,
} from "../store/booking/bookingActions";
import GoBack from "@components/partials/GoBack";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";

function Page2({ t }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const onClickGoBack = () => {
    history.push("/");
  };
  const onCollectTicket = () => {
    history.push(`/kiosk-collect-login`);
  };
  const onBookNewTicket = () => {
    // history.push("/movies");
    // history.push("/booking-type");
    switch (mode) {
      case 3:
      case 1:
        history.push(`/movies`);
        break;
      case 2:
        dispatch(resetTimer());
        clearTimer();
        dispatch(startTimer2());
        dispatch(clearSelectedFnbItems());
        history.push(`/fnb-checkout/${cinema_id}`);
        break;
      default:
        history.push(`/movies`);
    }
  };

  let globalLanguages;
  const global = useSelector((state) => state.global);
  const {
    selected_language,
    globalOptions,
    mode,
    cinema: { cinema_id },
  } = global;
  const globalOptionsLanguages = globalOptions.find((go) => go.go_id === 30);
  console.log("globalOptionsLanguages", globalOptionsLanguages);
  if (globalOptionsLanguages) {
    globalLanguages = JSON.parse(globalOptionsLanguages.go_value);
  } else {
    history.push(`/`);
  }

  return (
    <Container fluid>
      {globalLanguages?.length > 1 && (
        <div className="gb">
          <GoBack className="" />
        </div>
      )}
      <div className="parent">
        <div className="page2_container">
          {/* <Link to="/" className="page2_movie_header">
            <h2 className="small_text">{t("common.WELCOME TO")}</h2>
            <div
              className="logo2"
            // style={{ backgroundImage: `url(${globalSettings.org_logo_url})` }}
            ></div>
            <h2 className="bigg_text">{t("common.OASIS MALL")}</h2>
          </Link> */}
          <div className="page2_body">
            <div className="choose_action text-uppercase">
              {t("Choose your Action")}
            </div>
            <div className="huge_buttons">
              <button
                onClick={onCollectTicket}
                className="left_button hb text-uppercase"
              >
                {t("Collect your Ticket")}
              </button>
              <button onClick={onBookNewTicket} className="right_button hb">
                {t("collect_or_book.BOOK A NEW SHOW")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default withNamespaces()(Page2);
