import { createStore, applyMiddleware } from "redux";
//import thunkMiddleware to use async function
import thunkMiddleware from "redux-thunk";
//import rootReducer from store
import rootReducer from "./rootReducer";
//import logger to log actions and reducer in console
import logger from "redux-logger";
// import redux-dev-tools extension
import { composeWithDevTools } from "redux-devtools-extension";

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
  // logger^
);
export default store;
export const persistor = persistStore(store)
