import MovieService from "@apiService/MovieService";
import {
  FETCH_SEAT_LAYOUT,
  FETCH_SEAT_LAYOUT_SUCCESS,
  FETCH_SEAT_LAYOUT_ERROR,
  FETCH_SEAT_TYPE,
  FETCH_SEAT_TYPE_SUCCESS,
  FETCH_SEAT_TYPE_ERROR,
  SET_SEAT_SELECTED,
  CLEAR_SEAT_SELECTION,
  UPDATE_SEAT_LAYOUT,
  RESERVE_SEAT,
  RESERVE_SEAT_SUCCESS,
  RESERVE_SEAT_ERROR,
  SET_RESERVATION_ID,
  RELEASE_SEAT,
  RELEASE_SEAT_SUCCESS,
  RELEASE_SEAT_ERROR,
  SET_TEMP_RESERVATION_ID,
  SET_INTERNET_HANDLING_FEES_APPLIED,
  FETCH_SEAT_LAYOUT_SUCCESS_ON_DEMAND
} from "./seatLayoutTypes";

import {SET_PRIVATE_SCREENING_TYPE_PAYLOAD, SET_TIMEOUT_ERROR} from "../movies/moviesTypes";

import {fetchReservationDetails, fetchScheduleDetails} from "../movies/moviesActions";

import { toast } from "react-toastify";
import { groupBy } from "lodash";
import {SET_CHECKOUT_TIMER} from "../global/globalTypes";
import {START_TIMER} from "../booking/bookingTypes";

var timerInterval = null
const clearTimer = () => {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
};

export const fetchSeatLayoutBrij = ({ screen_id, ss_id, md_id }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: FETCH_SEAT_LAYOUT });
    const { data: seatLayoutData } = await MovieService.GetSeatLayoutBrij({
      screen_id,
      ss_id,
      md_id,
    });
    if (!seatLayoutData.status) {
      dispatch({
        type: FETCH_SEAT_LAYOUT_ERROR,
        payload: seatLayoutData.message,
      });
      dispatch({
        type: SET_TIMEOUT_ERROR,
        payload: { message: seatLayoutData.message, show: true },
      });
    }
    dispatch({
      type: SET_INTERNET_HANDLING_FEES_APPLIED,
      payload: seatLayoutData?.is_internet_handling_fee_applied === 'Y',
    })
    dispatch({ type: FETCH_SEAT_TYPE });
    const { data: seatTypeData } = await MovieService.GetSeatType({
      screen_id,
    });
    if (!seatTypeData.status) {
      dispatch({
        type: FETCH_SEAT_TYPE_ERROR,
        payload: seatLayoutData.message,
      });
    }
    let seatLayoutPayload = {};
    seatLayoutPayload.seats = seatLayoutData;
    seatLayoutPayload.seatType = seatTypeData;
    dispatch({
      type: FETCH_SEAT_LAYOUT_SUCCESS,
      payload: seatLayoutPayload,
    });
    dispatch({
      type: FETCH_SEAT_TYPE_SUCCESS,
      payload: seatTypeData,
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: FETCH_SEAT_LAYOUT_ERROR, payload: error });
  }
};

export const fetchSeatLayout = ({
  screen_id,
  ss_id,
  md_id,
  category_id,
  gogo_ss_id,
  mycinema_ss_d_id,
}) => async (dispatch, getState) => {
  try {
    // const mycinema_ss_d_id = getState().movies.myCinemaCreateShow
    //   .mycinema_ss_d_id;
    //dispatch({ type: FETCH_SEAT_LAYOUT });
    // const payload = {
    //   screen_id,
    //   ss_id,
    //   md_id,

    //   gogo_ss_id,
    // };
    // if (category_id == 1 || category_id == 3) {
    //   payload.isCinemaSeat = true;
    // }
    const { data: seatLayoutData } = await MovieService.GetSeatLayout({
      mycinema_ss_d_id,
    });
    if (!seatLayoutData.status) {
      dispatch({
        type: FETCH_SEAT_LAYOUT_ERROR,
        payload: seatLayoutData.message,
      });
      dispatch({
        type: SET_TIMEOUT_ERROR,
        payload: { message: seatLayoutData.message, show: true },
      });
    }
    // dispatch({ type: FETCH_SEAT_TYPE });
    // const { data: seatTypeData } = await MovieService.GetSeatType({
    //   screen_id,
    // });
    // if (!seatTypeData.status) {
    //   dispatch({
    //     type: FETCH_SEAT_TYPE_ERROR,
    //     payload: seatLayoutData.message,
    //   });
    // }
    const seatTypeData = seatLayoutData.getSeatTypeArray;
    let seatLayoutPayload = {};
    seatLayoutPayload.seats = seatLayoutData;
    seatLayoutPayload.seatType = seatTypeData;
    //
    // const data = {};
    // const seats = seatLayoutData
    //
    // data.seats = seats;
    // data.seatType = seatTypeData;
    // if (seats && seats.status) {
    //   let seatData = [];
    //   let seatData1 = groupBy(data.seats.Records, "sst_seat_type");
    //   data.seatType.Records = data.seatType.Records.sort(function (a, b) {
    //     return a.sst_order - b.sst_order;
    //   });
    //   data.seatType.Records.map((type) => {
    //     for (var key in seatData1) {
    //       if (seatData1.hasOwnProperty(key)) {
    //         if (key == type.sst_seat_type) {
    //           seatData[key] = seatData1[key];
    //         }
    //       }
    //     }
    //   });
    //   let tempArray = [];
    //   for (let key in seatData) {
    //     if (seatData.hasOwnProperty(key)) {
    //       let seat_price = 0;
    //       let seat_price_obj = seatData[key].filter((x) => {
    //         return x;
    //       });
    //       if (seat_price_obj) {
    //         seat_price = seat_price_obj[0].seat_price;
    //       }
    //       tempArray.push({
    //         seatType: key,
    //         seat_price: seat_price,
    //         seats: seatData[key],
    //       });
    //     }
    //   }
    //   let finalArray = [];
    //   for (let i = 0; i < tempArray.length; i++) {
    //     let singleSeatType = tempArray[i];
    //
    //     let seatData_1 = groupBy(singleSeatType.seats, "sl_row_num");
    //     let tempArray_1 = [];
    //     for (let key in seatData_1) {
    //       if (seatData_1.hasOwnProperty(key)) {
    //         let seatData_2 = seatData_1[key].map((singleSeat) => {
    //           singleSeat.selectStatus = false;
    //           singleSeat.SelectStatusFlag = false;
    //           return singleSeat;
    //         });
    //         seatData_2 = seatData_2.sort(function (a, b) {
    //           return a.sl_col_num - b.sl_col_num;
    //         });
    //
    //         tempArray_1.push(seatData_2);
    //       }
    //     }
    //     tempArray[i].seatsFinal = tempArray_1;
    //   }
      dispatch({
        type: FETCH_SEAT_LAYOUT_SUCCESS_ON_DEMAND,
        payload: seatLayoutData.seatLayoutNew//tempArray//seatLayoutPayload,
      });
    //}
    //
    // dispatch({
    //   type: FETCH_SEAT_LAYOUT_SUCCESS,
    //   payload: tempArray//seatLayoutPayload,
    // });
    dispatch({
      type: FETCH_SEAT_TYPE_SUCCESS,
      payload: seatTypeData,
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: FETCH_SEAT_LAYOUT_ERROR, payload: error });
  }
};

export const selectSeats = (payload) => async (dispatch) =>
  dispatch({
    type: SET_SEAT_SELECTED,
    payload,
  });

export const clearSeatSelection = (payload) => async (dispatch) =>
  dispatch({
    type: CLEAR_SEAT_SELECTION,
  });

export const updateSeatLayout = (payload) => async (dispatch, getState) => {
  const { globalOptions } = getState().movies;
  dispatch({
    type: UPDATE_SEAT_LAYOUT,
    payload,
    globalOptions,
  });
};

export const reserveSeats = ({
  selected_seats,
  schedule_show_id,
  history,
  cinema_id,
  seatGroup,
  gogo_ss_id,
  gogo_booking_type_id,
  category_id,
  callback,
  screen_id,
  ss_id,
  md_id,
  closeModal,
  specialRequest,
  genre_id,
  movie_id,
  content_lang_id,
  isPrivate,
  setReserveSeatError,
  setShowModal,
  setModalMessage,
}) => async (dispatch, getState) => {
  try {
    let state = getState();
    let { reservation_id_temp } = state.seatLayout;
    dispatch({ type: RESERVE_SEAT });
    let seats = selected_seats.map((x) => x.sl_id);
    let SeatTypeId = selected_seats.map((x) => x.sst_id);
    let isVipOrBlockSeats =
      selected_seats.filter(
        (x) => x.seat_reserve_type_id == 5 || x.seat_reserve_type_id == 12
      ).length > 0;
    let newSeatLayoutArray = [];
    let rateCardGroup = [];
    seatGroup.map((seat_g) => {
      seat_g.rate_card_group.map((rg) => {
        let findIndex32 = rateCardGroup.findIndex(
          (newrg) => newrg.tt_id == rg.tt_id
        );

        if (findIndex32 >= 0) {
          rateCardGroup[findIndex32]["count"] =
            rateCardGroup[findIndex32]["count"] + rg.totalSeatSelected;
        } else {
          let objRateCard = {
            tt_id: rg.tt_id,
            tt_name: rg.tt_name.trim(),
            count: rg.totalSeatSelected,
          };
          rateCardGroup.push(objRateCard);
        }

        for (let i = 0; i < rg.totalSeatSelected; i++) {
          let obj = {
            rate_card_id: rg.rate_card_id,
            tt_id: rg.tt_id,
            rc_g_id: rg.rc_g_id,
            rcg_c_id: rg.rcg_c_id,
            tt_name: rg.tt_name,
            seat_type_id: seat_g.sst_id,
            status: false,
          };

          newSeatLayoutArray.push(obj);
        }
      });
    });
    // const reservation_id = Math.floor(100000000 + Math.random() * 900000000);
    let reservation_id = reservation_id_temp;
    const { data } = await MovieService.ReserveSeats({
      groupRateCard: true,
      seat_layout_id: seats,
      seat_type_id: SeatTypeId,
      newSeatLayout: newSeatLayoutArray,
      schedule_show_id,
      reservation_id,
      isVipOrBlockSeats,
      cinema_id,
      gogo_ss_id,
      gogo_booking_type_id,
      specialRequest,
    });
    if (data && data.status) {
      dispatch({ type: RESERVE_SEAT_SUCCESS, payload: reservation_id });
      // dispatch(startTimerAction());
      // history.push(`/payment/${reservation_id}/${category_id}`);
      history.push(`/fnb/${ss_id}/${screen_id}/${md_id}`);
      // dispatch({ type: CLEAR_SEAT_SELECTION });
    } else {
      // toast.dismiss();
      // toast.error(data.message);
      setReserveSeatError(data.message);
      closeModal && closeModal();
      dispatch(clearSeatSelection());
      dispatch(
        fetchSeatLayoutBrij({
          screen_id,
          ss_id,
          md_id,
          category_id,
          gogo_ss_id,
        })
      );
      setShowModal(true);
      setModalMessage(data.message);
    }
  } catch (error) {
    if (error.response) {
      dispatch({ type: RESERVE_SEAT_ERROR, payload: error.response.data });
    } else {
      dispatch({ type: RESERVE_SEAT_ERROR, payload: error.message });
    }
  } finally {
    callback(false);
  }
};

export const cinemaReserveSeat = ({
  selected_seats,
  schedule_show_id,
  history,
  cinema_id,
  seatGroup,
  gogo_ss_id,
  gogo_booking_type_id,
  category_id,
  callback,
  screen_id,
  ss_id,
  md_id,
  closeModal,
  specialRequest,
  genre_id,
  movie_id,
  content_lang_id,
  isPrivate,
  mycinema_ss_d_id,
  booking_type_id,
  setReserveSeatError,
  setShowModal,
  setModalMessage,
}) => async (dispatch) => {
  try {
    dispatch({ type: RESERVE_SEAT });
    console.log("enter step 1");
    let seats = selected_seats.map((x) => x.sl_id);
    let SeatTypeId = selected_seats.map((x) => x.sst_id);
    let isVipOrBlockSeats =
      selected_seats.filter(
        (x) => x.seat_reserve_type_id == 5 || x.seat_reserve_type_id == 12
      ).length > 0;
    let newSeatLayoutArray = [];
    let rateCardGroup = [];
    console.log("enter step 2");

    seatGroup.map((seat_g) => {
      seat_g.rate_card_group.map((rg) => {
        let findIndex32 = rateCardGroup.findIndex(
          (newrg) => newrg.tt_id == rg.tt_id
        );

        if (findIndex32 >= 0) {
          rateCardGroup[findIndex32]["count"] =
            rateCardGroup[findIndex32]["count"] + rg.totalSeatSelected;
        } else {
          let objRateCard = {
            tt_id: rg.tt_id,
            tt_name: rg.tt_name.trim(),
            count: rg.totalSeatSelected,
          };
          rateCardGroup.push(objRateCard);
        }

        for (let i = 0; i < rg.totalSeatSelected; i++) {
          let obj = {
            rate_card_id: rg.rate_card_id,
            tt_id: rg.tt_id,
            rc_g_id: rg.rc_g_id,
            rcg_c_id: rg.rcg_c_id,
            tt_name: rg.tt_name,
            seat_type_id: seat_g.sst_id,
            status: false,
          };

          newSeatLayoutArray.push(obj);
        }
      });
    });
    console.log("enter step 3");

    const reservation_id = Math.floor(100000000 + Math.random() * 900000000);
    console.log("enter step 4");

    const { data } = await MovieService.CinemaReserveSeats({
      groupRateCard: true,
      seat_layout_id: seats,
      seat_type_id: SeatTypeId,
      newSeatLayout: newSeatLayoutArray,
      schedule_show_id,
      cinema_reservation_id: reservation_id,
      isVipOrBlockSeats,
      cinema_id,
      booking_type_id,
      mycinema_booking_type_id: booking_type_id,
      specialRequest,
      mycinema_ss_d_id,
    });
    console.log("enter step 5");

    if (data && data.status) {
      dispatch({ type: RESERVE_SEAT_SUCCESS, payload: reservation_id });

      // dispatch(startTimerAction());
      // history.push(`/payment/${reservation_id}/${category_id}`);
      history.push(`/fnb/${mycinema_ss_d_id}/${booking_type_id}`);
      console.log(
        "enter step 6",
        { reservation_id },
        { mycinema_ss_d_id },
        { booking_type_id }
      );

      // dispatch({ type: CLEAR_SEAT_SELECTION });
    } else {
      // toast.dismiss();
      // toast.error(data.message);
      setReserveSeatError(data.message);
      dispatch({
        type: SET_TIMEOUT_ERROR,
        payload: { message: data.message, show: true },
      });
      closeModal && closeModal();
      dispatch(clearSeatSelection());
      dispatch(
        fetchSeatLayout({
          screen_id,
          ss_id,
          md_id,
          category_id,
          gogo_ss_id,
        })
      );
      setShowModal(true);
      setModalMessage(data.message);
      console.log("enter step 7");
    }
  } catch (error) {
    if (error.response) {
      dispatch({ type: RESERVE_SEAT_ERROR, payload: error.response.data });
    } else {
      dispatch({ type: RESERVE_SEAT_ERROR, payload: error.message });
    }
    console.log("enter step 8", { error });
  } finally {
    callback(false);
  }
  console.log("enter step 9");
};

const startTimer = () => ({
  type: START_TIMER,
  payload: timerInterval,
});

export const privateScreeningReserveSeat = ({
  screen_id,
  history,
  cinema_id,
  specialRequest,
  category_id,
  mycinema_ss_d_id,
  movie_id,
  content_lang_id,
  genre_id,
  isPrivate,
  cinema = {}
}) => async (dispatch, getState) => {
  try {
    dispatch({ type: RESERVE_SEAT });
    console.log("category_id ajit:>> ", category_id);
    const cinema_reservation_id = Math.floor(
      100000000 + Math.random() * 900000000
    );

    let payload = {
      groupRateCard: true,
      seat_layout_id: [],
      schedule_show_id: 0,
      cinema_reservation_id,
      isVipOrBlockSeats: false,
      cinema_id,
      screen_id,
      mycinema_ss_d_id,
      mycinema_booking_type_id: 3,
      newSeatLayout: [],
      seat_type_id: [],
      specialRequest,
      MyCinema: true,
    }

    let privateScreeningTypePayload = getState()?.movies?.privateScreeningTypePayload ?? null
    if (privateScreeningTypePayload) {
      payload.booking_data =  {
        customSeatCount: privateScreeningTypePayload?.customSeatCount ?? null,
        total: privateScreeningTypePayload?.total ?? null,
        isTicketSlab: privateScreeningTypePayload?.isTicketSlab ?? null,
        isFnbCompulsory: privateScreeningTypePayload?.isFnbCompulsory ?? null,
        slab: privateScreeningTypePayload?.slab?.rc_id
            ? [{
              rc_id: privateScreeningTypePayload?.slab?.rc_id ?? null,
              rcg_c_id: privateScreeningTypePayload?.slab?.rcg_c_id ?? null,
              price: privateScreeningTypePayload?.slab?.price ?? null,
              quantity: privateScreeningTypePayload?.slab?.quantity ?? null,
            }]
            : [],
        fnb: privateScreeningTypePayload?.fnb?.fnb_private_id
            ? [{
              fnb_private_id: privateScreeningTypePayload?.fnb?.fnb_private_id ?? null,
              item_id: privateScreeningTypePayload?.fnb?.item_id ?? null,
              item_name: privateScreeningTypePayload?.fnb?.item_name ?? null,
              price: privateScreeningTypePayload?.fnb?.price ?? null,
              quantity: privateScreeningTypePayload?.fnb?.quantity ?? null,
            }]
            : [],
        addon: privateScreeningTypePayload?.addon?.fnb_private_id
            ? [{
              fnb_private_id: privateScreeningTypePayload?.addon?.fnb_private_id ?? null,
              item_id: privateScreeningTypePayload?.addon?.item_id ?? null,
              item_name: privateScreeningTypePayload?.addon?.item_name ?? null,
              price: privateScreeningTypePayload?.addon?.price ?? null,
              quantity: privateScreeningTypePayload?.addon?.quantity ?? null,
            }]
            : []
      }
    }

    const { data } = await MovieService.CinemaReserveSeats(payload);
    if (data && data.status) {
      dispatch(
        fetchScheduleDetails({
          mycinema_ss_d_id,
        })
      );
      dispatch({ type: RESERVE_SEAT_SUCCESS, payload: cinema_reservation_id });

      if (cinema?.cine_seat_release_time_in_mins) {
        console.log({t: cinema?.cine_seat_release_time_in_mins})
        dispatch({
          type: SET_CHECKOUT_TIMER,
          payload: cinema.cine_seat_release_time_in_mins,
        });
      }

      clearTimer();
      timerInterval = setInterval(() => {
        dispatch(startTimer());
      }, 1000);

      dispatch(fetchReservationDetails({reservation_id: cinema_reservation_id})).then(_ => {
        history.push(`/checkout-on-demand/${cinema_reservation_id}`)
      });
    } else {
      toast.dismiss();
      toast.error(data.message);
    }
  } catch (error) {
    if (error.response) {
      dispatch({ type: RESERVE_SEAT_ERROR, payload: error.response.data });
    } else {
      dispatch({ type: RESERVE_SEAT_ERROR, payload: error.message });
    }
  } finally {
  }
};

export const setReservationID = (payload) => async (dispatch) =>
  dispatch({ type: SET_RESERVATION_ID, payload });

export const setPrivateScreeningPayload = (payload) => async (dispatch) =>
    dispatch({ type: SET_PRIVATE_SCREENING_TYPE_PAYLOAD, payload });

export const releaseSeat = ({ reservation_id }) => async (dispatch) => {
  try {
    dispatch({ type: RELEASE_SEAT });
    const { data } = await MovieService.ReleaseSeat({ reservation_id });
    if (data && data.status) {
      dispatch({ type: RELEASE_SEAT_SUCCESS });
    }
  } catch (error) {
    dispatch({ type: RELEASE_SEAT_ERROR, payload: error });
  }
};

export const releaseSeatBrij = ({ reservation_id }) => async (dispatch) => {
  try {
    dispatch({ type: RELEASE_SEAT });
    const { data } = await MovieService.ReleaseSeatBrij({ reservation_id });
    if (data && data.status) {
      dispatch({ type: RELEASE_SEAT_SUCCESS });
    }
  } catch (error) {
    dispatch({ type: RELEASE_SEAT_ERROR, payload: error });
  }
};

export const releaseRealTimeBlockedSeats = () => async (dispatch) => {
  let reservation_id = window.localStorage.getItem("reservation_id_temp");
  console.log(
    "🚀 ~ file: movieActions.js ~ line 1742 ~ releaseRealTimeBlockedSeats ~ reservation_id",
    reservation_id
  );
  let guest_user_id = window.localStorage.getItem("guest_user_id");

  if (!reservation_id || !guest_user_id || isNaN(parseInt(reservation_id))) {
    return true;
  }

  try {
    const { data } = await MovieService.ReleaseRealtimeSeats({
      reservation_id,
      guest_user_id,
    });

    if (!data.status) {
      console.log("error in releasing realtime seats", data);
    } else {
      window.localStorage.removeItem("reservation_id_temp");
    }

    console.log("[reservation id] done with releasing seats");

    return data.status;
  } catch (err) {
    console.log("error in release real time seats", err);
  }
};

export const setTempReservationId = (payload) => {
  window.localStorage.setItem("reservation_id_temp", payload);

  return {
    type: SET_TEMP_RESERVATION_ID,
    payload,
  };
};

export const resetTempReservationId = () => {
  window.localStorage.removeItem("reservation_id_temp");

  return {
    type: SET_TEMP_RESERVATION_ID,
    payload: null,
  };
};
