import ElectronAppService from "@apiService/ElectronAppService";
import store from "../store/index";

async function getHardwareId() {
  try {
    let resp = await ElectronAppService.getHardwareId();
    const { data } = resp;

    if (data && data.status) {
      return data.data;
    }

    return null;
  } catch (err) {
    console.log("🚀 ~ file: init.js ~ line 24 ~ getHardwareId ~ err", err);
    return null;
  }
}

// async function getMacAddress() {
//   try {
//     let resp = await ElectronAppService.getMacAddress();
//     const { data } = resp;

//     if (data && data.status) {
//       return data.data;
//     }

//     return null;
//   } catch (err) {
//     console.log("🚀 ~ file: init.js ~ line 44 ~ getMacAddress ~ err", err);
//     return null;
//   }
// }

async function getResourceUsageInfo() {
  try {
    let resp = await ElectronAppService.getResourceUsage();
    const { data } = resp;

    if (data && data.status) {
      return data.data;
    }

    return null;
  } catch (err) {
    console.log(
      "🚀 ~ file: init.js ~ line 44 ~ getResourceUsageInfo ~ err",
      err
    );
    return null;
  }
}

export function initBackendAndElectronSocket(backendSocket, electronSocket) {
  electronSocket.on("connect", function () {
    console.log("connected to electron app. So send message to backend");
    backendSocket.emit("hardware-connected");
  });

  backendSocket.on("connect", function () {
    if (electronSocket && electronSocket.connected) {
      backendSocket.emit("hardware-connected");
    }
  });

  backendSocket.on("ping-hardware", async function () {
    let hardware_id = await getHardwareId();
    let sys_info = await getResourceUsageInfo();
    // const key = Object.keys(sys_info.macAddress)[0];
    // let macAddress = sys_info.macAddress[key].mac;
    // hardware_id = hardware_id + "-" + macAddress;
    backendSocket.emit("hardware-ping-back", {
      hardware_id,
      sys_info,
    });
  });

  backendSocket.on("get-hardware-id", async function () {
    let hardware_id = await getHardwareId();
    // let sys_info = await getResourceUsageInfo();
    // const key = Object.keys(sys_info.macAddress)[0];
    // let macAddress = sys_info.macAddress[key].mac;
    // hardware_id = hardware_id;
    let device = "KIOSK";
    backendSocket.emit("hardware-id", hardware_id, device);
  });

  backendSocket.on("get-system-info", async function () {
    let sys_info = await getResourceUsageInfo();
    backendSocket.emit("system-info", sys_info);
  });

  backendSocket.on("device-unique-code", async function (code) {
    console.log("device-unique-code", code);
    window.localStorage.setItem("deiviceUniqueCode", code);
    store.dispatch({
      type: "SET_DEVICE_CODE",
      payload: code,
    });
  });
}
