import React from "react";
import { Spinner as BSpinner } from "react-bootstrap";
import { withNamespaces } from "react-i18next";

function Spinner({ active = true, t }) {
  return active ? (
    <div className="c-spiner-container">
      <BSpinner animation="border" role="status">
        <span className="sr-only">{t("Loading")}</span>
      </BSpinner>
    </div>
  ) : null;
}

export default withNamespaces()(Spinner);
