import _ from "lodash"
import {
  FETCH_COMING_SOON_MOVIES,
  FETCH_COMING_SOON_ERROR,
  FETCH_NOW_PLAYING_MOVIES,
  FETCH_NOW_PLAYING_ERROR,
  FETCH_BANNERS,
  FETCH_BANNERS_ERROR,
  FETCH_MOVIE_DETAILS,
  FETCH_MOVIE_DETAILS_ERROR,
  FETCH_SHOW_TIMES,
  FETCH_SHOW_TIMES_ERROR,
  FETCH_CURRENT_MOVIE_SHOW_DATES,
  SET_MOVIE_DETAILS_LOADER,
  SET_SHOW_TIMES_LOADER,
  CLEAR_MOVIE_DETAILS_ERROR,
  SET_CURRENT_MOVIE_SHOW_DATES,
  SET_MOVIE_FORMATS,
  SET_MOVIES_LOADER,
  SET_SHOW_DATE_LOADER,
  FETCH_DATE_ALL_SHOWS,
  FETCH_DATE_ALL_SHOWS_LOADING,
  SET_SELECTED_SESSION,
  FETCH_ALL_MOVIES_SCHEDULES,
  FETCH_ALL_MOVIES_SCHEDULES_SUCCESS,
  FETCH_ALL_MOVIES_SCHEDULES_ERROR,
  SEND_SMS_ERROR,
  SEND_EMAIL_ERROR,
  SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS,
  FETCH_CINEMAS,
  FETCH_CINEMAS_SUCCESS,
  FETCH_CINEMAS_ERROR,
  SELECT_CINEMA_SUCCESS,
  SET_SELECTED_LANGUAGE,
  BOOK_SEAT_ERROR,
  BOOK_SEAT_SUCCESS,
  FETCH_MOVIE_SCHEDULES_SUCCESS3,
  FETCH_MOVIE_SCHEDULES_SUCCESS2,
  CLEAR_STATE,
  SET_TIMEOUT_ERROR,
  SET_SHOW,
  FETCH_RESERVATION_DETAILS,
  FETCH_RESERVATION_DETAILS_ERROR,
  FETCH_RESERVATION_DETAILS_SUCCESS,
  CREATE_SHOW,
  CREATE_SHOW_ERROR,
  CREATE_SHOW_SUCCESS,
  FETCH_NOW_SHOWING,
  FETCH_NOW_SHOWING_SUCCESS,
  FETCH_NOW_SHOWING_ERROR,
  SET_PRIVATE_SCREEN_AMOUNT,
  FETCH_MOVIE_DETAILS_SUCCESS2,
  FETCH_MOVIE_DETAILS_SUCCESS,
  FETCH_MOVIE_SCHEDULES,
  FETCH_MOVIE_SCHEDULES_SUCCESS,
  FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
  FETCH_MOVIE_SCHEDULES_ERROR,
  FETCH_SCHEDULE_DETAILS,
  FETCH_SCHEDULE_DETAILS_SUCCESS,
  FETCH_SCHEDULE_DETAILS_ERROR,
  ON_SET_COMPLETE_RATE_CARD,
  ON_SET_COMPLETE_NEW_TOTAL_AMOUNT,
  SET_STAGE,
  SET_SHOW_BOOKING_ID,
  FETCH_BOOKING_DETAILS,
  FETCH_BOOKING_DETAILS_SUCCESS,
  FETCH_BOOKING_DETAILS_ERROR,
  SET_STRIPE_PUBLISHER_KEY,
  FETCH_OTHER_SCREENINGS,
  FETCH_OTHER_SCREENINGS_SUCCESS,
  FETCH_OTHER_SCREENINGS_ERROR,
  FETCH_CURRENCY_SUCCESS,
  FETCH_CURRENCY_ERROR,
  FETCH_SCREENING_TYPES,
  FETCH_SCREENING_TYPES_SUCCESS,
  FETCH_SCREENING_TYPES_ERROR,
  START_TIMER,
  STOP_TIMER,
  RESET_TIMER,
  SET_TIMER,
  SET_FROM_MOVIE_PAGE,
  SET_IS_CREATE_YOUR_SHOW_ENABLED,
  SET_MOVIE_LANGUAGES,
  CLEAR_BOOKING_TYPE,
  SET_PRIVATE_SCREENING_TYPE_PAYLOAD,
} from "./moviesTypes";
import {
  FETCH_CURRENCY,
  FETCH_GLOBAL_OPTIONS,
  FETCH_GLOBAL_OPTIONS_ERROR,
  FETCH_GLOBAL_OPTIONS_SUCCESS,
  SET_BOOKING_TYPE
} from "../global/globalTypes";

let INITIAL_MINS = 4;
let INITIAL_SECONDS = 59;

const initialState = {
  now_playing_movies: [],
  now_playing_error: null,
  movie_details: [],
  movie_list: null,
  movie_list_loader: false,
  movie_details_error: null,
  show_times: [],
  show_times_error: null,
  current_movie_show_dates: null,
  show_times_loader: false,
  movie_details_loader: false,
  movieFormats: [],
  movies_loader: false,
  dates_slider_loader: false,
  selected_session: null,
  allMoviesSchedulesLoading: false,
  allMoviesSchedules: {
    movieSchedules: [],
    datesArray: [],
  },
  allMoviesSchedulesError: null,

  nowShowing: null,
  nowShowingLoading: false,
  nowShowingError: null,

  movieDetails: [],
  movieDetailsLoading: false,
  movieDetailsError: null,

  movieSchedules: null,
  movieScheduleDetails: null,
  movieSchedulesLoading: false,
  movieSchedulesError: null,
  movieLanguages: [],
  isCompleteRateCard: false,
  seatGroupAll: null,
  ft_id: null,
  totalAmount: null,
  netAmount: null,
  selectedTicketPrice: {
    priceInCents: 0,
    taxInCents: 0,
    totalWithFnb: 0,
    taxWithFnb: 0,
  },
  scheduleDetails: null,
  scheduleDetailsLoading: false,
  scheduleDetailsError: null,
  stage: 1,
  show: null,
  booking_type: null,
  bookingDetails: null,
  bookingDetailsLoading: false,
  bookingDetailsError: null,
  showBookingID: null,
  bookingSuccessful: false,
  stripePublisherKey: null,

  otherScreenings: [],
  otherScreeningsLoading: false,
  otherScreeningsError: null,
  currency: {},
  loading: false,
  error: null,
  globalSettings: {},
  globalOptions: [],
  screeningTypes: [],
  screeningTypesLoading: false,
  screeningTypesError: null,
  privateScreeningTypePayload: null,
  timer: {
    minutes: INITIAL_MINS,
    seconds: INITIAL_SECONDS,
  },
  timerCompleted: false,
  fromMoviePage: false,
  myCinemaCreateShow: {},
  timeoutError: false,
  reservationDetails: null,
  dateMovieScheduleDetails: null,
  filterMovieScheduleDetails: null,
  bookSeatsError: null,
  selectedCinema: JSON.parse(localStorage.getItem(`selected_cinema`)) || null,
  cinemas: [],
  getLanguageSpecificMovieDetails: null,
  getLanguageSpecificGenres: null,
  getLanguageSpecificLanguages: null,
  resendSMSError: null,
  resendEmailError: null,
  movieDetailsCopy: null,
  selected_language: JSON.parse(localStorage.getItem("selected_language")) || {
    lang_id: 1,
    lang_name: "English",
    alternate_lang_name: null,
    lang_is_active: "Y",
    created_by: null,
    created_at: null,
    updated_by: 1,
    updated_at: "2021-03-09T11:05:00.000Z",
    iso_2: "en",
    iso_3: "en",
  },
  createYourShowEnabled: false,
};

const moviesReducer = (state = initialState, action) => {
  let { type, payload } = action;

  switch (action.type) {
    case SET_MOVIE_FORMATS:
      return {
        ...state,
        movieFormats: action.payload,
      };
    case FETCH_NOW_PLAYING_MOVIES:
      return {
        ...state,
        now_playing_movies: action.payload,
        movies_loader: false,
        error: action.payload,
      };
    case FETCH_NOW_PLAYING_ERROR:
      return {
        ...state,
        now_playing_movies: [],
        movies_loader: false,
        now_playing_error: action.payload,
      };
    case FETCH_MOVIE_DETAILS:
      return {
        ...state,
        movie_details: action.payload,
        movie_details_loader: false,
      };
    case FETCH_MOVIE_DETAILS_ERROR:
      return {
        ...state,
        movie_details_error: action.payload,
        movie_details_loader: false,
      };
    case FETCH_DATE_ALL_SHOWS:
      return {
        ...state,
        movie_list: action.payload,
      };
    case FETCH_DATE_ALL_SHOWS_LOADING: {
      return {
        ...state,
        movie_list_loader: action.payload,
      };
    }
    case SET_SELECTED_SESSION: {
      return {
        ...state,
        selected_session: action.payload,
      };
    }
    case FETCH_SHOW_TIMES:
      return {
        ...state,
        show_times: action.payload,
        show_times_loader: false,
      };
    case FETCH_SHOW_TIMES_ERROR:
      return {
        ...state,
        show_times_error: action.payload,
        show_times_loader: false,
      };
    case FETCH_CURRENT_MOVIE_SHOW_DATES:
      return {
        ...state,
        current_movie_show_dates: [],
      };
    case SET_SHOW_DATE_LOADER:
      return {
        ...state,
        dates_slider_loader: true,
      };
    case SET_CURRENT_MOVIE_SHOW_DATES:
      return {
        ...state,
        current_movie_show_dates: action.payload,
        dates_slider_loader: false,
      };
    case SET_SHOW_TIMES_LOADER:
      return {
        ...state,
        show_times_loader: action.payload,
        show_times: [],
      };
    case SET_MOVIES_LOADER:
      return {
        ...state,
        movies_loader: action.payload,
      };
    case SET_MOVIE_DETAILS_LOADER:
      return {
        ...state,
        movie_details_loader: action.payload,
      };
    case CLEAR_MOVIE_DETAILS_ERROR:
      return {
        ...state,
        movie_details_error: null,
      };
    case FETCH_ALL_MOVIES_SCHEDULES: {
      return {
        ...state,
        allMoviesSchedulesLoading: true,
        allMoviesSchedulesError: null,
      };
    }
    case FETCH_ALL_MOVIES_SCHEDULES_SUCCESS: {
      return {
        ...state,
        allMoviesSchedulesLoading: false,
        allMoviesSchedulesError: null,
        allMoviesSchedules: action.payload,
      };
    }
    case FETCH_ALL_MOVIES_SCHEDULES_ERROR: {
      return {
        ...state,
        allMoviesSchedulesLoading: false,
        allMoviesSchedulesError: action.payload,
        allMoviesSchedules: {
          movieSchedules: [],
          datesArray: [],
        },
      };
    }


    case SEND_SMS_ERROR:
      return { ...state, resendSMSError: payload };
    case SEND_EMAIL_ERROR:
      return { ...state, resendEmailError: payload };

    case SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS:
      console.log(state, `manav 1234`);
      const {
        selected_language: { lang_name },
        movieDetails,
      } = state;
      let getLanguageSpecificMovieDetails;

      switch (lang_name) {
        case "English":
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === "English"
          );

          break;
        case "German":
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === "German"
          );
          break;
        case "Italian":
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === "Italian"
          );
          break;
        case "French":
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === "French"
          );
          break;
        case "Hindi":
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === "Hindi"
          );
          break;
        default:
          getLanguageSpecificMovieDetails = movieDetails;
      }
      if (getLanguageSpecificMovieDetails === undefined) {
        getLanguageSpecificMovieDetails = movieDetails;
      }
      return { ...state, getLanguageSpecificMovieDetails };

    case FETCH_CINEMAS:
      return {
        ...state,
        cinemas: [],
      };
    case FETCH_CINEMAS_SUCCESS:
      return {
        ...state,
        cinemas: payload,
      };
    case FETCH_CINEMAS_ERROR:
      return {
        cinemas: [],
      };
    case SELECT_CINEMA_SUCCESS:
      localStorage.setItem(`selected_cinema`, JSON.stringify(payload));
      return { ...state, selectedCinema: payload };
    case SET_SELECTED_LANGUAGE:
      localStorage.setItem(`selected_language`, JSON.stringify(payload));
      return {
        ...state,
        selected_language: payload,
      };
    case BOOK_SEAT_ERROR:
      return { ...state, bookSeatsError: payload };
    case BOOK_SEAT_SUCCESS:
      return {
        ...state,
        bookingSuccessful: true,
      };
    case FETCH_MOVIE_SCHEDULES_SUCCESS3:
      return { ...state, filterMovieScheduleDetails: payload };
    case FETCH_MOVIE_SCHEDULES_SUCCESS2:
      return {
        ...state,
        dateMovieScheduleDetails: payload,
      };
    case CLEAR_STATE:
      let currency = state.currency;
      return {
        ...initialState,
        currency,
      };
    case SET_TIMEOUT_ERROR:
      return { ...state, timeoutError: payload };
    case SET_SHOW:
      return { ...state, show: payload };
    case FETCH_RESERVATION_DETAILS:
    case FETCH_RESERVATION_DETAILS_ERROR:
      return {
        ...state,
        reservationDetails: null,
      };
    case FETCH_RESERVATION_DETAILS_SUCCESS:
      return {
        ...state,
        reservationDetails: payload,
      };
    case CREATE_SHOW:
    case CREATE_SHOW_ERROR:
      return {
        ...state,
        myCinemaCreateShow: {},
      };
    case CREATE_SHOW_SUCCESS:
      return {
        ...state,
        myCinemaCreateShow: payload,
      };
    case FETCH_NOW_SHOWING:
      return {
        ...state,
        nowShowing: null,
        nowShowingLoading: true,
      };
    case FETCH_NOW_SHOWING_SUCCESS:
      console.log("payload manav :>> ", payload);
      return {
        ...state,
        nowShowing:
          state.nowShowing === null
            ? payload
            : {
              ...state.nowShowing,
              data: [...state.nowShowing.data, ...payload.data],
            },
        nowShowingLoading: false,
      };
    case FETCH_NOW_SHOWING_ERROR:
      return {
        ...state,
        nowShowing: null,
        nowShowingError: payload,
      };
    case SET_PRIVATE_SCREEN_AMOUNT:
      return {
        ...state,
        totalAmount: payload.amount,
        netAmount: payload.net_amount,
      };
    case FETCH_MOVIE_DETAILS_SUCCESS2:
      return {
        ...state,
        movieDetailsCopy: payload,
      };
    case FETCH_MOVIE_DETAILS_SUCCESS:
      return {
        ...state,
        movieDetails: payload,
        movieDetailsLoading: false,
      };

    case FETCH_MOVIE_SCHEDULES:
      return {
        ...state,
        movieSchedules: null,
        movieSchedulesLoading: true,
      };
    case FETCH_MOVIE_SCHEDULES_SUCCESS:
      return {
        ...state,
        movieSchedules: payload,
        movieSchedulesLoading: false,
      };
    case FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS:
      return {
        ...state,
        movieScheduleDetails: payload,
      };
    case FETCH_MOVIE_SCHEDULES_ERROR:
      return { ...state, movieSchedules: null, movieSchedulesError: payload };
    case FETCH_SCHEDULE_DETAILS:
      return {
        ...state,
        scheduleDetails: null,
        scheduleDetailsLoading: true,
      };
    case FETCH_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        scheduleDetails: payload,
        scheduleDetailsLoading: false,
      };
    case FETCH_SCHEDULE_DETAILS_ERROR:
      return { ...state, scheduleDetails: null, scheduleDetailsError: payload };

    case ON_SET_COMPLETE_RATE_CARD:
      return {
        ...state,
        isCompleteRateCard: payload.status,
        seatGroupAll: payload.seatGroup,
      };
    case ON_SET_COMPLETE_NEW_TOTAL_AMOUNT:
      return {
        ...state,
        selectedTicketPrice: {
          priceInCents: payload.totalAmount,
          taxInCents:
            payload.netAmount !== 0
              ? payload.totalAmount - payload.netAmount
              : state.selectedTicketPrice.taxInCents,
          totalWithFnb: state.selectedTicketPrice.totalWithFnb,
          taxWithFnb: state.selectedTicketPrice.taxWithFnb,
        },
        netAmount: payload.netAmount,
        totalAmount: payload.totalAmount,
      };
    case SET_STAGE:
      console.log("payload :>> ", payload);
      return {
        ...state,
        stage: payload.stage,
        // show: payload.show,
      };
    case SET_BOOKING_TYPE:
      return {
        ...state,
        booking_type: payload,
      };
    case CLEAR_BOOKING_TYPE:
      return {
        ...state,
        booking_type: null,
      };
    case SET_SHOW_BOOKING_ID:
      return {
        ...state,
        showBookingID: payload,
      };
    case FETCH_BOOKING_DETAILS:
      return {
        ...state,
        bookingDetails: null,
        bookingDetailsLoading: true,
      };
    case FETCH_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        bookingDetails: payload,
        bookingDetailsLoading: false,
      };
    case FETCH_BOOKING_DETAILS_ERROR:
      return { ...state, bookingDetails: null, bookingDetailsError: payload };
    case SET_STRIPE_PUBLISHER_KEY:
      return { ...state, stripePublisherKey: payload };

    case FETCH_OTHER_SCREENINGS:
      return {
        ...state,
        otherScreenings: [],
        otherScreeningsLoading: true,
      };
    case FETCH_OTHER_SCREENINGS_SUCCESS:
      return {
        ...state,
        otherScreenings: payload,
        otherScreeningsLoading: false,
      };
    case FETCH_OTHER_SCREENINGS_ERROR:
      return { ...state, otherScreenings: [], otherScreeningsError: payload };
    case FETCH_GLOBAL_OPTIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GLOBAL_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        globalOptions: payload,
        error: null,
      };
    case FETCH_GLOBAL_OPTIONS_ERROR:
      return {
        loading: false,
        globalOptions: [],
        error: payload,
      };
    case FETCH_CURRENCY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currency: payload,
        error: null,
      };
    case FETCH_CURRENCY_ERROR:
      return {
        loading: false,
        currency: {},
        error: payload,
      };
    case FETCH_SCREENING_TYPES:
      return {
        ...state,
        screeningTypes: [],
        screeningTypesLoading: true,
      };
    case FETCH_SCREENING_TYPES_SUCCESS:
      let groupByQuantity = (obj) => (
          Object.fromEntries(_.chain(obj)
              .groupBy('quantity')
              .toPairs()
              .map(([key, valueArray]) => {
                return [key, valueArray]
              })
          )
      )

      let [privateScreening] = payload.filter(x => x?.booking_type_id === 3)
      let rateCardPrivate = privateScreening?.rate_card_array?.[0] ?? []

      rateCardPrivate.fnbListing.forEach(fnb => {
        let fnbOptions = rateCardPrivate.fnbDataPricing
            .filter(f => fnb?.item_id === f?.item_id)
            .sort((a, b) => a.quantity - b.quantity)

        fnb.priceGroupByQuantity = groupByQuantity(fnbOptions)
      });
      rateCardPrivate.addOnsData.forEach(addon => {
        let addOnOptions = rateCardPrivate.fnbDataPricing
            .filter(f => addon?.item_id === f?.item_id)
            .sort((a, b) => a.quantity - b.quantity)

        addon.priceGroupByQuantity = groupByQuantity(addOnOptions)
      });

      console.log({rateCardPrivate})
      return {
        ...state,
        screeningTypes: payload,
        screeningTypesLoading: false,
      };
    case SET_PRIVATE_SCREENING_TYPE_PAYLOAD:
      return {
        ...state,
        privateScreeningTypePayload: payload,
      };
    case FETCH_SCREENING_TYPES_ERROR:
      return { ...state, screeningTypes: [], screeningTypesError: payload };
    case START_TIMER: {
      let oldTimer = state.timer;
      let newTimer = {};
      let newSeconds = oldTimer.seconds;
      let newMinutes = oldTimer.minutes;
      let timerCompleted = false;

      if (newSeconds === 0) {
        newSeconds = 59;
        if (newMinutes === 0) {
          // timer has ended
          timerCompleted = true;
          newSeconds = 0;
          newMinutes = 0;
          if (action.payload && action.payload.timerInterval) {
            clearInterval(action.payload.timerInterval);
            action.payload.timerInterval = null;
          }
        } else {
          newMinutes -= 1;
        }
      } else {
        newSeconds -= 1;
      }

      newTimer = {
        seconds: newSeconds,
        minutes: newMinutes,
      };

      return {
        ...state,
        timer: newTimer,
        timerCompleted,
      };
    }
    case STOP_TIMER:
      if (action.payload && action.payload.timerInterval) {
        clearInterval(action.payload.timerInterval);
      }
      return { ...state };
    // return{...state,
    // }
    case RESET_TIMER: {
      return {
        ...state,
        timer: {
          seconds: INITIAL_SECONDS,
          minutes: INITIAL_MINS,
        },
        timerCompleted: false,
      };
    }
    case SET_TIMER: {
      return { ...state, timer: payload };
    }

    case SET_FROM_MOVIE_PAGE: {
      return {
        ...state,
        fromMoviePage: true,
      };
    }
    case SET_IS_CREATE_YOUR_SHOW_ENABLED: {
      return {
        ...state,
        createYourShowEnabled: payload,
      };
    }
    case SET_MOVIE_LANGUAGES: {
      return {
        ...state,
        movieLanguages: payload,
      };
    }

    default:
      return state;
  }
};

export default moviesReducer;
