import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCategories,
  fetchFnbItems,
  fetchFnbModifiersGroup,
  fecthModifiers,
  onCloseModifierPopup,
  selectModifier,
  setFnbReservationDone,
  fetchRecipeItems,
  selectModifierUnderRecipes,
  onCloseRecipeModifierPopup,
  removeFnbItem,
} from "@store/foodAndBeverages/foodAndBeveragesActions";
import {
  onSelectFnb,
  bookFnbItems,
  clearTimer,
  clearStore,
  clearSelectedFnbItems,
} from "@store/booking/bookingActions";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
// import "../assets/css/fnb.css";
import {
  timeConvert,
  isImageURLNull,
  titleCase,
  translateSessionAttribute,
} from "@helper/formatting.js";
import screen from "../assets/imgs/svgs/screen.svg";
import ip1 from "../assets/imgs/ip1.jpg";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import { globalConfigContext } from "@context/GlobalConfigContext";
import i18n from "../plugins/i18n";
import { Trash } from "react-bootstrap-icons";
import snack from "@assets/imgs/svgs/snacks_default.svg";
import movieDef from "@assets/imgs/movie-default-portrait.jpg";
import downArrow from "@assets/imgs/svgs/arrow-down.svg";
import clock from "@assets/imgs/svgs/clock.svg";
import { modifiersForSelectedFnbItems } from "@store/foodAndBeverages/foodAndBeveragesSelectors";
import TimeoutContainer from "@components/partials/TimeoutContainer";
import MovieHeader from "@components/partials/MovieHeader";
import CSpinner from "@components/partials/Spinner";
import GoBack from "@components/partials/GoBack";
import {setReservationID} from "../store/booking/bookingActions";
const els3 = [1, 2, 3];
const els10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const OnlyFnbCheckout = (props) => {
  // const { arabic, currency } = useContext(globalConfigContext);
  const arabic = false;
  const currency = {};
  const { cinema_id } = props.match.params;
  const t = props.t;
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showProceedSpinner, setShowProceedSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [reserveFnbItemsError, setReserveFnbItemsError] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [modifierPopupValidation, setModifierPopupValidation] = useState({});
  const [recipesPopupValidation, setRecipesPopupValidation] = useState({});
  const [enableModifierConfirm, setEnableModifierConfirm] = useState(false);
  const allshowDetails = useSelector((state) => state.movies.show_times);
  const session_id = useSelector((state) => state.booking.session_id);
  // const showDetails = allshowDetails.find((show) => show.ss_id == session_id);
  // const showDetails = allshowDetails[0];
  const showDetails = useSelector((state) => state.movies.selected_session);
  const reservation_id = useSelector((state) => state.booking.reservation_id);
  const seats_string = useSelector((state) => state.booking.seats_string);
  const selectedTicketPrice = useSelector(
    (state) => state.booking.selectedTicketPrice
  );
  const fnbCategories = useSelector(
    (state) => state.foodAndBeverages.fnb_categories
  );
  const fnbItems = useSelector((state) => state.booking.fnb_items);
  const fnbItemsLoader = useSelector((state) => state.booking.fnb_loader);
  const redirect_to_checkout = useSelector(
    (state) => state.foodAndBeverages.redirect_to_checkout
  );
  const selectedFnbItems = useSelector(
    (state) => state.booking.selected_fnb_items
  );
  const fnbPrice = useSelector((state) => state.foodAndBeverages.fnb_price);
  const selectedFnbItemId = useSelector(
    (state) => state.foodAndBeverages.selected_fnb_item_id
  );
  const modifiersGroup = useSelector(
    (state) => state.foodAndBeverages.fnb_modifiers_group
  );
  const modifiers = useSelector(
    (state) => state.foodAndBeverages.fnb_modifiers
  );
  const showModifiersPopup = useSelector(
    (state) => state.foodAndBeverages.show_modifiers_popup
  );
  const modifierPrice = useSelector(
    (state) => state.foodAndBeverages.modifier_price
  );
  const currentFnbItem = useSelector(
    (state) => state.foodAndBeverages.current_fnb_item
  );
  const seats = useSelector((state) => state.booking.seats_selected);
  const fnbReservationDone = useSelector(
    (state) => state.foodAndBeverages.fnbReservationDone
  );
  const minutes = useSelector((state) => state.booking.timer.minutes);
  const seconds = useSelector((state) => state.booking.timer.seconds);
  const showTimeoutPopup = useSelector(
    (state) => state.booking.showTimeoutPopup
  );
  const recipeItems = useSelector(
    (state) => state.foodAndBeverages.recipe_items
  );
  const showRecipeItemsPopup = useSelector(
    (state) => state.foodAndBeverages.show_recipe_items_popup
  );
  const modifiersForConcessions = useSelector((state) =>
    modifiersForSelectedFnbItems(state)
  );
  const timer = useSelector((state) => state.booking.timer);
  const seatsSelected = useSelector((state) => state.booking.seats_selected);
  const bookingFee = useSelector((state) => state.booking.bookingFee);
  const global = useSelector((state) => state.global);
  const {
   loading,
    currency: { curr_code },
  } = global;

  useEffect(() => {
    if (!reservation_id)
      dispatch(setReservationID(Math.floor(100000000 + Math.random() * 900000000)));
  }, []);

  useEffect(() => {
    // dispatch(fetchAllCategories({ cinema_id }));
    if (fnbItems.length === 0) {
      dispatch(fetchFnbItems({ cinema_id, reservation_id }));
    }
    if (redirect_to_checkout) {
      onProceed();
    }
  }, [
    cinema_id,
    dispatch,
    reservation_id,
    fnbItems.length,
    redirect_to_checkout,
  ]);
  //   useEffect(() => dispatch(clearSelectedFnbItems()), [dispatch]);
  const onSelectFnbItem = (type, item) => {
    dispatch(onSelectFnb(type, item));
    // if (type == "add") {
    //   dispatch(fetchFnbModifiersGroup({ item_id: fnbId }));
    //   dispatch(fecthModifiers({ item_id: fnbId }));
    // }
  };

  const onProceed = async () => {
    // if (selectedFnbItems.length > 0) {
    //  await dispatch(bookFnbItems({reservation_id,selectedFnbItems}))
    // }
    props.history.push(`/checkout-one/${reservation_id}`);
  };
  const history = useHistory();

  if (loading) {
      console.log('Invalid state', { loading })
      history.push('/')
      return null
  }

  return (
    <div className="">
      <CSpinner active={showSpinner} />
      {/* <TimeoutContainer t={t} session_id={session_id} cinema_id={cinema_id} /> */}
      <MovieHeader />
      <div className="bg-light-black">
        <Container fluid className="">
          <Row className="align-items-center py-3 mb-40">
            <Col>
              <GoBack to="prev" />
            </Col>
            <Col className="d-flex justify-content-end">
              <span className="session-timer">
                <span>
                  {timer.minutes}:
                  {timer.seconds < 10
                    ? "0" + timer.seconds
                    : timer.seconds == 0
                    ? "00"
                    : timer.seconds}
                </span>
                <img src={clock} className={""} />
              </span>
            </Col>
          </Row>

          {/* <Row>
            <Col xs={12}>
              <div className="ticket-header pb-3">
                <Row className="">
                  <Col xs={12}>
                    <div className="d-flex">
                      <img src={showDetails?.md_thumbnail_url} className="" />
                      <div className="ml-1 d-flex flex-column align-items-center w-100">
                        <Row className="mx-0 w-100 pb-3 pt-1 align-items-center">
                          <Col xs={5}>
                            <span className="fw-bold frs-28 text-limit v3 lh-1">
                              {arabic
                                ? showDetails?.movie_title_ar
                                : showDetails?.movie_title}{" "}
                              ({showDetails?.lang_name})
                            </span>
                          </Col>
                          <Col xs={4}>
                            <div className="group">
                              <span
                                className={`${
                                  arabic
                                    ? "border-left pl-2"
                                    : "border-right pr-2"
                                }`}
                              >
                                {showDetails?.rating}
                              </span>
                              <span className={`${arabic ? "pr-2" : "pl-2"}`}>
                                {timeConvert(showDetails?.mrrdr_runtime)}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mx-0 w-100">
                          <Col xs={5}>
                            <span>
                              <p>{t("common.Date")}</p>
                              <h4>
                                {moment(showDetails?.ss_start_date).format(
                                  "Do MMMM, YYYY"
                                )}
                              </h4>
                            </span>
                          </Col>
                          <Col xs={4}>
                            <span>
                              <p>{t("seatlayout_page.Seats")}</p>
                              <h4>
                                {seats_string
                                  ? seats_string
                                  : t("seatlayout_page.seats-not-selected")}
                              </h4>
                            </span>
                          </Col>
                        </Row>
                        <Row className="mx-0 w-100">
                          <Col xs={5}>
                            <span>
                              <p>{t("fnb_page.Show Details")}</p>
                              <h4>
                               
                                {moment(
                                  showDetails?.ss_start_show_time,
                                  "HH:mm"
                                ).format("HH:mm")}
                              </h4>
                            </span>
                          </Col>
                    
                        </Row>
                       
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row> */}

          <Row className="sticky-bar-margin">
            <Col md={6} lg={8}>
              <Row className="fnb-section">
                {fnbItems.map((item, index) => (
                  <Col lg={6} className="mb-4">
                    <div key={index} className="fnbItem">
                      <img
                        src={item.item_image_url}
                        alt={item.item_name}
                        className=""
                      />
                      <div className="w-100 d-flex pt-2 pb-1 bb-1 align-items-center">
                        <Col xs={12} className="text-limit v4">
                          <p className="itemName">{item.item_name}</p>
                        </Col>
                        {/* <Col xs={4} className="text-right">
														<p className="itemCal">240 kcal</p>
													</Col> */}
                      </div>
                      <div className="w-100 d-flex pb-2 pt-2 align-items-center">
                        <Col xs={7} className="">
                          <p className="itemPrice">
                            {curr_code} {item.fp_total_amount}
                          </p>
                        </Col>
                        <Col xs={5}>
                          <div className="w-100 h-100 d-flex justify-content-between align-items-center">
                            {/* <svg
															onClick={() => onSelectFnbItem(item.Id, 'sub', item)}
															width="1em"
															height="1em"
															viewBox="0 0 16 16"
															className="bi bi-dash-circle-fill"
															fill="#6236ff"
															xmlns="http://www.w3.org/2000/svg"
															className="fnb-icons"
														>
															<path
																fill-rule="evenodd"
																d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"
															/>
														</svg> */}

                            {/* item.Quantity && item.Quantity > 0
                                  ? "hide-minus" */}
                            <span
                              onClick={() => onSelectFnbItem("sub", item)}
                              className={`minus-icon`}
                            >
                              -
                            </span>
                            <span className="kumbh fw-bold frxl-28 frm-24 frs-20 pt-1">
                              {item.Quantity !== 0 && item.Quantity
                                ? item.Quantity
                                : "0"}
                            </span>
                            <span
                              onClick={() => onSelectFnbItem("add", item)}
                              className="plus-icon"
                            >
                              +
                            </span>
                            {/* <svg
															onClick={() => onSelectFnbItem(item.Id, 'add', item)}
															width="1em"
															height="1em"
															viewBox="0 0 16 16"
															className="bi bi-plus-circle-fill"
															fill="#6236ff"
															xmlns="http://www.w3.org/2000/svg"
															class="fnb-icons"
														>
															<path
																fill-rule="evenodd"
																d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
															/>
														</svg> */}
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Col>
                ))}
                {fnbItemsLoader && (
                  <div className="w-100 text-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="spinner spinner2"
                    >
                      <span className="sr-only">{t("Loading")}.</span>
                    </Spinner>
                    <br />
                    <h6>{t("Loading")}</h6>
                  </div>
                )}
              </Row>
            </Col>

            <Col md={6} lg={4}>
              <Row className="">
                <Col className="">
                  <div className="brds-10 bgb p-4 fw-bold frs-20">
                    <p className="ls-2 frs-20 pb-3 bb-1 mb-3 text-center">
                      {t("fnb_page.food-order")}
                    </p>
                    {selectedFnbItems && selectedFnbItems.length > 0
                      ? selectedFnbItems.map((item, index) => (
                          <div>
                            <div className="d-flex justify-content-between">
                              <div
                                key={index}
                                className="d-flex justify-content-between align-items-center pb-3 mr-2 flex-grow-1"
                              >
                                <span className="pr-2 fw-reg">
                                  {item.item_name} (x{item.Quantity})
                                  {false && (
                                    <img
                                      src={downArrow}
                                      alt={index}
                                      className="cursor-pointer d-inline-block pl-3"
                                    />
                                  )}
                                </span>
                                <span className="frs-24">
                                  {curr_code}{" "}
                                  {(item.priceInCents * item.Quantity).toFixed(
                                    2
                                  )}
                                </span>
                              </div>
                              {/* <Trash className="cursor-pointer" size={24} /> */}
                            </div>
                            {false && (
                              <div className="cdropdown hide">
                                {[].map((index) => (
                                  <div
                                    key={index}
                                    className="d-flex justify-content-between align-items-center pb-3"
                                  >
                                    <span className="fw-reg">
                                      {item.item_name} (x{item.Quantity})
                                    </span>
                                    <span className="frs-24">
                                      {curr_code}{" "}
                                      {(
                                        item.priceInCents * item.Quantity
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))
                      : null}
                    <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                      {/* <span className="fw-reg">
                        {t("fnb_page.Taxes")}
                        <br /> */}
                      {/* <span className="blurred-white frs-14 fw-reg">
                          (15%)
                        </span> */}
                      {/* </span> */}
                      <span className="frs-24"></span>
                    </div>
                    <div className="d-flex justify-content-between">
                      {/* <span className="fw-reg">{t("fnb_page.Total")}</span> */}
                      <span className="frs-24">
                        {/* {(
                          (parseFloat(fnbPrice.priceInCents) +
                            modifierPrice.priceInCents) /
                          currency.divided_by
                        ).toFixed(2)}{" "} */}
                        {arabic
                          ? currency.currency_short_code_ar
                          : currency.currency_short_code}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <Row className="align-items-center bgb mt-5 py-3">
						<Col md={8} className="br-1">
							<div className="d-flex">
								<div className="kumbh d-flex flex-column px-4">
									<p className="fw-reg">{t('Total Payable')}</p>
									<p className="fw-bold">{selectedTicketPrice.priceInCents / 100} SR</p>
									<p className="fw-reg">
										{t('Total')}:{' '}
										{(parseFloat(selectedTicketPrice.priceInCents) +
											parseFloat(fnbPrice.priceInCents) +
											modifierPrice.priceInCents) /
											100}{' '}
										SR
									</p>
								</div>
								<div className="d-flex flex-column justify-content-center">
									<p className="pill bg-light-black-2 p-3 fw-bold">{t('Total Bill')}</p>
								</div>
							</div>
						</Col>
						<Col md={4}>
							<span onClick={handleProceed} className="blue-btn px-4 py-3">
								{t('PROCEED')}
							</span>
						</Col>
					</Row> */}

          <Row className="align-items-center bgb mt-5 sticky-movie-bar">
            <Col xs={12} className="seat-footer">
              <Row className="py-4">
                <Col md={8}>
                  <div className={`d-flex px-3 ${arabic ? "bl-1" : "br-1"}`}>
                    <div>
                      <h4 className="f-grey">{t("fnb_page.Total Payable")}</h4>
                      <h1 className="fw-bold">
                        {curr_code}{" "}
                        {(
                          parseFloat(selectedTicketPrice.priceInCents) +
                          parseFloat(fnbPrice.priceInCents) +
                          modifierPrice.priceInCents
                        ).toFixed(2)}{" "}
                        {arabic
                          ? currency.currency_short_code_ar
                          : currency.currency_short_code}
                      </h1>
                      {/* <h5>
                        {curr_code} {t("fnb_page.Taxes")}:{" "}
                        {(
                          parseFloat(selectedTicketPrice.taxInCents) +
                          parseFloat(fnbPrice.taxesInCents) +
                          parseFloat(modifierPrice.taxesInCents)
                        ).toFixed(2)}{" "}
                        {arabic
                          ? currency.currency_short_code_ar
                          : currency.currency_short_code}
                      </h5> */}
                    </div>
                    {/* <div className={`flex-center ${arabic ? "pr-5" : "pl-5"}`}>
                      <h6 className="custom-label">
                        {t("fnb_page.Total Bill")}
                      </h6>
                    </div> */}
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  {selectedFnbItems.length > 0 && (
                    <span className="blue-btn btn-lg px-5" onClick={onProceed}>
                      {showProceedSpinner ? (
                        <Spinner
                          animation="border"
                          role="status"
                          className="spinner"
                        >
                          <span className="sr-only">{t("Loading")}</span>
                        </Spinner>
                      ) : (
                        t("fnb_page.PROCEED")
                      )}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Modal
          // show={show}
          // onHide={handleClose}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="1"
        >
          <div className="bg-light-black-2 brds-8">
            <div className="my-4 kumbh d-flex flex-column align-items-center">
              <p className="mb-2">{t("fnb_page.Enter your contact details")}</p>
              <input
                type="email"
                placeholder={t("collect_login.placeholders.email")}
                className="p-3 rounded-lg mb-2"
              />
              <input
                type="number"
                placeholder={t("collect_login.placeholders.mobile")}
                className="p-3 rounded-lg"
              />
            </div>
          </div>
        </Modal>

        <Modal
          show={showTimeoutPopup}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-time-up"
          backdrop="static"
        >
          <Modal.Header closeButton={false}>
            <p className="kumbh fw-bold frxl-36 frl-32 frm-28 frs-24 ls-4">
              {t("timeout.time-up")}
            </p>
          </Modal.Header>
          <Modal.Body>
            <p className="kumbh fw-reg frxl-24 frl-20 frs-16">
              {t("timeout.session-expired")}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <span
              onClick={() => {
                history.push("/");
              }}
              className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14"
            >
              {t("timeout.go-back")}
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default withNamespaces()(OnlyFnbCheckout);
