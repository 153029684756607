import React, { Component, useEffect } from "react";
// import "./page2.css";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GoBack from "@components/partials/GoBack";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import { setBookingType } from "./../store/global/globalActions";
import {
  startTimer2,
  clearTimer,
  clearFnbState,
  clearSelectedFnbItems,
  resetTimer,
} from "./../store/booking/bookingActions";

// 1- only ticket
// 2- only fnb
// 3 - both

const Page3 = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onClickGoBack = () => {
    history.push("/");
  };
  const onCollectTicket = () => {
    dispatch(setBookingType(1));
    history.push(`/movies`);
  };

  const onClickTicketConcession = () => {
    dispatch(setBookingType(3));
    history.push(`/movies`);
  };

  const onClickConcession = () => {
    dispatch(setBookingType(2));
    dispatch(resetTimer());
    clearTimer();
    dispatch(startTimer2());
    dispatch(clearSelectedFnbItems());
    history.push(`/fnb-checkout/${cinema_id}`);
  };
  let globalLanguages;
  const global = useSelector((state) => state.global);
  const {
    selected_language,
    globalOptions,
    cinema: { cinema_id },
  } = global;
  const globalOptionsLanguages = globalOptions.find((go) => go.go_id === 30);
  console.log("globalOptionsLanguages", globalOptionsLanguages);
  if (globalOptionsLanguages) {
    globalLanguages = JSON.parse(globalOptionsLanguages.go_value);
  } else {
    history.push(`/`);
  }

  return (
    <Container fluid>
      <div className="parent">
        <div className="page2_container">
          {/* <Link to="/" className="page2_movie_header">
            <h2 className="small_text">{t("common.WELCOME TO")}</h2>
            <div
              className="logo2"
            // style={{ backgroundImage: `url(${globalSettings.org_logo_url})` }}
            ></div>
            <h2 className="bigg_text">{t("common.OASIS MALL")}</h2>
          </Link> */}
          <div className="page2_body">
            <div className="choose_action">{t("collect_or_book.BOOK")}</div>
            <div className="huge_buttons">
              <button onClick={onCollectTicket} className="left_button hb">
                {t("collect_or_book.TICKET")}
              </button>
              <button
                onClick={onClickTicketConcession}
                className="right_button hb"
              >
                {t("collect_or_book.TICKET&CONCESSION")}
              </button>
              <button onClick={onClickConcession} className="right_button hb">
                {t("collect_or_book.CONCESSION")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default withNamespaces()(Page3);
