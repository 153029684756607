import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDateAllShows,
  setSelectedSession,
  fetchMovieDetails,
} from "@store/movies/moviesActions";
import { useHistory } from "react-router-dom";
import { timeConvert, timeDifference } from "@helper/formatting.js";
import { withNamespaces } from "react-i18next";
import i18n from "../plugins/i18n";
import MovieHeader from "@components/partials/MovieHeader";
import GoBack from "@components/partials/GoBack";
import { globalConfigContext } from "@context/GlobalConfigContext";
import { Row, Container, Col, Spinner } from "react-bootstrap";
import CSpinner from "@components/partials/Spinner";
import gridView from "../assets/imgs/svgs/grid-view.svg";
import listView from "../assets/imgs/svgs/list-view.svg";
import reset from "../assets/imgs/svgs/reset.svg";
import seatOccupied from "../assets/imgs/svgs/seat-sl-occupied.svg";
import moment from "moment";
import { getLangSpecificAttribute } from "../helper/Languages";

import movieDef from "@assets/imgs/movie-default-portrait.jpg";
import { ListStars } from "react-bootstrap-icons";

function ListMovies({ t }) {
  return (
    <div className="movie_listing_container list-movie-card">
      {/* <MovieHeader t={t} /> */}
      <MovieContent t={t} />
    </div>
  );
}

const GridView = ({
  movieListsLoader,
  movieLists,
  t,
  lang_id,
  dummy_image,
}) => {
  const currentDate = moment().format("DD-MM-YYYY");
  const history = useHistory();
  const dispatch = useDispatch();

  const onSelectShow = (showObj) => {
    dispatch(
      fetchMovieDetails({
        movie_id: showObj.movie_id,
        content_lang_id: showObj.lang_id,
        md_id: showObj.movie_details_id,
      })
    );
    history.push(
      `/seat-layout/${showObj.movie_details_id}/${showObj.ss_id}/${showObj.screen_id}`
    );
    dispatch(setSelectedSession(showObj));
  };

  return !movieListsLoader ? (
    movieLists && movieLists.length > 0 ? (
      movieLists.map((dateShowsObj) => (
        <div className="list__view__movie">
          <div className="row">
            <div className="col-12">
              <article className="day__title">
                <h2 className="">
                {moment(dateShowsObj.date, "YYYY-MM-DD").format("dddd")}
              </h2>
              </article>
            </div>
          </div>
          {dateShowsObj.shows.map((movie, index) => {
            return (
              <div className="row">
                <div className="col-12">
                  <article className="content" onClick={() => onSelectShow(movie)}>
                     <section className="d-flex">
                    <div className="">
                      <img
                        src={
                          getLangSpecificAttribute(
                            movie.altMovieContent,
                            lang_id,
                            "artwork"
                          ) || dummy_image
                        }
                        alt=""
                      />
                    </div>

                      <section className="list_movie_content">
                        <div>
                          <h3 class="text-limit v3 list__view__movie__time">
                        {movie.ss_start_show_time}
                      </h3>
                      <h3
                        class="text-limit v3 list__view__movie__title"
                        
                      >
                        {getLangSpecificAttribute(
                          movie.altMovieContent,
                          lang_id,
                          "mc_title",
                          true,
                        ) || movie?.original_title_of_movie || movie?.movie_title}
                          </h3>
                         
                        </div>
                        
                    <div className="">
                       <div className="list__view__movie__rating">{movie.rating}</div>
                       
                      <div class="middle-content filter-tags">
                        <div>{movie.movie_format}</div>
                      </div>
                    </div>

                        <div className="">
                          <div />
                          <div class="bottom-content">
                            {movie.language_name &&  <p>
                              {movie?.version_name ?? movie.language_name}</p>}
                            {movie?.mrrdr_runtime  && <p>{`${movie?.mrrdr_runtime} ${t("common.mins")}`}</p>}
                        {/* <div>{timeDifference(movie.ss_start_show_time, movie.ss_end_show_time)}
                          </div> */}
                        {/* { movie?.mrrdr_interval_time > 0 && (
                          <div className="mr-2">
                            {movie.mrrdr_interval_time} mins{" "}
                          </div>
                        )} */}
                        {/* <div>Alterfreigabe 12/12</div> */}
                        
                      </div>
                          </div>
                      </section>
                  </section>
                 </article>
                </div>
              </div>
            );
          })}
        </div>
      ))
    ) : (
      <div className="d-flex justify-content-center align-items-center">
        No Shows Found
      </div>
    )
  ) : (
    <div className="d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status" className="sppinner">
        <span className="sr-only">{t("Loading")}</span>
      </Spinner>
    </div>
  );
};

const MovieContent = ({ t }) => {
  const dispatch = useDispatch();
  const movieLists = useSelector((state) => state.movies.movie_list);
  const [copyList, setCopyList] = useState([]);
  const [display, setDisplay] = useState(true);
  const movieListsLoader = useSelector(
    (state) => state.movies.movie_list_loader
  );
  const [formats, setFormats] = useState([]);
  const [ratings, setRatings] = useState([]);

  const global = useSelector((state) => state.global);
  const {
    cinema: { cinema_id },
    selected_language: { lang_id },
    dummy_image,
  } = global;

  const currentDate = moment().format("DD-MM-YYYY");
  const nextDate = moment().add(1, "days").format("DD-MM-YYYY");

  useEffect(() => {
    dispatch(
      fetchDateAllShows({
        selectedDate: currentDate,
        cinema_id,
      })
    );
  }, []);

  useEffect(() => {
    if (
      movieLists &&
      movieLists.length > 0 &&
      copyList &&
      copyList.length === 0
    ) {
      const uniqueFormats = [];
      const uniqueRatings = [];

      for (let date of movieLists) {
        for (let value of date.shows) {
          if (
            uniqueFormats.findIndex(
              (x) => x.movie_format_id === value.movie_format_id
            ) === -1
          ) {
            uniqueFormats.push({
              movie_format: value.movie_format,
              movie_format_id: value.movie_format_id,
            });
          }
          if (
            uniqueRatings.findIndex((x) => x.rating_id === value.rating_id) ===
            -1
          ) {
            uniqueRatings.push({
              rating: value.rating,
              rating_id: value.rating_id,
            });
          }
        }
      }

      setCopyList([...movieLists]);
      console.log(
        "🚀 ~ file: ListMovies.jsx ~ line 158 ~ useEffect ~ uniqueFormats",
        uniqueFormats
      );
      setFormats(uniqueFormats);

      setRatings(uniqueRatings);
    } else if (
      movieLists &&
      movieLists.length === 0 &&
      copyList &&
      copyList.length !== 0
    ) {
      setCopyList([]);
      setFormats([]);
      setRatings([]);
    }
  }, [movieLists, copyList]);

  const onSelectFilter = (title, id) => {
    console.log(title, id);
    if (title == "format") {
      dispatch(
        fetchDateAllShows({
          cinema_id,
          format_id: id,
        })
      );
    }

    if (title == "rating") {
      dispatch(
        fetchDateAllShows({
          cinema_id,
          rating_id: id,
        })
      );
    }

    if (title == "refresh") {
      dispatch(
        fetchDateAllShows({
          cinema_id,
        })
      );
    }

    console.log(title);
  };

  return (
    <div class="container-fluid list__view">
      {/* {JSON.stringify(currentDate)} */}
      {/* {JSON.stringify(t)} */}
      {/* <div class="row grid-icons px-3 my-4">
        <div class="pr-4" onClick={() => setDisplay(true)}>
          <img className="img-fluid" src={listView} alt="icon" />
        </div>
        <div onClick={() => setDisplay(false)}>
          <img className="img-fluid" src={gridView} alt="icon" />
        </div>
      </div> */}
      <GridView
        movieLists={movieLists}
        movieListsLoader={movieListsLoader}
        t={t}
        lang_id={lang_id}
        dummy_image={dummy_image}
      />

      <div
        style={{
          "margin-top": "100px",
        }}
      ></div>

      <div className="filter-bottom">
        <img
          className="cursor-pointer mr-3"
          width="40px"
          src={reset}
          alt="icon"
          onClick={() => onSelectFilter("refresh")}
        />
        <div className="__formats_and_ratings">
          <article className="mr-3">
            {formats.map((format, index) => {
              return (
                <div
                  className=" __format"
                  onClick={() =>
                    onSelectFilter("format", format.movie_format_id)
                  }
                >
                  {format.movie_format}
                </div>
              );
            })}
          </article>
          <article>
            {ratings.map((rating, index) => {
              return (
                <div
                  className="__rating"
                  onClick={() => onSelectFilter("rating", rating.rating_id)}
                >
                  {rating.rating}
                </div>
              );
            })}
          </article>
        </div>
        {/* <div className="filter-tags justify-content-center">
          {formats.map((format, index) => {
            return (
              <div
                className="cursor-pointer"
                onClick={() => onSelectFilter("format", format.movie_format_id)}
              >
                {format.movie_format}
              </div>
            );
          })}
          <span className="d-inline mr-5"></span>
          {ratings.map((rating, index) => {
            return (
              <div
                className="cursor-pointer"
                onClick={() => onSelectFilter("rating", rating.rating_id)}
              >
                {rating.rating}
              </div>
            );
          })}
        </div> */}
        <div></div>
      </div>
    </div>
  );
};

export default withNamespaces()(ListMovies);
