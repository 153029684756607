import React, {useEffect, useRef} from "react";
import moment from "moment";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {slImageOnhover} from "../../helper/formatting.js";
import {setFromMoviePage, createShow} from "../../store/movies/moviesActions";
import {
  getMovieVersionsText,
  generateMovieVersionsText,
} from "@helper/formatting";

export default function MovieShow(props) {
  // const { movie_id, content_lang_id, category_id } = useParams();
  const {
    movie_id,
    content_lang_id,
    genre_id,
    cinema,
  } = useParams();
  const {cinemaIndex, selectedCinema} = props;
  let isPrivate = props.isPrivate;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => slImageOnhover, []);
  const privateCallback = async (item) => {
    dispatch(createShow(item, history, true)).then(() =>
      props.privateCallback(3, item)
    );
    // dispatch(setStage({ stage: 1, show: item, direct: true }));
    // setTimeout(() => props.privateCallback(3, item), 1000);
  };

  const currency = useSelector((state) => state.movies.currency);
  const mycinema_ss_d_id = useSelector(
    (state) => state.movies.myCinemaCreateShow.mycinema_ss_d_id
  );

  const {curr_code, curr_id} = currency;

  const [loading, setLoading] = React.useState(false);

  const goToScreeningType = (item) => {
    dispatch(createShow(item, history));
    // .then(() => {
    //   setTimeout(
    //     () => history.push(`/screening-type/${mycinema_ss_d_id}`),
    //     2000
    //   );
    // });

    // console.log("item :>> ", item);
  };

  return (
    <section className="row movie-show" id="movieShow">
      <div className="col-xl-3">
        <p className="theatre-name">{props.theatre}</p>
        <div className="theatre-address pbm">
          <p className="">
            {props.theatreAddress}
          </p>
        </div>
      </div>
      <div className="col-xl-9">
        <div className="row language-row">
          {props.languages.map((language, index) => (
            <>
              <div className="col-md-2">
                <p className="theatre-language" title={language.version_name}>
                  {generateMovieVersionsText(
                    selectedCinema?.region_name,
                    language.movie_lang_iso_3,
                    language.sub_1_iso_3,
                    language.sub_2_iso_3
                  )}
                </p>
              </div>
              <div className="col-md-10 pl-0">
                {language.screen_list &&
                language.screen_list.length > 0 &&
                language.screen_list.map((screen, screenIndex) => (
                  <section className="show-section" key={screenIndex}>
                    {screen?.screen_name && (
                      <p className="mr-3">
                          <span className="hall-name">
                            {screen.screen_name}
                          </span>
                      </p>
                    )}
                    {screen?.schedule_list
                      ?.sort((a, b) => {
                        if (
                          parseInt(a.showTime.split(":")[0]) -
                          parseInt(b.showTime.split(":")[0]) ===
                          0
                        ) {
                          return (
                            parseInt(a.showTime.split(":")[1]) -
                            parseInt(b.showTime.split(":")[1])
                          );
                        } else {
                          return (
                            parseInt(a.showTime.split(":")[0]) -
                            parseInt(b.showTime.split(":")[0])
                          );
                        }
                      })
                      ?.map((item, index) => (
                      <div
                        disabled={true}
                        className={`show ${
                          item?.showType == 2 ? "yellow" : "green"
                        } ${item?.mf_name ? "lg" : ""}`}

                        onClick={
                          () => {
                            if (!loading) {
                              if (isPrivate == 1) {
                                privateCallback(item);
                              } else if (props.category_id == 2) {
                                dispatch(setFromMoviePage());
                                history.push(
                                  `/seat-layout/${item.ss_id}/${item.screen_id}/${item.md_id}`
                                );
                              } else {
                                goToScreeningType(item);
                              }
                            }
                            setLoading(true);
                          }
                        }
                        key={index}
                      >
                        <div className="">
                          <p className="show-time">{item?.showTime}</p>

                          {props.category_id == 2 && item?.booked_seats && (
                            <p className="seats-booked">
                              {item.statusType}
                            </p>
                          )}
                        </div>
                        {item?.mf_name &&
                        item?.mf_name.toLowerCase() !== "2d" && (
                          <div className="exp">
                            {item?.mf_name}

                            {/* {props.category_id == 1 && item?.priceArray && (
                          <div className="hover-text">
                            {item?.priceArray?.map((price) => (
                              <p>
                                {price?.screeningType} {curr_code}{" "}
                                {price?.pricing}
                              </p>
                            ))}
                          </div>
                        )} */}
                          </div>
                        )}
                      </div>
                    ))}
                    {/* <p className="view-more">View More</p> */}
                  </section>
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
    </section>
  );
}
