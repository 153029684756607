import axios from "axios";

let electronAppUrl = "http://localhost:3001";

function get_axios() {
  return axios.create({
    baseURL: electronAppUrl,
  });
}

export default {
  getHardwareId() {
    return get_axios().get("/hardware-id");
  },
  getResourceUsage() {
    return get_axios().get("/resource-usage");
  },
  getMacAddress() {
    return get_axios().get("/mac-address");
  },
};
