import React, {useEffect, useState, useRef, useContext} from "react";
import {Row, Container, Col, Dropdown, Modal, Alert} from "react-bootstrap";
import {useParams} from "react-router";
import {withNamespaces} from "react-i18next";
import close from "@assets/imgs/svgs/close.svg";
import moment from "moment";
import dummyPoster from "@assets/imgs/trolls.png";
import {useSelector} from "react-redux";
import {getLangSpecificAttribute} from "../../helper/Languages"
import {currencyFormatter} from "../../helper/currencyFormatter";

const SpecialInstructionsPopup = ({
                                    t,
                                    showPrivateScreenPopup,
                                    onConfirmPrivateScreeningPopup,
                                    closeFunc,
                                  }) => {
  const {category_id} = useParams();
  const [specialRequest, setSpecialRequest] = useState("");

  return (
      <Modal
          aria-labelledby="contained-modal-title-vcenter"
          className="seat-type-popup"
          centered
          onHide={() => closeFunc(false)}
          show={showPrivateScreenPopup}
      >
        <section className="wrapper container-fluid">
          <img
              src={close}
              className="close-btn-main"
              onClick={() => closeFunc(false)}
              alt=""
          />
          <section className="row">
            <div className="col-12 payment-booking-details">
              <PaymentBookingDetails category_id={category_id} t={t}/>
            </div>
          </section>
          <>
            <div className="row">
              <div className="px-3 question">
                <p>{t("Have a special request or instruction")}</p>
              </div>
            </div>

            <div className="row">
              <div className="px-3 w-100">
                <input
                    type="text"
                    className="answer"
                    placeholder={t("Type Here...")}
                    value={specialRequest}
                    onChange={(e) => setSpecialRequest(e.target.value)}
                />
              </div>
            </div>

            <div className="d-flex flex-column flex-md-row align-items-center flex-wrap">
              <button
                  className={`blue-btn mx-auto`}
                  onClick={() => onConfirmPrivateScreeningPopup(specialRequest)}
              >
                {t("Confirm")}
              </button>
              <p className="my-3 my-md-0">OR</p>
              <button
                  className={`blue-btn mx-auto`}
                  onClick={() => onConfirmPrivateScreeningPopup(specialRequest)}
              >
                {t("Skip")}
              </button>
            </div>
          </>
        </section>
      </Modal>
  );
};

export default withNamespaces()(SpecialInstructionsPopup);

//Start Payment Booking Details
const PaymentBookingDetails = ({ category_id, t }) => {
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const totalAmount = useSelector((state) => state.movies.totalAmount);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const booking_type = useSelector((state) => state.movies.booking_type);
  const appliedVoucher = useSelector((state) => state?.promotions?.applied_voucher);
  const discount = useSelector((state) => state?.promotions?.voucher_discount);
  const selected_seats = useSelector(
      (state) => state.seatLayout.seats_selected
  );
  const screeningTypes = useSelector((state) => state.movies.screeningTypes);
  const selected_language = useSelector(
      (state) => state.movies.selected_language
  );
  const { lang_id, lang_name, iso_2 } = selected_language;

  const screen = screeningTypes.find((screen) => screen.booking_type_id === 3);
  return (
      <>
        <div className="header">
          <div className="left text-center">
            <img
                src={
                  getLangSpecificAttribute(
                      scheduleDetails?.languageSpecificImages,
                      lang_id,
                      "artwork"
                  ) || dummyPoster
                }
                alt="Movie Img"
                className="movie-img"
            />
          </div>
          <div className="right">
            <div>
              <h6 className="title mb-1">
                {getLangSpecificAttribute(
                    scheduleDetails?.altMovieContent,
                    lang_id,
                    "mc_title"
                ) || scheduleDetails?.movie_title}
              </h6>

              <div className="features">
                <p className="mb-1">
                  {scheduleDetails?.lang_name} {scheduleDetails?.mf_name}
                </p>
                <p className="mb-1">
                  {scheduleDetails?.mrrdr_runtime} {t("common.mins")}{" "}
                </p>
                <p>
                  {moment(scheduleDetails?.mrrdr_release_date).format("YYYY")}{" "}
                </p>
              </div>
            </div>
            {/* <div className="movie-imbd">
            <p className="fs-13">{t("IMDB")}</p>
            <p className="value">7.5 / 10</p>
          </div> */}
            <div className="movie-rating">
              <p className="fs-13">
                {t("Rating")}&nbsp;:&nbsp;
                <span className="value small-font">
                {scheduleDetails?.rating}
              </span>
              </p>
            </div>
          </div>
        </div>
        {/* End Header */}

        <div className="middle">
          <div>
            <p>
              {t("Cinema")}:{" "}
              {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
            </p>
            <p>
              {scheduleDetails?.cine_name}, {scheduleDetails?.cine_address}
              {/* {scheduleDetails?.cine_location} */}
            </p>
          </div>
          <div>
            <p>
              {t("Date")} & {t("Time")}:{" "}
              {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
            </p>
            <p>
              {moment(scheduleDetails?.ss_actual_start_date_time).format("ll")} |{" "}
              {moment(scheduleDetails?.ss_start_show_time, "HH:mm:ss").format(
                  "HH:mm"
              )}
            </p>
          </div>
          {false && category_id != 2 && (
              <div>
                <p>{t("Screening Type")}:</p>
                <p>
                  {/* {booking_type == 1
                ? t("Confirmed Screening")
                : booking_type == 2
                ? t("Crowdsourced Screening")
                : booking_type == 3
                ? t("Private Screening")
                : null} */}
                  {scheduleDetails?.booking_type_name}
                </p>
              </div>
          )}
          {false && (
              <div>
                <p>
                  {t("Pick Seats")} ({scheduleDetails?.ticket_count}):
                </p>
                <p>
                  {/* {booking_type !== 3 && (
              // selected_seats?.map((seat, index) => (
              //   <>
              //     {seat.sl_seat_name}
              //     {selected_seats.length - 1 !== index && ","}
              //   </>
              // ))

            )}
            {booking_type === 3 && <p>{t("common.All")}</p>} */}
                  <p>{scheduleDetails?.seat_name}</p>
                </p>
              </div>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <p className="fs-16">
              {t("final_ticket_page.Tickets")} (
              {booking_type === 3 ? "All" : scheduleDetails?.ticket_count}
              ):{" "}
            </p>
            <p className="fs-22">
              {curr_code} {currencyFormatter(screen?.amount)}
            </p>
          </div>
          {discount && (
              <div className="d-flex justify-content-between align-items-center">
                <p className="fs-16">
                  {t("Promocode Discount")}:
                  <br />({appliedVoucher?.voucher_title})
                </p>
                <p className="fs-22">
                  - {curr_code} {currencyFormatter(discount)}
                </p>
              </div>
          )}
        </div>
        {/* End Middle */}

        <div className="d-flex justify-content-between footer">
          <div>
            <p className="fs-16">{t("Total Amount Payable")}: </p>
            <p className="fs-10 text-grey font-italic">
              ({t("Amount inclusive of all taxes")})
            </p>
          </div>
          <p className="fs-22 font-weight-bold">
            {curr_code}{" "}
            {currencyFormatter(screen?.amount - (discount ? discount : 0))}
          </p>
        </div>

        {/* End Footer */}
      </>
  );
};
