import React, { Component } from "react";
// import "./movie.css";
import MovieHeader from "./partials/MovieHeader";
export default class App extends Component {
  render() {
    return (
      <div className="main_container">
        <MovieHeader />
        <div className="goback_area">
          <GoBack />
        </div>
        <LoginForm />
      </div>
    );
  }
}
// const MovieHeader = () => {
//   return (
//     <div className="movie_header">
//       <div className="logo"></div>
//       <h2 className="big_text">OASIS MALL</h2>
//       <div className="dropdown_div">
//         <select className="dropdown" name="language" id="language">
//           <option value="english">ENGLISH</option>
//           <option value="arabic">ARABIC</option>
//         </select>
//       </div>
//     </div>
//   );
// };

const GoBack = () => {
  return (
    <div className="go_back">
      <div className="button_wrap">
        <div className="go_back_button"></div>
      </div>
      <h2 className="big_text">GO BACK</h2>
    </div>
  );
};

const LoginForm = () => {
  return (
    <div className="login_form">
      <h2>Enter your details to collect the ticket</h2>
      <div className="linebreak"></div>
      <input type="email" placeholder="E-mail" />
      <input type="text" placeholder="Mobile No." />
      <button className="book_ticket">PROCEED</button>
    </div>
  );
};
