import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
// import "../assets/css/movie-container.css";
import screen from "../assets/imgs/svgs/screen.svg";
import ip1 from "../assets/imgs/ip1.jpg";
const els3 = [1, 2, 3];
const els10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export default class App extends Component {
  render() {
    return (
      <div className="">
        <MovieHeader />
        <div className="bg-light-black">
          <Container fluid>
            <Row className="align-items-center py-3 mb-40">
              <Col>
                {/* <div className="">
									<p className="kumbh fw-bold frxl-40 frl-32 frm-24 frs-16 ">
										Back
							</p>
								</div> */}
                <div className="go_back">
                  <div className="button_wrap">
                    <div className="go_back_button"></div>
                  </div>
                  <h2 className="big_text">GO BACK</h2>
                </div>
              </Col>
            </Row>

            <Row className="pb-4 bb-1 mb-3">
              <Col>
                <div className="d-flex align-items-center">
                  <img src={ip1} height="352px" className="mr-4" />
                  <div className="d-flex flex-column kumbh">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="kumbh fw-bold">Bad Boys For Life</span>
                      <div className="group frl-12 frm-10 frs-8 fw-reg">
                        <span className="border-right pr-2">PG-13</span>
                        <span className="pl-2">2h 19min</span>
                      </div>
                    </div>
                    <p className="fw-reg mb-2">English, Arabic</p>
                    <p className="fw-reg mb-2">Synopsis</p>
                    <p className="fw-reg mb-2">
                      The circumstances surrounding the death of crime novelist
                      Harlan Thrombey are mysterious, but there's one thing that
                      renowned Detective Benoit Blanc knows for sure, everyone
                      in the wildly dysfunctional Thrombey family is a suspect.
                      Now, Blanc must sift through a web of lies and red
                      herrings to uncover the truth.
                    </p>
                    <p className="fw-reg mb-2">Cast</p>
                    <p className="fw-reg mb-2">Will Smith</p>
                    <p className="fw-reg mb-2">Directed By</p>
                    <p className="fw-reg mb-2">Michael Bay</p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="my-4">
              <Col>
                <p className="kumbh fw-bold frxl-24 frl-22 frm-18 frs-14 ls-4">
                  Date
                </p>
              </Col>
              <Col className="text-right">
                <div className="d-inline-block py-2 px-4 bg-light-black rounded-lg">
                  <p className="kumbh fw-bold frxl-20 frl-18 frm-14 frs-10 ls-2 cursor-pointer">
                    Choose Language
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p className="kumbh fw-bold frxl-24 frl-22 frm-18 frs-14 ls-4">
                  24 january 2021
                </p>
              </Col>
              <Col className="text-right">
                <span className="d-inline-block py-2 px-4 mr-4 lang-btn active">
                  ENGLISH
                </span>
                <span className="d-inline-block py-2 px-4 lang-btn">
                  ARABIC
                </span>
              </Col>
            </Row>

            <Row>
              <Col>
                <p className="kumbh fw-reg">Choose Show Time</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="">
                  {els3.map((x, index) => (
                    <div className="bb-1 px-3 pt-4">
                      <div className="">
                        <p className="kumbh fw-bold frxl-24 frl-22 frm-18 fs-14 ls-2">
                          PREMIUM
                        </p>
                      </div>
                      {els10.map((x, index) => (
                        <div className="d-inline-block m-3 px-4 py-2 rounded-pill showtime inactive">
                          <span className="kumbh fw-reg frl-12 frm-10 frs-8 ls-2">
                            04:30 PM
                          </span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const MovieHeader = () => {
  return (
    <div className="movie_header">
      <div className="logo"></div>
      <h2 className="big_text">OASIS MALL</h2>
      <div className="dropdown_div">
        <select className="dropdown" name="language" id="language">
          <option value="english">ENGLISH</option>
          <option value="arabic">ARABIC</option>
        </select>
      </div>
    </div>
  );
};
