import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import MovieHeader from "@components/partials/MovieHeader";

//importing the assets
// import co1 from "../assets/pictures/co1.png";
import screen from "../assets/imgs/svgs/screen.svg";
import selectSeat from "../assets/imgs/svgs/seat.svg";
import whiteSofa from "../assets/imgs/svgs/sofa.svg";
import wheelChair from "../assets/imgs/svgs/wheel-chair.svg";
import selectedSeat from "../assets/imgs/svgs/seat-sl-selected.svg";
import occupiedSeat from "../assets/imgs/svgs/seat-sl-occupied.svg";
import whiteSeat from "../assets/imgs/svgs/seat-sl.svg";
import ip1 from "../assets/imgs/ip1.jpg";

// import leftArrow from "../assets/pictures/svgs/left-arrow.svg";
// import arrowUp from "../assets/pictures/svgs/arrow-up.svg";
// import cross from "../assets/pictures/svgs/cross.svg";
// import movieDef from '../assets/pictures/svgs/movie_default.svg';
// import { setShowLoginSignup } from "../store/config/configActions";
// import { TokenService } from "../ApiServices/tokenService";
function SeatAndVoucher(props) {
  //  const MovieHeader = () => {
  //   return (
  //    <div className="movie_header">
  //     <div className="logo"></div>
  //     <h2 className="big_text">OASIS MALL</h2>
  //     <div className="dropdown_div">
  //      <select className="dropdown" name="language" id="language">
  //       <option value="english">ENGLISH</option>
  //       <option value="arabic">ARABIC</option>
  //      </select>
  //     </div>
  //    </div>
  //   );
  //  };

  return (
    <>
      <MovieHeader />
      <Container fluid className="kumbh seat-layout">
        <Row>
          <Col md={12}>
            <div className="ticket-header bb-1 pb-5">
              <Row className="">
                <Col md={9}>
                  <div className="d-flex pt-4">
                    <img src={ip1} className="h-180 rounded" />
                    <div className="pl-4 align-items-center">
                      <div className="d-flex">
                        <h4 className="fw-bold">Bad Boys For Life(2D, EN)</h4>
                        <div className="label-ticket">
                          <h6 className="right">PG-13</h6>
                          <h6 className="left">2h19m</h6>
                        </div>
                      </div>
                      <div className="flex-between flex-half">
                        <span>
                          <p>Date</p>
                          <h5>24th January, 2021</h5>
                        </span>
                        <span>
                          <p>Seats</p>
                          <h5>G4, G5</h5>
                        </span>
                        <span>
                          <p>Show Details</p>
                          <h5>MACRO XE, 4:30PM</h5>
                        </span>
                        <span>
                          <p>Voucher Code</p>
                          <h5>ABCDS (20% OFF)</h5>
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <button className="blue-btn">CHANGE VOUCHER</button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={12} className="mb-5">
            <div className="position-relative">
              <div className="stw">
                <img className="img-fluid" src={screen} alt="Screen" />
              </div>
              <div className="stw-text">SCREEN THIS WAY</div>
            </div>
          </Col>
          <Col md={12}>
            <div class="table-responsive col">
              <div class="table-responsive">
                <table class="first-table table table-borderless">
                  <tr>
                    <td>
                      <div className="seat-title">
                        <span>PREMIUM</span>
                      </div>
                      <tr>
                        <td className="second-table">
                          <table className="table table-borderless">
                            <tr>
                              <td className="cell">A</td>
                            </tr>
                            <tr>
                              <td className="cell">B</td>
                            </tr>
                            <tr>
                              <td className="cell">C</td>
                            </tr>
                          </table>
                        </td>
                        <td className="second-table">
                          <table className="table table-borderless">
                            {Array(3)
                              .fill(1)
                              .map((el, i) => (
                                <tr className="seats">
                                  {Array(12)
                                    .fill(1)
                                    .map((el, i) => (
                                      <td>
                                        <div className="cell"></div>
                                      </td>
                                    ))}
                                </tr>
                              ))}
                          </table>
                        </td>
                      </tr>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="seat-title">
                        <span>RECLINER</span>
                      </div>
                      <tr>
                        <td className="second-table">
                          <table className="table table-borderless">
                            <tr>
                              <td className="cell">D</td>
                            </tr>
                            <tr>
                              <td className="cell">E</td>
                            </tr>
                          </table>
                        </td>
                        <td className="second-table">
                          <table className="table table-borderless">
                            {Array(2)
                              .fill(1)
                              .map((el, i) => (
                                <tr className="seats">
                                  {Array(5)
                                    .fill(1)
                                    .map((el, i) => (
                                      <>
                                        <td>
                                          <div className="cell empty"></div>
                                        </td>
                                        <td>
                                          <div className="sofa"></div>
                                        </td>
                                      </>
                                    ))}
                                </tr>
                              ))}
                          </table>
                        </td>
                      </tr>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="seat-title">
                        <span>DELUXE</span>
                      </div>
                      <tr>
                        <td className="second-table">
                          <table className="table table-borderless">
                            <tr>
                              <td className="cell">F</td>
                            </tr>
                            <tr>
                              <td className="cell">G</td>
                            </tr>
                            <tr>
                              <td className="cell">H</td>
                            </tr>
                          </table>
                        </td>
                        <td className="second-table">
                          <table className="table table-borderless">
                            {Array(3)
                              .fill(1)
                              .map((el, i) => (
                                <tr className="seats">
                                  {Array(12)
                                    .fill(1)
                                    .map((el, i) => (
                                      <td>
                                        <div className="cell"></div>
                                      </td>
                                    ))}
                                </tr>
                              ))}
                          </table>
                        </td>
                      </tr>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <tr>
                        <td className="second-table">
                          <table className="table table-borderless">
                            <tr>
                              <td className="cell"></td>
                            </tr>
                          </table>
                        </td>
                        <td className="second-table">
                          <table className="table table-borderless">
                            {Array(1)
                              .fill(1)
                              .map((el, i) => (
                                <tr className="seats">
                                  {Array(12)
                                    .fill(1)
                                    .map((el, i) => (
                                      <td>
                                        <div className="cell cell-none">
                                          {i + 1}
                                        </div>
                                      </td>
                                    ))}
                                </tr>
                              ))}
                          </table>
                        </td>
                      </tr>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </Col>{" "}
          {/* End Seat Design */}
          <Col md={8} className="offset-3 seats-desc py-5">
            <div className="flex-around flex-wrap">
              <span>
                <img src={occupiedSeat} alt="img" />
                <span className="pl-2">Occupied</span>
              </span>
              <span>
                <img src={whiteSeat} alt="img" />
                <span className="pl-2">Open</span>
              </span>
              <span>
                <img src={selectedSeat} alt="img" />
                <span className="pl-2">Selected</span>
              </span>
              <span>
                <img src={whiteSofa} alt="img" />
                <span className="pl-2">Lounger</span>
              </span>
              <span>
                <img src={wheelChair} alt="img" />
                <span className="pl-2">Wheelchair</span>
              </span>
            </div>
          </Col>
          <Col md={12} className="seat-footer">
            <Row>
              <Col md={8}>
                <div className="d-flex br-1 py-4 px-3">
                  <div>
                    <h6 className="f-grey">Total Payable</h6>
                    <h4 className="fw-bold">50 SR</h4>
                    <p>Taxes: 5 SR</p>
                  </div>
                  <div className="flex-center">
                    <h6 className="custom-label">Total Bill</h6>
                  </div>
                </div>
              </Col>
              <Col md={3} className="flex-center">
                <button className="blue-btn btn-lg btn-block flex-center">
                  PROCEED
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SeatAndVoucher;
