import React, { useContext, useEffect } from "react";
// import { globalConfigContext } from '@context/GlobalConfigContext';
import i18n from "../../plugins/i18n";
import { Link } from "react-router-dom";
// import moment from 'moment';
// import 'moment/locale/ar';
import { withNamespaces } from "react-i18next";
import { Row, Container, Col, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setKioskSelectedLanguage } from "../../store/global/globalActions";
import { useHistory } from "react-router-dom";
import { getLangISOCode } from "@helper/formatting";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";

const MovieHeader = ({ t, changeLanguageFunction }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const { arabic, setArabic } = useContext(globalConfigContext);
  const arabic = false;
  const global = useSelector((state) => state.global);
  const {
    globalOptions,
    cinema: { cinema_name },
    selected_language: { lang_name },
    globalSettings: { org_logo_url },
  } = global;
  const globalOptionsLanguages = globalOptions.find((go) => go.go_id === 30);
  let languages = [];
  if (globalOptionsLanguages && globalOptionsLanguages !== null) {
    const globalLanguages = JSON.parse(globalOptionsLanguages.go_value);
    languages = globalLanguages;
  } else {
    history.push(`/`);
  }

  // useEffect(() => {
  // 	if (lang && lang === 'ar') {
  // 		document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
  // 		setArabic(true);
  // 	} else {
  // 		document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
  // 		setArabic(false);
  // 	}
  // 	i18n.changeLanguage(lang ? lang : 'en');
  // }, []);

  const changeLanguage = (lng) => {
    console.log("languages: ", languages);
    console.log("lang change");

    // if (lng === "ar") {
    //   window.localStorage.setItem("lang", "ar");
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    // } else {
    //   window.localStorage.setItem("lang", "en");
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    // }
    dispatch(setKioskSelectedLanguage(lng));
    let lang = lng.iso_2
      ? lng.iso_2.toLowerCase()
      : getLangISOCode(lng.lang_name);
    i18n.changeLanguage(lang);
    moment.locale(lang.toLowerCase());
    // changeLanguageFunction();
    // window.location.reload();
  };

  return (
    <Container fluid>
      <div>
        <div className="movie_header">
          <Link to="/" className="logo">
            <img src={org_logo_url} />
          </Link>
          <span className="big_text">
            {cinema_name && cinema_name !== "" && cinema_name}
          </span>
          {/* <div className="dropdown_div">
						<select
							onChange={(e) => changeLanguage(e.target.value)}
							className="dropdown"
							name="language"
							id="language"
						>
							<option value="en" selected={!lang || lang === 'en'}>
								{t('common.ENGLISH')}
							</option>
							<option value="ar" selected={lang && lang === 'ar'}>
								{t('common.ARABIC')}
							</option>
						</select>
					</div> */}
          {languages?.length > 1 ? (
            <Dropdown>
              <Dropdown.Toggle
                className="dropdownClassic"
                // onChange={(e) => changeLanguage(e.target.value)}
                name="language"
                id="language"
              >
                {lang_name.toUpperCase()}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdownItems">
                {languages.map((lang) => (
                  <Dropdown.Item
                    value={lang.lang_id}
                    onSelect={() => changeLanguage(lang)}
                  >
                    {lang.lang_name.toUpperCase()}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <div className="single-lang">
              {languages[0]?.lang_name.toUpperCase()}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default withNamespaces()(MovieHeader);
