import React, { Component, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
 Container,
 Row,
 Col,
 Table,
 Alert,
 Modal,
 Spinner,
} from "react-bootstrap";
import screen from "../assets/imgs/svgs/screen.svg";
import ip1 from "../assets/imgs/ip1.jpg";
import moment from "moment";
import { PaymentService } from "@apiService/PaymentService";
import { withNamespaces } from "react-i18next";
import i18n from "../plugins/i18n";
import { SOCKET_URL } from "@config";
import clock from "@assets/imgs/svgs/clock.svg";
import { bookSeats, bookFnb } from "@store/booking/bookingActions";
import MovieHeader from "@components/partials/MovieHeader";
import GoBack from "@components/partials/GoBack";

// import { globalConfigContext } from '@context/GlobalConfigContext';
var wsUrl = SOCKET_URL;
var socket = new WebSocket(wsUrl);

const CheckoutTwo = (props) => {

 const [show, setShow] = useState(false);
 const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);

 return (
  <>
   <MovieHeader />

   <Container fluid>
    <div class="d-flex justify-content-between align-items-center">
     <GoBack to="prev" />
     <div className="session-timer">
      <span>6:54</span>
      <img src={clock} className={""} />
     </div>
    </div>
    <Row className="checkout-page pt-5 mt-4">
     <div className="hero mb-3">
      <div className="imgOverlay mxh-360">
       <img
        className=""
        src="https://cinematic-server-gdc-3.brij.tech/uploads/gallery/1605441987965-secret_garden_xlg.jpg"
       />
      </div>
      <div className="hero-text">
       <h3> Bad Boys For Life (2D, EN)</h3>
      </div>
     </div>
     <Col className="col-8 mt-5">
      <div className="pb-3">
       <h5 className="heading">TICKET</h5>
      </div>
      <Row className="ticket-header">
       <Col className="col-3">
        <img src="https://cinematic-server-gdc-3.brij.tech/uploads/gallery/1605441987965-secret_garden_xlg.jpg" className="img-fluid" />
       </Col>
       <Col className="col-9 ml-n4">
        <h4>
         Bad Boys For Life (2D, EN)
        </h4>
        <div className="grid-50 pt-3">
         <div>
          <p>Date</p>
          <h4>
           24th January, 2021
        </h4>
         </div>
         <div>
          <p>Seats</p>
          <h4>
           G4, G5
        </h4>
         </div>
         <div>
          <p>Show Details</p>
          <h4>
           MACRO XE, 4:30PM
        </h4>
         </div>
         <div>
          <p>Voucher Code</p>
          <h4>
           ABCDS (20% OFF)
        </h4>
         </div>
        </div>

       </Col>
      </Row>
     </Col>
     <Col className="col-4">
      <div className="brds-10 ticket-right">
       <div className="ticket-right-box pb-2">
        <h5 className="title pt-4 pb-3">Tickets</h5>
        <div className="grid-half pt-2">
         <h5 className="left">G4, G5 (Macro XE)</h5>
         <h5 className="right font-weight-bold">25 SR</h5>
        </div>
       </div>
       <div className="ticket-right-box pb-2">
        <h5 className="title pt-4 pb-3">Add Ons</h5>
        <div className="grid-half pt-2">
         <h5 className="left">G4, G5 (Macro XE)</h5>
         <h5 className="right font-weight-bold">25 SR</h5>
        </div>
        <div className="grid-half pt-2">
         <h5 className="left">G4, G5 (Macro XE)</h5>
         <h5 className="right font-weight-bold">25 SR</h5>
        </div>
        <div className="grid-half pt-2">
         <h5 className="left">G4, G5 (Macro XE)</h5>
         <h5 className="right font-weight-bold">25 SR</h5>
        </div>
        <div className="grid-half pt-2">
         <h5 className="left">G4, G5 (Macro XE)</h5>
         <h5 className="right font-weight-bold">25 SR</h5>
        </div>
        <div className="grid-half pt-2">
         <h5 className="left">G4, G5 (Macro XE)</h5>
         <h5 className="right font-weight-bold">25 SR</h5>
        </div>
       </div>
      </div>
     </Col>
    </Row>

    <Modal
     show={show}
     onHide={handleClose}
     size="lg"
     dialogClassName="modal-100w modal-bottom border-top checkout-footer-modal"
    >
     <div className="brds-8 py-5">
      <div className="my-4 offset-4 col-4">
       <h4 className="mb-2 font-weight-bold py-4">Enter your contact details</h4>
       <input
        type="email"
        placeholder="E-mail"
        className="rounded-lg mb-5 form-control"
       />
       <input
        type="number"
        placeholder="Mobile-No"
        className="rounded-lg mb-5 form-control"
       />
      </div>
     </div>
    </Modal>

    <Row className="align-items-center bgb mt-5 checkout-footer sticky-movie-bar p-4 grid-75">
     <div>
      <h6 className="title">Total Payable</h6>
      <h4 className="font-weight-bold">75 SR</h4>
     </div>
     <div>
      <button className="btn btn-primary btn-confirm" onClick={handleShow}>CONFIRM & PAY</button>
     </div>
    </Row>
    {/* <div class="seat-footer col-12">
     <div class="py-4 row">
      <div class="col-md-8">
       <div class="d-flex px-3 br-1">
        <div><h4 class="f-grey">Total Payable</h4><h1 class="fw-bold">INR 320.00 </h1><h5>INR Taxes: 41.74 </h5></div></div></div><div class="d-flex justify-content-center align-items-center col-md-4">
     <span class="blue-btn btn-lg px-5">PROCEED</span></div></div></div> */}
   </Container>
  </>

 );
};

export default withNamespaces()(CheckoutTwo);
