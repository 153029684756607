import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Spinner from "./partials/Spinner";
import {
  fetchAllMoviesSchedules,
  setSelectedSession,
  // setStage,
} from "@store/movies/moviesActions";
// import { setFromMoviePage } from "../store/movies/movieActions";
import moment from "moment";
import { getLangSpecificAttribute } from "@helper/Languages";
import {
  getMovieVersionsText,
  generateMovieVersionsText,
} from "@helper/formatting";
import ModalVideo from "react-modal-video";
import play from "@assets/imgs/svgs/play.svg";
import ScrollArrows from "@components/partials/ScrollArrows";
import { motion } from "framer-motion";


const Schedules = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(
    moment().locale("en").format("YYYY-MM-DD")
  );
  const [todaysDate, setTodaysDate] = useState(
    moment().locale("en").format("YYYY-MM-DD")
  );
  const [videoChannel, setVideoChannel] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);

    const paginationInterval = 3;

  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const [paginationEndIndex, setPaginationEndIndex] = useState(paginationInterval - 1);

  useEffect(() => {
    setPaginationEndIndex(paginationStartIndex + paginationInterval - 1)
  }, [paginationStartIndex])

  // let selectedCinema = useSelector((state) => state.movies.selectedCinema);
  // let cinema_id = selectedCinema?.cinema_id;

  // const selected_language = useSelector(
  //   (state) => state.movies.selected_language
  // );
  // const { lang_name, iso_2, lang_id } = selected_language;

  const global = useSelector((state) => state.global);
  const {
    cinema: { cinema_id, cinema_name, region_name },
    selected_language: { lang_id, iso_2 },
  } = global;

  let allMoviesSchedules = useSelector(
    (state) => state.movies.allMoviesSchedules
  );
  let allMoviesSchedulesLoading = useSelector(
    (state) => state.movies.allMoviesSchedulesLoading
  );
  let allMoviesSchedulesError = useSelector(
    (state) => state.movies.allMoviesSchedulesError
  );

  let movieSchedules = allMoviesSchedules.movieSchedules;
  let datesArray = allMoviesSchedules.datesArray;

  useEffect(() => {
    dispatch(
      fetchAllMoviesSchedules({
        start_date: selectedDate,
        cinema_id: !isNaN(parseInt(cinema_id)) ? cinema_id : null,
      })
    );
  }, [selectedDate, cinema_id]);

  const handleDateChange = () => {
    setSelectedDate((prev) => moment(prev).add(1, "week").format("YYYY-MM-DD"));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (allMoviesSchedulesLoading) {
    return (
      <div className="col-12 text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {movieSchedules?.length && <ScrollArrows paginationStartIndex={paginationStartIndex} setPaginationStartIndex={setPaginationStartIndex} paginationInterval={paginationInterval} arrayLength={movieSchedules?.length} />}
      

      <div className="schedules">
        {/* cinema name and buttons */}
        <h1 className="cinema-name">{cinema_name}</h1>
        <section className="row">
          <article className="col">
            <div className="d-flex align-items-center my-4">
              <button
                className="schedules-btn"
                onClick={() =>
                  setSelectedDate(moment().locale("en").format("YYYY-MM-DD"))
                }
              >
                {t("This Week")}
              </button>
              <button className="schedules-btn" onClick={handleDateChange}>
                {" "}
                &#62;
              </button>
            </div>
          </article>
        </section>

        {/* movie details and schedules */}
        {movieSchedules?.length > 0 &&
          movieSchedules.map((movie, movieIndex) => (
            (movieIndex >= paginationStartIndex && movieIndex <= paginationEndIndex) && 
            <motion.section className="row schedules-row"
            initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
              <article className="col-xl-6 mb-3 mb-xl-0">
                <section className="row">
                  <article className="col-4">
                    {/* Movie Image */}
                    <figure className="img-wrapper">
                      <img
                        src={getLangSpecificAttribute(
                          movie?.movie_content,
                          lang_id,
                          "artwork"
                        )}
                        alt={movie?.original_movie_title}
                        title={movie?.original_movie_title}
                      />
                    </figure>
                  </article>
                  <article className="col-8">
                    {/* Movie details */}
                    <p className="movie-title">
                      {getLangSpecificAttribute(
                        movie?.movie_content,
                        lang_id,
                        "mc_title"
                      ) || movie?.original_movie_title}
                    </p>
                    <table>
                      <tr>
                        <td>{t("Original Title")} :</td>
                        <td>{movie?.original_movie_title}</td>
                      </tr>
                      <tr>
                        <td>{t("Details")} :</td>
                        <td>
                          {movie?.rating} |{" "}
                          {movie?.genres
                            ?.map((g) => g[iso_2?.toLowerCase()] || g["g_name"])
                            .join(", ")}{" "}
                          | {movie?.runtime} {t("min")}.
                        </td>
                      </tr>
                      <tr>
                        <td>{t("Languages")} :</td>
                        <td>
                          {movie?.movie_languages
                            ?.map((x) => x.iso_2)
                            .join(", ")}
                        </td>
                      </tr>
                      {true && (
                        <tr>
                          <td>
                            {movie?.trailers?.length > 0 && (
                              <p className="">
                                {movie?.trailers?.length > 1
                                  ? t("Trailers")
                                  : t("Trailer")}{" "}
                                :
                              </p>
                            )}
                          </td>
                          <td>
                            <ul className="list-inline mb-0">
                              <ModalVideo
                                autoplay
                                channel={videoChannel}
                                isOpen={isOpen}
                                videoId={currentVideoId}
                                onClose={() => setOpen(false)}
                              />
                              {movie?.trailers?.map((trailer, trailerIndex) => (
                                <li
                                  className="list-inline-item"
                                  key={trailerIndex}
                                >
                                  <p
                                    className="mr-2 play-trailer my-1"
                                    onClick={() => {
                                      if (
                                        trailer?.trailer_link?.includes(
                                          "youtube"
                                        )
                                      ) {
                                        setVideoChannel("youtube");
                                        setCurrentVideoId(
                                          trailer?.trailer_link?.split("=")[1]
                                        );
                                      }
                                      if (
                                        trailer?.trailer_link?.includes("vimeo")
                                      ) {
                                        setVideoChannel("vimeo");
                                        setCurrentVideoId(
                                          trailer?.trailer_link
                                            ?.split("/")
                                            .pop()
                                        );
                                      }
                                      setOpen(true);
                                    }}
                                  >
                                    <img
                                      src={play}
                                      alt="play"
                                      className="mr-2"
                                    />
                                    {generateMovieVersionsText(
                                      region_name,
                                      trailer.movie_lang_iso_3,
                                      trailer.sub_lang_1_iso_3,
                                      trailer.sub_lang_2_iso_3
                                    )}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      )}
                    </table>
                    <p className="">
                      {getLangSpecificAttribute(
                        movie?.movie_content,
                        lang_id,
                        "mc_plot"
                      )}
                    </p>

                    <div className="d-flex align-items-center flex-wrap mt-3">
                      {/* <button className="btn-main mr-3 mr-md-4 mr-xl-5">
                      {t("Trailer")}
                    </button> */}
                      <button
                        className="blue-btn"
                        onClick={() => {
                          // dispatch(setStage({ stage: 1, show: null }));
                          history.push(
                            `/movie-details/${movie?.movie_id}/${lang_id}/${
                              movie?.screens?.[0]?.shows?.find(
                                (x) => x.shows.length > 0
                              )?.shows?.[0]?.movie_details_id
                            }`
                          );
                        }}
                      >
                        {t("Movie Details")}
                      </button>
                    </div>
                  </article>
                </section>
              </article>
              {/* Schedules */}
              <article className="col-xl-6">
                <div className="schedules-section">
                  {/* Dates and Days */}
                  <div className="schedule-grid date-n-day">
                    {/* First two boxes is for offset */}
                    <section className="" />
                    <section className="" />
                    {movie?.datesArray?.length > 0 &&
                      movie?.datesArray?.map(({ date, formats }, dateIndex) => (
                        <section className="" key={dateIndex}>
                          {date === todaysDate ? (
                            <>
                              <p>{t("Today")}</p>
                              <p>{moment(date).format("MMM Do")}</p>
                            </>
                          ) : (
                            <>
                              <p>{moment(date).format("ddd")}</p>
                              <p>{moment(date).format("DD.MM")}</p>
                            </>
                          )}
                        </section>
                      ))}
                  </div>
                  {/* Versions and Formats */}
                  <div className="schedule-grid version-n-formats">
                    {/* First two boxes is for offset */}
                    <section />
                    <section />
                    {movie?.datesArray?.length > 0 &&
                      movie?.datesArray?.map(({ formats }, dateIndex) => (
                        <section className="" key={dateIndex}>
                          <section>{formats?.join(", ")}</section>
                        </section>
                      ))}
                  </div>
                  {/* Schedules */}
                  {movie?.screens?.length > 0 &&
                    movie?.screens?.map((screen, screenIndex) => (
                      <div className="schedule-grid schedule" key={screenIndex}>
                        {/* Language */}
                        <section>
                          <p className="theatre-language text-break">
                            {screen?.is_version_apply
                              ? screen?.version_name
                              : screen?.lang_translation_obj?.[
                                  iso_2?.toLowerCase()
                                ] || screen?.lang_name}
                          </p>
                        </section>
                        <section>
                          <div className="hall-name">{screen.screen_name}</div>
                        </section>
                        {screen?.shows?.length > 0 &&
                          screen?.shows?.map((showDate, showDateIndex) =>
                            showDate?.shows?.length === 0 ? (
                              <section key={showDateIndex}></section>
                            ) : (
                              <section key={showDateIndex}>
                                {showDate?.shows?.map((show, showIndex) => (
                                  <div
                                    disabled={true}
                                    className={`show-btn yellow lg ${
                                      false && movie?.showType == 2
                                        ? "yellow"
                                        : "green"
                                    } ${false && movie?.mf_name ? "lg" : ""} ${
                                      false && movie?.showType == 3
                                        ? "full"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      // dispatch(setFromMoviePage());
                                      history.push(
                                        `/seat-layout/${show.md_id}/${show.ss_id}/${show.screen_id}`
                                      );
                                      dispatch(setSelectedSession(show));
                                    }}
                                  >
                                    <div className="">
                                      <p className="show-time">
                                        {show?.ss_start_show_time}
                                      </p>
                                    </div>
                                    {/* {true && (
                                      item?.mf_name &&
                                        item?.mf_name.toLowerCase() !== "2d" &&

                                      <div className="exp">{show?.mf_name}</div>
                                    )} */}
                                  </div>
                                ))}
                              </section>
                            )
                          )}
                      </div>
                    ))}
                </div>
              </article>
            </motion.section>
          ))}

        {movieSchedules?.length === 0 &&
          allMoviesSchedulesLoading === false && (
            <div className="d-flex justify-content-center align-items-center">
              {t("No Movies Found")}
            </div>
          )}
      </div>
    </>
  );
};

export default withNamespaces()(Schedules);
