import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";
//import compoents
import HomeContainer from "./components/HomeContainer.js";
import TodoContainer from "./components/TodoContainer";
import UserContainer from "./components/UserContainer";
import MoviesListing from "./components/MoviesListingContainer.jsx";
import Page2 from "./components/Page2.jsx";
import Page3 from "./components/Page3.jsx";
import LoginPage from "./components/LoginPage";
import KioskCollectLoginPage from "./components/KioskCollectLoginPage.jsx";
import Receipt from "./components/Receipt";
import SelectCinema from "./components/SelectCinema.jsx";
import SelectLanguage from "./components/SelectLanguage.jsx";
import ChooseLanguage from "./components/ChooseLanguage.jsx";
import MovieDetails from "./components/MovieDetails.jsx";
import SeatLayout from "./components/SeatLayout.jsx";
import CreateShowSeatLayout from "./components/OnDemandFlow/CreateShowSeatLayout";
import FnB from "./components/FnB.jsx";
import OnlyFnbCheckout from "./components/OnlyFnbCheckout.jsx";
import FnBConfirm from "./components/FnBConfirm";
import Checkout from "./components/Checkout";
import CheckoutOne from "./components/CheckoutOne.jsx";
import FinalTicket from "./components/FinalTicket.jsx";
import MovieContainer from "./components/MovieContainer";
import ListMovies from "./components/ListMovies";
import KioskCollectTwo from "./components/KioskCollectTwo.jsx";
import InsertCard from "./components/InsertCard";
import ProcessPayment from "./components/ProcessPayment";
import SeatAndVoucher from "./components/SeatsAndVoucher";
import {
  fetchGlobalSettings,
  fetchGlobalOptions,
  getSignageScreen,
  fetchCountries,
  setBookingType,
  fetchCinemaSettings,
} from "./store/global/globalActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  SELECT_CINEMA_SUCCESS,
  SET_CUST_MOB_CODE,
} from "./store/global/globalTypes";
import CheckoutTwo from "./components/CheckoutTwo.js";
import Signage from "./components/partials/Signage.js";
import { useIdleTimer } from "react-idle-timer";
import { mode } from "./config/";
import MasterService from "./ApiServices/MasterService.js";
import Settings from "./components/Settings.jsx";
import OnDemand from "./components/OnDemandFlow/OnDemand.jsx";
import CreateShowDetails from "./components/OnDemandFlow/CreateShowDetails.jsx";
import ScreeningType from "./components/OnDemandFlow/ScreeningType";
import { setGuestId } from "@store/booking/bookingActions";
import CheckoutOnDemand from "./components/OnDemandFlow/Checkout";

import { fetchNowPlayingMovies } from "@store/movies/moviesActions";
import { backendSocket } from "@src/index";

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

const Router = ({}) => {
  const dispatch = useDispatch();
  const [screenTimeOut, setscreenTimeOut] = useState(60000);
  useEffect(() => {
    dispatch(fetchGlobalOptions());
    dispatch(fetchGlobalSettings());
    dispatch(fetchCountries());
  }, [dispatch]);
  const history = useHistory();
  // useEffect(() => dispatch(setBookingType(mode)), []);
  useEffect(() => {
    const cinema = JSON.parse(localStorage.getItem("cinema"));
    console.log("cinema", cinema);
    if (cinema === null) {
      history.push(`/`);
    } else {
      dispatch({ type: SELECT_CINEMA_SUCCESS, payload: cinema });
      let custMobCode = "+" + cinema.cine_mobile_code;
      dispatch({ type: SET_CUST_MOB_CODE, payload: custMobCode });
      dispatch(getSignageScreen(cinema.cinema_id));
      dispatch(fetchCinemaSettings(cinema.cinema_id));
    }

    // MasterService.GetTranslations().then((resp) => {
    //   const { data } = resp;
    //   console.log("fetched translations");
    // });

    // check and set guest user id
    let guest_user_id = window.localStorage.getItem("guest_user_id");
    // if (!guest_user_id) {
      guest_user_id = Math.round(Math.random() * 1000000);
      window.localStorage.setItem("guest_user_id", guest_user_id);
    // }

    setGuestId(guest_user_id);
  }, [history, dispatch]);

  const global = useSelector((state) => state.global);
  const {
    cinema,
    globalSettings: {
      org_logo_url,
      org_color,
      org_hover_color,
      org_font_color,
    },
    screen_time,
    signage,
  } = global;

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--bn-brand-color", org_color);
    root.style.setProperty("--bn-brand-color-dark", org_hover_color);
    root.style.setProperty("--bn-font-1", org_font_color);
    root.style.setProperty("--bn-logo", `url(${org_logo_url})`);
  }, [org_logo_url, dispatch, org_color, org_hover_color, org_font_color]);

  const handleOnIdle = (event) => {
    console.log("user is idle");
    if (signage && signage.Records && signage.Records.length > 0) {
      history.push("/signage");
    }
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    if (
      signage &&
      signage.Records &&
      signage.Records.length > 0
      &&
      // (
      //   event.type !== "mousedown" ||
      //   (event.target && event.target.localName !== "button"))
     !( event.target && event.target.classList && event.target.classList.contains("hb"))
    ) {
      history.push("/");
    }
  };

  const handleOnAction = (e) => {
    console.log("user did something");
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: screen_time || screenTimeOut,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  useEffect(() => {
    if (
      backendSocket &&
      backendSocket.connected
    ) {

      backendSocket.on("FETCH_SCHEDULE", function (payload) {
        if (cinema?.cinema_id)
          dispatch(fetchNowPlayingMovies({cinema_id: cinema?.cinema_id}));
      });
    }

    return () => {
      if (backendSocket) {
        backendSocket.off("FETCH_SCHEDULE");
      }
    };
  }, [cinema]);

  return (
    //define path and use component
    <Switch>
      {/* <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"> */}
      {/* <Route exact path="/" component={HomeContainer} /> */}
      {/* <Route exact path="/user/:user_id" component={UserContainer} /> */}
      {/* <Route exact path="/todos" component={TodoContainer} /> */}
      <Route exact path="/select-cinema" component={SelectCinema} />
      <Route exact path="/select-language" component={SelectLanguage} />
      <Route exact path="/" component={ChooseLanguage} />
      <Route exact path="/book-show" component={Page2} />
      {/* <Route exact path="/booking-type" component={Page3} /> */}
      <Route exact path="/movies" component={MoviesListing} />
      <Route exact path="/list-movies" component={ListMovies} />
      <Route exact path="/select-cinema" component={SelectCinema} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/on-demand" component={OnDemand} />
      {/* <Route exact path="/login" component={LoginPage} /> */}

      <Route
        exact
        path="/kiosk-collect-login"
        component={KioskCollectLoginPage}
      />
      {/* <Route exact path="/receipt" component={receipt} /> */}

      <Route
        exact
        path="/movie-details/:movie_id/:content_lang_id/:movie_id2"
        component={MovieDetails}
      />
      <Route
        exact
        path="/seat-layout/:movie_id/:session_id/:screen_id"
        component={SeatLayout}
      />
      <Route exact path="/fnb/:cinema_id" component={FnB} />
      <Route
        exact
        path="/fnb-checkout/:cinema_id"
        component={OnlyFnbCheckout}
      />
      {/* <Route exact path="/fnb-confirm" component={FnBConfirm} />*/}
      <Route exact path="/checkout/:reservation_id" component={Checkout} />
      <Route exact path="/final-ticket" component={FinalTicket} />
      {/* <Route exact path="/movie-container" component={MovieContainer} />*/}
      <Route exact path="/kiosk-collect-2" component={KioskCollectTwo} />
      <Route
        exact
        path="/checkout-one/:reservation_id"
        component={CheckoutOne}
      />
      <Route exact path="/checkout-two" component={CheckoutTwo} />
      <Route
        // exact
        path="/create-show-details/:movie_id/:content_lang_id/:isPrivate?"
        component={CreateShowDetails}
      />
      <Route
        exact
        path="/screening-type/:mycinema_ss_d_id"
        component={ScreeningType}
      />
      <Route
        exact
        path="/create-show-seat-layout/:mycinema_ss_d_id/:booking_type_id"
        component={CreateShowSeatLayout}
      />
      <Route
        exact
        path="/checkout-on-demand/:reservation_id"
        component={CheckoutOnDemand}
      />
      <Route exact path="/signage" component={Signage} />
      {/* <Route exact path="/insert-card" component={InsertCard} />
    <Route exact path="/process-payment" component={ProcessPayment} />
    <Route exact path="/seat-voucher" component={SeatAndVoucher} /> 

    {/* </AnimatedSwitch> */}
    </Switch>
  );
};

export default Router;
